const Backtesting = {
    headers : [
            'Backtesting',
        ], 
    
        titles : [
            'Backtesting - Overview',
            'Backtesting - Video Tutorial',
            'Backtesting - Replay Mode in TradingView',
        ],
    
        media : [
            {type: 'image', src: '2CourseDailyMedien/DailyAnalysisExcelOverview.png', alt: 'Daily Chart Analysis'},
            {type: 'video', src: 'videos/backtest.mp4', alt: 'Backtesting Daily Chart'},
            {type: 'image', src: '2CourseDailyMedien/replayBacktestDaily.png', alt: 'Replay Mode TradingView', caption: 'Chart provided by TradingView'},
        ],


    texts : [
            {
                content: [
                    {type: 'paragraph', text: 'Backtesting is an important part of trading. It allows you to test your trading strategy and solidify the rules in trading before risking real money.'},
                    {type: 'paragraph', text: 'Now use the newly acquired knowledge and test the markets on the daily chart. You will now need the evaluation from the weekly chart. Look for the date of the trading signals from the Excel table in the daily chart.'},
                    {type: 'list', items: [
                        'Mark the date of the trading signal in the daily chart and check if the signal is valid within the 25-day rule.',
                        'Check the market structure in the daily chart and find the current trend according to short-term, medium-term, and long-term highs and lows.',
                        'Use the Moving Average 18 and the ADMA indicator and look for initial patterns to determine the trend direction.',
                        'Look for divergences in Proff (ProGo) and the POIV indicator.',
                        'Look for a suitable entry pattern.',
                        'Define the entry point with the 1-tick rule and set the stop loss according to the rules.',
                        'Set the profit target.',
                        'You can ignore the spread from the Excel at this point. This only works in real-time. We will address this later.'
                    ]},
                    {type: 'download', text: 'EXCEL Daily Analysis Template', href: 'https://firebasestorage.googleapis.com/v0/b/courseapp-e7938.appspot.com/o/2CourseDailyMedien%2FCocoaWeeklyPlusDailyTemplate.xlsx?alt=media&token=2bec8ad2-1231-41f2-98df-1b9882b03a78'},
                    {type: 'download', text: 'EXCEL Daily Analysis Example', href: 'https://firebasestorage.googleapis.com/v0/b/courseapp-e7938.appspot.com/o/2CourseDailyMedien%2FCocoaWeeklyPlusDailyExampleSolution.xlsx?alt=media&token=c88f824d-877b-406c-b208-9faa50cd22bf'},
                    {type: 'download', text: 'Trade Algorithm PDF', href: 'https://firebasestorage.googleapis.com/v0/b/courseapp-e7938.appspot.com/o/3CourseAdditionalMedien%2FTrade%20Algorithm.pdf?alt=media&token=edff7667-c939-4818-b5b9-dd279c4358b5'},
                ]
            },
            {
                content: [
                    {type: 'paragraph', text: 'In this video tutorial, you will learn how to conduct a backtest of COT signals on the daily chart, based on fundamental analysis from the weekly chart. The goal is to understand how well these signals perform under different market conditions and how they can be applied in practice.'},
                    {type: 'paragraph', text: 'A backtest is crucial for obtaining statistical values that show how well COT signals work in various market environments and patterns. For each signal, at least 3 out of 5 predefined criteria must be met to analyze and potentially trade it. By reviewing a market over a three-year period, we can statistically verify how likely it is that a trading setup will succeed in different conditions.'},
                    {type: 'paragraph', text: '### Benefits of a Systematic Backtest'},
                    {type: 'list', items: [
                        'Statistical Evidence: A systematic backtest provides concrete numbers that demonstrate the effectiveness of your trading strategy. These statistics are essential for understanding under what conditions your strategies work best.',
                        'Emotional Control: A solid backtest helps build emotional capital. Knowing that your strategy is based on robust statistical data allows you to remain calm during difficult market phases or drawdowns. It’s about removing emotions from trading and focusing on the facts.',
                        'Confidence in Trading: Trading can be emotionally challenging. A thorough backtest gives you confidence that your strategy works, which helps reduce emotional responses such as disappointment or frustration. Knowing that your decisions are based on solid data strengthens your confidence.'
                    ]},
                    {type: 'paragraph', text: 'Documentation and Reflection'},
                    {type: 'paragraph', text: 'Later, when you document each of your trades in a journal – including images, setups, and personal notes on feelings or concerns – these records can be incredibly valuable. Regularly reviewing and analyzing this documentation helps you learn from your experiences and continually improve.'},
                    {type: 'paragraph', text: 'In summary: A thorough backtest, combined with meticulous documentation of your trading activities, is key to successful and emotionally controlled trading. It not only helps refine your strategy but also strengthens your emotional control and ultimately leads to more successful trading.'}
                ]
                
            },
            {
                content: [
                    {type: 'paragraph', text: 'The Replay Mode in TradingView is a powerful tool to analyze and test the markets.'},
                    {type: 'paragraph', text: 'The Replay Mode allows you to load historical data and simulate the market in real-time. You can move the market forward and backward and play the market at different speeds.'},
                    {type: 'paragraph', text: 'The Replay Mode is an effective tool to test and improve your trading strategy. You can analyze the market in different time frames and practice market structure and order management.'},
                    {type: 'paragraph', text: 'Use the Replay Mode to test and improve your trading strategy.'},
                    {type: 'paragraph', text: 'Set the starting point and click forward one candle at a time. Check if a signal has been generated and if you would trade the market. Use the tools to draw the entry points, stop loss, and profit targets, and then click forward one candle. Record the profits or losses and other data in the Excel table.'},
                    {type: 'paragraph', text: 'Do not use the Buy and Sell function, as we cannot set the correct entry points here.'},
                    {type: 'paragraph', text: '!!! Make sure to set the B-Adj button.'}
                ]
            },

        /*{
            content: [
                {type: 'paragraph', text: 'Hier ist ein Absatztext für das Zick Zack Pattern'},
                {type: 'list', items: ['Kurzfristig A', 'Mittelfristig B', 'Langfristig C'] }
                {type: 'download', text: 'Einstellungen für die Wochenanalyse', href: '1CourseWeeklyPictures/COTSettingsWeekly.png'}
            ]
        }*/
    ]
};

export default Backtesting; 



