import React from 'react';

function Slogan() {
  return (
    <div className="slogan">
      <h2>Trade smarter – Master the markets with COT data</h2>
      <h3>Trade commodities with a proven strategy</h3>
    </div>
  );
}

export default Slogan;
