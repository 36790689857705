import React, { useState } from 'react';
import { getFunctions, httpsCallable } from "firebase/functions"; // Import Firebase functions
import Spinner from '../js/Spinner'; 
import '../css/standard.css'; // Import CSS

function ManageSubscription() {
  const [loading, setLoading] = useState(false); // Zustand für den Spinner
  const functions = getFunctions(); // Initialize Firebase functions

  const handleManageSubscription = async () => {
    setLoading(true); // Spinner anzeigen
    try {
      console.log('Attempting to create portal link...');
      const createPortalLink = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');

      const result = await createPortalLink({
        returnUrl: window.location.origin + '/profile'  // URL where the user will be redirected after managing their subscription
      });

      const portalLink = result.data.url;
      console.log('Portal link created:', portalLink);
      window.location.href = portalLink; // Redirect user to the portal link
    } catch (error) {
      console.error('Error creating portal session:', error);
      setLoading(false); // Spinner ausblenden bei Fehler
    }
  };

  return (
    <div>
      <button className='manageSubscription' onClick={handleManageSubscription} disabled={loading}>
        {loading ? 'Loading...' : 'Cancel Subscription'}
      </button>
      {loading && <Spinner />} {/* Spinner anzeigen */}
    </div>
  );
}

export default ManageSubscription;