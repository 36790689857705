const Correlation = {
    headers : [
        'Correlation',
    ], 

    titles : [
        'Correlation',
    ],

    media : [
        {type: 'image', src: '3CourseAdditionalMedien/correlation.png', alt: 'Correlation', caption: '@ mrci.com'},

        /*{type: 'image', src: 'pics/weeklyCotAnalysis/abcd-market.png', alt: '18 MA Pattern'},
        {type: 'video', src: 'videos/CotDataCotIndexAnalysis.mp4', alt: 'Zick Zack Trend Analysis'},
        {type: 'gif', src: 'path/to/your-gif.gif', alt: 'Winning'}, // Direct link to the GIF image
        // more media entries...*/
    ],

    texts : [
        {
            content: [
                {type: 'paragraph', text: 'To trade successfully, it is important to understand the correlation between markets. Correlation shows how two markets behave in relation to each other.'},
                {type: 'paragraph', text: 'When trading multiple markets, it is important not to be in markets with a high correlation at the same time. For example, gold and silver have a high correlation, so it makes sense not to be in both markets simultaneously. The same applies to the dollar and the euro, as they almost move identically against each other. A correlation between 0 +/- 50 is ideal when you want to trade multiple markets.'},
                {type: 'paragraph', text: 'Correlation can change over time. Therefore, it is important to check the correlation regularly.'},
                {type: 'paragraph', text: 'Correlation can also be used as an indicator of the strength of a trend. If two markets have a high correlation, this can be an indicator of a strong trend.'},
                {type: 'paragraph', text: 'On the other hand, two correlated markets can also mean two losing trades. Diversification therefore also reduces risk.'},
                {type: 'download', text: 'Correlation - MRCI', href: 'https://www.mrci.com/special/corr030.php'},
            ]
        },

        /*{
            content: [
                {type: 'paragraph', text: 'Here is a paragraph text for the Zig Zag Pattern'},
                {type: 'list', items: ['Short-term A', 'Medium-term B', 'Long-term C'] }
                {type: 'download', text: 'Settings for the Weekly Analysis', href: '1CourseWeeklyPictures/COTSettingsWeekly.png'}
            ]
        }*/
    ]
};

export default Correlation;