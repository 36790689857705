import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/buyButton.css';

const Buybutton = () => {
    const navigate = useNavigate();

    function redirectToCheckout() {
        navigate('/checkout');
    }

    return (
        <div>
            <section className="checkout-section">
                <div className="checkout-product">
                    <img
                        src="../../Medien/kapics/buyCard.jpg"
                        alt="COT Trading Pro - Master Course"
                        className="checkout-img"
                    />
                    <div className="checkout-description">
                        {/*<h3 className="checkout-h3">COT Trading Pro - Master Course</h3>
                        <h5 className="checkout-h5">Amount to Pay: $99.00</h5>*/}
                    </div>
                </div>
                <form>
                  <button type="button" className="checkout-button" onClick={redirectToCheckout}>
                      Buy 
                  </button>
                </form>
            </section>
        </div>
    );
}

export default Buybutton;