import { initializeApp } from "firebase/app";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getAuth } from 'firebase/auth';

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app;


// Initialize Firebase Storage
const storage = getStorage(app);
export const getMediaUrls = async (mediaArray) => {
  const mediaPromises = mediaArray.map(async (media) => {
    const storageRef = ref(storage, media.src);
    const url = await getDownloadURL(storageRef);
    return { ...media, src: url };
  });

  return Promise.all(mediaPromises);
};

// Initialize Firestore Database
const db = getFirestore(app);
export { db, storage };

// Initialize Firebase Auth
const auth = getAuth(app);
export { auth };

//import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
//const appCheck = initializeAppCheck(app, {
  //provider: new ReCaptchaV3Provider('6LfoZlsqAAAAADBVSZU6QsmFIpcJVtjWhBjj6VVg'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  //isTokenAutoRefreshEnabled: true
//});