

const SeasonalsCycles = {
    headers : [
            'Seasonals & Cycles',
        ],
    
    titles : [
        'Introduction',
        'Seasonal',
        'Cycles',
        'Forecast Analyzer',
        'Forecast Analyzer Video'
    ],

    media : [
        {type: 'image', src: '1CourseWeeklyMedien/SeasonalsIntro.png', alt: 'Seasonals & Cycles'},
        {type: 'image', src: '1CourseWeeklyMedien/Seasonal.png', alt: 'Seasonal', caption: 'Chart provided by TradingView'},
        {type: 'image', src: '1CourseWeeklyMedien/cycles.png', alt: 'Cycles' , caption: '@ cycles.org'},
        {type: 'image', src: '1CourseWeeklyMedien/ForcastAnalyzerMask.png', alt: 'Forecast Analyzer'},
        {type: 'video', src: 'videos/ForecastAnalyzer-1.mp4', alt: 'Forecast Analyzer Video'},



        /*{type: 'image', src: 'pics/weeklyCotAnalysis/abcd-market.png', alt: '18 MA Pattern'},
        {type: 'video', src: 'videos/CotDataCotIndexAnalysis.mp4', alt: 'Zick Zack Trendanalyse'},
        {type: 'gif', src: 'path/to/your-gif.gif', alt: 'Gewinnen'}, // Direkter Link zum GIF-Bild
        // weitere Medieneinträge...*/
    ],


    texts : [
        {
            content: [
                {type: 'paragraph', text: 'Discover the power of seasonality in trading! In this course, you will learn how to identify and profitably use seasonal patterns in the markets, with concrete examples from practice. Additionally, we will show you how to increase the quality of trades by combining seasonality with COT data (Commitment of Traders).'}, 
                {type: 'paragraph', text: 'The combination of seasonality and COT data is a powerful tool to determine market direction. As you can see in the image, you can derive probabilities for the future from past data and thus optimize your trades.'}
            ]
        },
        {
            content: [
                {type: 'paragraph', text: 'The role of seasonality in market analysis:'},
                {type: 'list', items: ['Seasonalities do not predict specific price levels but provide a statistical analysis of price behavior based on timing.']},
                {type: 'list', items: ['This method examines historical data to identify patterns at certain times of the year.']},
                {type: 'list', items: ['Especially in commodity trading, planting and harvesting times occur consistently each year, which can influence market behavior.']},
                {type: 'list', items: ['Although not infallible, seasonal patterns are noteworthy due to their recurring nature.']},
                {type: 'list', items: ['Using seasonalities involves projecting 6 weeks from the date of the COT signal to evaluate the potential alignment of market direction with historical trends.']},
                {type: 'list', items: ['The best historical evaluation period is 5, 10, or 15 years. For our analysis, we use 10 years.']},
                {type: 'download', text: 'Kaschko`s Seasonal Trend in Tradingview', href: 'https://www.tradingview.com/script/BEQsOqoT-Kaschko-s-Seasonal-Trend/'}
            ]
        },
        {
            content: [
                {type: 'paragraph', text: 'In addition to seasonal analyses, cyclical analyses can also be conducted. The main difference between the two lies in their repetition rate and underlying causes:'},
                {type: 'paragraph', text: 'Seasonal patterns refer to regular, annual fluctuations caused by specific seasons or events. These are often due to external factors such as weather or holidays.'},
                {type: 'paragraph', text: 'Cycles, on the other hand, are long-term and often less regularly recurring fluctuations that can be influenced by broad, often unknown forces. They repeat in a stable rhythm and often show a profound pattern indicating fundamental causes.'},
                {type: 'paragraph', text: 'For a deeper analysis of cyclical phenomena, we recommend external tools and resources:'},
                {type: 'paragraph', text: 'Visit the Foundation For the Study of Cycles to learn more about the Cycles method. The FSC is a non-profit organization dedicated to the study of cyclical phenomena.'},
                {type: 'download', text: 'Foundation For the Study of Cycles', href: 'https://cycles.org'},
                {type: 'paragraph', text: 'To learn more about the Cycles method, watch the weekly seminars on YouTube every Monday by one of the founders on the FSCtv YouTube channel.'},
                {type: 'paragraph', text: 'Why is seasonal analysis important?'},
                {type: 'paragraph', text: 'Seasonal and cyclical analyses are valuable for forward-looking predictions. While seasonal patterns are determined by recurring annual events, cyclical analyses provide insights into long-term market movements influenced by fundamental forces. Both methods can serve as additional confirmation points in your trading strategy and help make more precise decisions.'},
                {type: 'paragraph', text: 'In the gold market, for example, you can see how cyclical patterns contribute to a precise prediction of price movement. The last price movement was not included in the analysis, which nicely confirms the cyclical prediction.'},
                {type: 'paragraph', text: 'Although cycles and seasonal analyses can provide valuable insights into potential market movements, it is important to consider them in the context of current price movements and trend structure. Cycles and seasonal patterns are based on historical data and time-based statistics, which do not guarantee that the same patterns will always repeat exactly. Therefore, these analyses should be used as complementary tools that support actual market data and prevailing trend structure, but not as the sole basis for trading decisions.'}
            ]
            
        },
        {
            content: [
                {type: 'paragraph', text: 'Another option is the Seasonal Tool available on our site in Menu: "Seasonals".'},
                {type: 'paragraph', text: 'The first chart shows the current price chart, the second chart shows the statistical analysis of the percentage change for each same day of the year from the price history. The third chart shows the cyclical analysis based on weekly candles. The fourth chart calculates the probability by trading days (not calendar days). The fifth chart shows the best day of the week for a market.'},
                {type: 'list', items: ['Ticker Symbol: Search for the ticker symbol on Yahoo Finance, such as GC=F', 'Start Date to Forecast: Choose the date from which you want to look into the future.', 'X Days to Forecast: Here you choose the period you want to forecast into the future.', 'Forecast Rate of Return X Days: Here the statistical percentage change for each same day of the year from the price history is calculated and an average is determined. Choose the number of days needed for your approximate trade duration.', 'Years for Weekly Seasonals: Here you choose the years to be used for the cyclical analysis. The default is 10 or 15 years on weekly candles.', 'Trading Day of Week (TDOW): Here you best choose 5 years.', 'Start Date for TDOY Matrix: In the matrix, the probability of a specific trading day is determined. Choose the date to determine how many years should be used for the analysis. By default, the entire history is set to make statistically valuable statements.', 'Days of Average Return Matrix: Here you choose the period for the length you want to hold your trade.', 'General Average Return Matrix: Here the positive and negative values from the history are summed for each individual trading day to get an average. This should not be too high, as negative and positive days are calculated in one sum.', 'Only positive or negative Average Matrix: Here the positive and negative values are calculated separately. Therefore, the value can be higher here.', 'Percentage Probability: Here you can set that you only want to see the profitable trading days above a certain value. For example, 70% would mean that historically, on 7 out of 10 (over 10 years) days, the price traded negatively or positively.'] },

            ]
        }, 
        {
            content: [
                {type: 'paragraph', text: 'Watch the video to learn how to use this software.'},
            ]
        }
        
    ]
    /*{
        content: [
            {type: 'paragraph', text: 'Hier ist ein Absatztext für das Zick Zack Pattern'},
            {type: 'list', items: ['Kurzfristig A', 'Mittelfristig B', 'Langfristig C'] }
            {type: 'download', text: 'Einstellungen für die Wochenanalyse', href: '1CourseWeeklyMedien/COTSettingsWeekly.png'}
        ]
    }*/

}

export default SeasonalsCycles;
