const Psychology = {
    headers: [
        'Psychology',
    ],

    titles: [
        'Psychology',
        'Psychological Barriers',
        'Trading Psychology',
        'False Beliefs',
        'Trading Psychological Profile',
        'Conditioning Techniques',
        'Rational Trading Plan',
        'Trading Journal',
        'Dealing with Losses and Drawdowns',
        'Summary and Encouragement',
    ],

    media: [
        { type: 'image', src: '3CourseAdditionalMedien/brain.jpg', alt: 'brain' },
        { type: 'image', src: '3CourseAdditionalMedien/barriers.jpg', alt: '' },
        { type: 'image', src: '3CourseAdditionalMedien/emotioncycle.jpg', alt: '' },
        { type: 'image', src: '3CourseAdditionalMedien/selffullfillingprophecy.jpg', alt: '' },
        { type: 'image', src: '3CourseAdditionalMedien/profilbuilding.jpg', alt: 'profile creation' },
        { type: 'image', src: '3CourseAdditionalMedien/newhabbits.jpg', alt: '' },
        { type: 'image', src: '3CourseAdditionalMedien/strategyplan.jpg', alt: '' },
        { type: 'image', src: '3CourseAdditionalMedien/tradingplan.jpg', alt: '' },
        { type: 'image', src: '3CourseAdditionalMedien/fearchain.jpg', alt: '' },
        { type: 'image', src: '3CourseAdditionalMedien/psychofaktoren.jpg', alt: 'Conclusion' },
    ],

    texts: [
        {
            content: [
                { type: 'list', items: [
                    'Psychology is a fundamental part of trading. Your mental state, emotional reactions, and self-control are crucial to your success in the market.',
                    'Even with a strong strategy, psychological factors like fear, greed, and uncertainty can negatively impact your decisions.'
                ]},
                { type: 'paragraph', text: 'Important to understand:' },
                { type: 'list', items: [
                    'Your thoughts and emotions directly affect your actions.',
                    'The market is often chaotic, and you need to learn to stay calm and clear-headed.'
                ]}
            ]
        },
        {
            content: [
                { type: 'list', items: [
                    'Many traders fail not because of a lack of technical skills, but due to psychological barriers like fear, over-optimism, or lack of discipline.'
                ]},
                { type: 'paragraph', text: 'Practical tips:' },
                { type: 'list', items: [
                    'Short-term: Observe your spontaneous reactions, especially during stressful market phases.',
                    'Mid-term: Work on identifying and reducing your fears, for example through meditation or other relaxation techniques.',
                    'Long-term: Integrate routines into your daily life that keep you emotionally stable, such as regularly reflecting on your trades.'
                ]}
            ]
        },
        {
            content: [
                { type: 'list', items: [
                    'Trading psychology refers to the mental and emotional states of a trader. The goal is to eliminate emotions like fear or euphoria to implement the strategy clearly and factually.',
                    'It is about executing the strategy based on statistical evidence and market data, rather than being distracted by emotional reactions.'
                ]},
                { type: 'paragraph', text: 'Practical steps:' },
                { type: 'list', items: [
                    'Eliminate emotions: Work consciously to minimize emotional influences by focusing on the facts.',
                    'Act based on data: Implement your strategy strictly according to statistical analyses, without being guided by short-term emotions.',
                    'Consistent implementation: Monitor your progress and stick to your strategy with discipline, regardless of emotional stress.'
                ]}
            ]
        },
        {
            content: [
                { type: 'list', items: [
                    'One of the biggest psychological traps in trading is the so-called self-fulfilling prophecy, which is based on deeply ingrained behavior patterns.',
                    'An example would be the belief: "I am not successful anyway because I never was." Such beliefs lead traders to unconsciously reinforce their own failures.'
                ]},
                { type: 'paragraph', text: 'Focus on rationality:' },
                { type: 'list', items: [
                    'Let go of self-beliefs: Recognize that past failures are not a prediction of the future.',
                    'Implement strategy rationally: Focus on consistently implementing the strategy based on hard facts and analyses, rather than emotional prejudices.'
                ]}
            ]
        },
        {
            content: [
                { type: 'list', items: [
                    'A trading psychological profile helps you better understand your personal mental strengths and weaknesses and work on them specifically.',
                    'It gives you a clear overview of your emotional reaction to the market and how you should respond to it.'
                ]},
                { type: 'paragraph', text: 'Creating a profile:' },
                { type: 'list', items: [
                    'Initial assessment: Note which emotions occur for you during wins and losses.',
                    'Detailed review: Reflect on your reactions more closely and ask yourself why you act that way.',
                    'Regular reflection: Use your profile to prepare for future trades and recognize where you can improve psychologically.'
                ]}
            ]
        },
        {
            content: [
                { type: 'list', items: [
                    'If you notice that your previous habits have led to emotional and wrong decisions, it is important to establish new habits. This also includes being aware that your strategy worked in backtests.'
                ]},
                { type: 'paragraph', text: 'Practical approaches:' },
                { type: 'list', items: [
                    'Avoid impulsive actions: Do not constantly look at the trade to avoid rash, emotional decisions.',
                    'Maintain independence: Do not be influenced by the opinions of others, especially if you have already conducted sound analyses.',
                    'New routines: Develop routines based on rationality and facts, and ensure that these become a habit.'
                ]}
            ]
        },
        {
            content: [
                { type: 'list', items: [
                    'A trading plan is the key to successful trades. Without clear rules for entering and exiting trades, you risk making bad decisions based on emotional impulses.'
                ]},
                { type: 'paragraph', text: 'Steps to create a plan:' },
                { type: 'list', items: [
                    'Set specific criteria for when you want to enter the market (e.g., certain indicators, market conditions).',
                    'Set clear stop-loss and profit targets to prevent you from staying in losing positions too long or not realizing profits.',
                    'Review and optimize your plan regularly based on performance.'
                ]}
            ]
        },
        {
            content: [
                { type: 'list', items: [
                    'A trading journal is a valuable tool to track your psychological development and trading performance. You learn not only to recognize your technical mistakes but also your emotional weaknesses.'
                ]},
                { type: 'paragraph', text: 'How to keep a journal:' },
                { type: 'list', items: [
                    'Note every trade, why you made it, and what emotions you had during it.',
                    'Regularly review your journal to recognize emotional patterns that lead you to bad decisions.'
                ]}
            ]
        },
        {
            content: [
                { type: 'list', items: [
                    'Losses are part of trading, and it is important not to speculate on hope or increase risk just because you do not want to accept the loss. Perfectionism is out of place here – no one will win every trade.'
                ]},
                { type: 'paragraph', text: 'Important approaches:' },
                { type: 'list', items: [
                    'Accept losses: Instead of increasing risks to avoid losses, it is better to accept losses as a normal part of trading.',
                    'Backtesting for confidence: If you have doubts about yourself or the strategy, it helps to regularly conduct backtests to strengthen confidence in the strategy.',
                    'Develop routines: Fixed trading rules and routines help you stay disciplined even in difficult phases like drawdowns.'
                ]}
            ]
        },
        {
            content: [
                { type: 'list', items: [
                    'Considering psychological factors is essential for long-term success in trading. It is a continuous process of self-improvement, and the most important step is to start dealing more consciously with your own emotions and continuously reflecting on yourself.'
                ]}
            ]
        }
    ]
};

export default Psychology;