import Calculator from "./Calculator";
import Footer from "../landingComponents/Footer";
import UsefullLinks from "./UsefullLinks";

export default function Resources () {
    return (
        <>
            <Calculator />
            <UsefullLinks />
            <Footer />
        </>
    )
}