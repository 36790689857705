
const WeeklyCotSignals = {
        
    headers : [
            'COT Signals and Trend Analysis',
        ],
    
        titles : [
            'COT Signals - Rules for Trading with the COT Index',
            'Validity of Signals Compared to Price Movements',
            'COT Signals and Supertrend'
        ],
    
        media : [
            {type: 'image', src: '1CourseWeeklyMedien/cot-index.png', alt: 'Signals in the COT Index', caption: 'Chart provided by TradingView'},
            {type: 'image', src: '1CourseWeeklyMedien/cot-data.png', alt: 'Validity of Signals Compared to Price Movements', caption: 'Chart provided by TradingView'},
            {type: 'video', src: 'videos/CotSignals.mp4', alt: 'COT Signals and Supertrend', caption: 'Chart provided by TradingView'},

        /*{type: 'image', src: 'pics/weeklyCotAnalysis/abcd-market.png', alt: '18 MA Pattern'},
        {type: 'video', src: 'videos/CotDataCotIndexAnalysis.mp4', alt: 'Zick Zack Trendanalyse'},
        {type: 'gif', src: 'path/to/your-gif.gif', alt: 'Gewinnen'}, // Direkter Link zum GIF-Bild
        // weitere Medieneinträge...*/
    ],

    texts : [
            {
                content: [
                    {type: 'paragraph', text: 'Look for a COT index above 75 or below 25.'},
                    {type: 'paragraph', text: 'Above 75: Buy'},
                    {type: 'paragraph', text: 'Below 25: Sell'},
                    {type: 'paragraph', text: 'If COT > 75 and close > open, then we have a buy signal.'},
                    {type: 'paragraph', text: 'If COT < 25 and close < open, then we have a sell signal.'},
                    {type: 'paragraph', text: 'Each signal is valid for 25 days / 6 weeks. These are trading days, not calendar days.'},
                    {type: 'list', items: ['The new COT data is published on Friday evening or Saturday. It should be noted that traders must report their positions to the CFTC on the Tuesday before the publication. This means that we receive this data with a delay of about a week. Therefore, instead of 30 days (6 weeks), it is only 25 days.']},
                    {type: 'paragraph', text: 'In the sixth week, a new signal can emerge. Even if the signal is still valid, a new signal can emerge in the same or opposite direction.'},
                    {type: 'paragraph', text: 'If a counter-signal occurs within the six weeks, the current signal would become invalid.'},
                    {type: 'paragraph', text: 'Another rule applies if the price in the NEXT WEEK falls or rises above or below the high or low, contrary to the signal candle. The signal becomes invalid. (see image: gray arrows with red x)'},
                    {type: 'paragraph', text: 'This is only valid in the week following the signal candle (1st week). If the low or high is broken only in the third week, the signal remains valid.'},
                    {type: 'paragraph', text: 'To trade a COT signal, the weekly signal candle must be broken by a higher high or lower low. From this point, we can look for an entry pattern in the daily chart.'},
                    {type: 'paragraph', text: 'If a signal becomes invalid, it can be interpreted as a supertrend, and we use the signal as a counter-signal in the direction of the main trend. This is only valid in a strong trend.'},
                    {type: 'paragraph', text: 'In a supertrend, we then need a daily close below/above the signal candle in the main trend direction to look for an entry pattern. The 6-week rule no longer applies. The confirmation of a supertrend can also occur 8 weeks after the signal and would still be considered a signal in the main trend direction.'},
                ]
            },
            {
                content: [
                    {type: 'paragraph', text: 'Since we have a strong downtrend on the left side of the image, the COT buy signals become invalid, and we look for sell signals after the low of the buy signal candle is broken downwards with a lower close below the low.'},
                    {type: 'paragraph', text: 'This is called a supertrend as long as the main trend is strong.'},
                    {type: 'paragraph', text: 'However, the general rule is to observe the market for 6 weeks after a valid signal to look for an entry pattern in the daily chart.'},
                    {type: 'paragraph', text: 'Sometimes the market takes its time to initiate a trend change. As you can see, trend lines can also be drawn in the COT data.'},
                    {type: 'paragraph', text: 'COT signals in the direction of the main trend are always preferred. Signals against the main trend should be treated with caution and should ideally occur at a resistance or support level to trade against the trend.'},
                    {type: 'paragraph', text: 'In the further course, we will go into more detail about COT signals and trend determination.'},
                ]
            },
            {
                content: [
                    {type: 'paragraph', text: 'In this video, you will see how the COT signals are analyzed in the chart. The rules for the validity of the signals and the determination of the main trend are discussed.'},
                ]
            }
        ]


    /*{
        content: [
            {type: 'paragraph', text: 'Hier ist ein Absatztext für das Zick Zack Pattern'},
            {type: 'list', items: ['Kurzfristig A', 'Mittelfristig B', 'Langfristig C'] }
        ]
    }*/
}

export default WeeklyCotSignals;