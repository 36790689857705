import React from 'react';

export default function TermsConditions() {
    return (
        <div style={{ marginTop: "120px" }}>

            <h1>General Terms and Conditions</h1>

            <h2>Table of Contents</h2>
            <ol>
                <li>Scope of application</li>
                <li>Services offered by the provider</li>
                <li>Conclusion of contract</li>
                <li>Right to cancel</li>
                <li>Prices and terms of payment</li>
                <li>Content of services and teaching material</li>
                <li>Granting of rights to use digital content and provision of digital content</li>
                <li>Duration and termination of subscription contracts</li>
                <li>Liability for defects</li>
                <li>Liability</li>
                <li>Applicable Law</li>
                <li>Place of Jurisdiction</li>
                <li>Alternative dispute resolution</li>
            </ol>

            <h2>1) Scope of application</h2>
            <p><b>1.1</b> These General Terms and Conditions (hereinafter 'GTC’) of Samuel Pietrowski acting under "COTSeason.com" (hereinafter 'provider') apply to all contracts for participation in online live courses and the provision of digital content (hereinafter 'services’) that a consumer or trader (hereinafter 'client') concludes with the provider regarding the services presented on the provider's website. The inclusion of the client's own terms and conditions is hereby rejected unless otherwise agreed.</p>
            <p><b>1.2</b> A trader pursuant to these GTC is a natural or legal person or a partnership with legal capacity who, when concluding a legal transaction, acts in the exercise of his commercial or independent professional activity.</p>
            <p><b>1.3</b> A consumer pursuant to these GTC is any natural person concluding a legal transaction for a purpose attributed neither to a mainly commercial nor a self-employed occupational activity.</p>
            <p><b>1.4</b> Digital content within the meaning of these GTC is data that is created and provided in digital form.</p>
            <p><b>1.5</b> Depending on the service description of the provider, the subject of the contract can be both the one-off purchase of services and the permanent purchase of services (hereinafter 'subscription contract’). In the case of a subscription contract, the provider undertakes to provide the client with the contractually owed service for the duration of the agreed contract term.</p>

            <h2>2) Services offered by the provider</h2>
            <p><b>2.1</b> The provider offers digital content via his website. The subject matter of this content can be found in the respective service description on the provider's website.</p>
            <p><b>2.2</b> The provider shall provide his services through qualified personnel selected by him. The provider may also utilise the services of third parties (subcontractors) who work on his behalf. Unless otherwise stated in the provider's descriptions, the client has no claim to the selection of a specific person to provide the commissioned service.</p>
            <p><b>2.3</b> The provider shall provide its services with the utmost care and to the best of its knowledge and belief. However, the provider does not owe a specific success. In particular, the provider does not guarantee that the client will achieve a specific learning success or that he will achieve a specific performance target. This depends not least on the personal commitment and will of the client, over which the provider has no influence.</p>

            <h2>3) Conclusion of contract</h2>
            <p><b>3.1</b> The services described on the provider's website do not constitute binding offers on the part of the provider but are intended for the submission of a binding offer by the client.</p>
            <p><b>3.2</b> The client can submit his offer via the online form provided on the provider's website. After entering his data in the form, the client submits a legally binding contractual offer regarding the selected service by clicking the button at the end of the registration process.</p>
            <p><b>3.3</b> The provider can accept the client's offer within five days,</p>
            <ul>
                <li>by sending the client a written confirmation of registration or a confirmation of registration in text form (fax or email), whereby the receipt of the confirmation of registration with the client is decisive in this respect, or</li>
                <li>by requesting payment from the client after submission of the client's contractual declaration</li>
            </ul>
            <p>If several of the aforementioned alternatives exist, the contract is concluded at the point in time when one of the aforementioned alternatives occurs first. If the provider does not accept the client's offer within the aforementioned period, this shall be deemed a rejection of the offer with the consequence that the client is no longer bound by his declaration of intent. In the case of a contract for participation in a live online course, this applies accordingly if the course selected by the client begins before the expiry of the acceptance period and the provider does not accept the client's offer at the latest 24 hours before the start of the course, unless otherwise agreed between the parties.</p>
            <p><b>3.4</b> When submitting an offer via the provider's online form, the text of the contract is saved by the provider after the contract is concluded and sent to the client in text form (e.g. e-mail, fax, or letter) after the offer has been sent. The provider will not disclose the text of the contract in any other form. If the client has set up a user account on the provider's website before sending the offer, the data on the ordered service is archived on the provider's website and can be accessed free of charge by the client via his password-protected user account by entering the corresponding login data.</p>
            <p><b>3.5</b> Before submitting a binding offer via the online form, the client can correct his entries at any time using the usual keyboard and mouse functions. In addition, all entries are displayed again in a confirmation window before the binding submission of the offer and can also be corrected there using the usual keyboard and mouse functions.</p>
            <p><b>3.6</b> The English language is exclusively available for the conclusion of the contract.</p>

            <h2>4) Right to cancel</h2>
            <p>Further information on the right of cancellation can be found in the provider's cancellation policy.</p>

            <h2>5) Prices and terms of payment</h2>
            <p><b>5.1</b> Unless otherwise stated in the provider's service descriptions, the prices quoted are total prices that include the statutory value added tax.</p>
            <p><b>5.2</b> The prices quoted by the provider are total prices. VAT is not shown as the provider is a small business within the meaning of the German Value Added Tax Act (UStG).</p>
            <p><b>5.3</b> Various payment options are available to the client, which are indicated on the provider's website.</p>
            <p><b>5.4</b> For payments in countries outside the European Union, additional costs may be incurred in individual cases for which the provider is not responsible and which are to be borne by the client. These include, for example, costs for the transfer of money by credit institutions (e.g. transfer fees, exchange rate fees).</p>
            <p><b>5.5</b> When selecting the payment method credit card, the invoice amount is due immediately upon conclusion of the contract. Payment by credit card is processed in cooperation with Stripe Payments Europe Ltd., 1 Grand Canal Street Lower, Grand Canal Dock, Dublin, Ireland (hereinafter referred to as "Stripe"). Stripe reserves the right to carry out a credit assessment and to refuse this payment method if the credit check is negative.</p>

            <h2>6) Content of services and teaching material</h2>
            <p><b>6.1</b> The provider is the owner of all rights of use required to provide the services. This also applies regarding teaching materials that may be provided to the client in connection with the provision of services.</p>
            <p><b>6.2</b> Any teaching material accompanying the service (e.g. teaching materials) shall be provided to the client exclusively in electronic form by e-mail or for download. Unless otherwise agreed, the client is not entitled to receive the teaching material in physical form.</p>
            <p><b>6.3</b> The client may only use the content of the services, including any teaching materials provided, to the extent required by the purpose of the contract as agreed by both parties. In particular, the client is not authorised to record the content of the services or parts thereof or to reproduce, distribute or make publicly accessible teaching materials without the provider's separate permission.</p>

            <h2>7) Granting of rights to use digital content and provision of digital content</h2>
            <p><b>7.1</b> Unless otherwise stated in the content description in the trader's online shop, the trader grants the client the non-exclusive right to use the content provided for private purposes only, with no restriction in terms of location or time.</p>
            <p><b>7.2</b> The provision of digital content in the form of streaming occurs at the time of the conclusion of the contract in accordance with Section 3 of these GTC. The client shall not be entitled to download or save the streaming content unless this is expressly permitted in the content description.</p>

            <h2>8) Duration and termination of subscription contracts</h2>
            <p><b>8.1</b> The duration of the subscription contracts is determined in the respective order form.</p>
            <p><b>8.2</b> The client can cancel the subscription contract at any time before the end of the respective contract period. The cancellation takes effect at the end of the current contract period.</p>
            <p><b>8.3</b> The provider reserves the right to terminate the subscription contract prematurely for good cause.</p>

            <h2>9) Liability for defects</h2>
            <p>Claims for defects are governed by the statutory provisions.</p>

            <h2>10) Liability</h2>
            <p><b>10.1</b> The provider is liable for intent and gross negligence in accordance with the statutory provisions. In addition, the provider is also liable for slight negligence if he violates a contractual obligation essential to the contract (so-called cardinal obligation). In such a case, however, liability is limited to the foreseeable damage typical for the contract. The same applies if the provider’s liability is based on the conduct of a subcontractor.</p>
            <p><b>10.2</b> Liability for damage resulting from injury to life, body, or health is unlimited.</p>
            <p><b>10.3</b> Any further liability is excluded.</p>

            <h2>11) Applicable Law</h2>
            <p>German law applies to all legal relationships between the parties. In case the client is a consumer, the statutory provisions of the state in which the client has his habitual residence also apply. The applicability of the UN Convention on Contracts for the International Sale of Goods (CISG) is excluded.</p>

            <h2>12) Place of Jurisdiction</h2>
            <p>The place of jurisdiction for all disputes arising from this contract is the registered office of the provider if the client is a merchant, a legal entity under public law, or a special fund under public law. Otherwise, the statutory provisions shall apply.</p>

            <h2>13) Alternative dispute resolution</h2>
            <p>The provider is not obliged to participate in dispute resolution proceedings before a consumer arbitration board.</p>

            <hr />


            <h1>Allgemeine Geschäftsbedingungen mit Kundeninformationen</h1>

            <h2>Inhaltsverzeichnis</h2>
            <ol>
                <li>Geltungsbereich</li>
                <li>Leistungen des Anbieters</li>
                <li>Vertragsschluss</li>
                <li>Widerrufsrecht</li>
                <li>Preise und Zahlungsbedingungen</li>
                <li>Inhalte der Leistungen und Lehrmaterial</li>
                <li>Bereitstellung von digitalen Inhalten und Einräumung von Nutzungsrechten</li>
                <li>Vertragsdauer und Vertragsbeendigung bei Abonnementverträgen</li>
                <li>Mängelhaftung</li>
                <li>Haftung</li>
                <li>Anwendbares Recht</li>
                <li>Alternative Streitbeilegung</li>
            </ol>

            <h2>1) Geltungsbereich</h2>

            <p><b>1.1</b>&nbsp;Diese Allgemeinen Geschäftsbedingungen (nachfolgend "AGB") des Samuel Pietrowski, handelnd unter "COTSeason.com" (nachfolgend "Anbieter"), gelten für alle Verträge zur Teilnahme an Online-Live-Kursen und die Bereitstellung von digitalen Inhalten (nachfolgend „Leistungen“), die ein Verbraucher oder Unternehmer (nachfolgend „Kunde“) mit dem Anbieter hinsichtlich der auf der Website des Anbieters dargestellten Leistungen abschließt. Hiermit wird der Einbeziehung von eigenen Bedingungen des Kunden widersprochen, es sei denn, es ist etwas anderes vereinbart.</p>

            <p><b>1.2</b>&nbsp;Unternehmer im Sinne dieser AGB ist eine natürliche oder juristische Person oder eine rechtsfähige Personengesellschaft, die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer gewerblichen oder selbständigen beruflichen Tätigkeit handelt. Verbraucher im Sinne dieser AGB ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können.</p>

            <p><b>1.3</b>&nbsp;Digitale Inhalte im Sinne dieser AGB sind Daten, die in digitaler Form erstellt und bereitgestellt werden.</p>

            <p><b>1.4</b>&nbsp;Gegenstand des Vertrages kann – je nach Leistungsbeschreibung des Anbieters - sowohl der einmalige Bezug von Leistungen als auch der dauerhafte Bezug von Leistungen (nachfolgend „Abonnementvertrag“) sein. Beim Abonnementvertrag verpflichtet sich der Anbieter, dem Kunden die vertraglich geschuldete Leistung für die Dauer der vereinbarten Vertragslaufzeit zu erbringen.</p>

            <h2>2) Leistungen des Anbieters</h2>

            <p><b>2.1</b>&nbsp;Der Anbieter stellt über seine Website digitale Inhalte bereit. Deren Gegenstand ergibt sich aus der jeweiligen Leistungsbeschreibung auf der Website des Anbieters.</p>

            <p><b>2.2</b>&nbsp;Der Anbieter erbringt seine Leistungen durch qualifiziertes, von ihm ausgewähltes Personal. Dabei kann sich der Anbieter auch der Leistungen Dritter (Subunternehmer) bedienen, die in seinem Auftrag tätig werden. Sofern sich aus den Beschreibungen des Anbieters nichts anderes ergibt, hat der Kunde keinen Anspruch auf Auswahl einer bestimmten Person zur Erbringung der beauftragten Leistung.</p>

            <p><b>2.3</b>&nbsp;Der Anbieter erbringt seine Leistungen mit größter Sorgfalt und nach bestem Wissen und Gewissen. Einen bestimmten Erfolg schuldet der Anbieter aber nicht. Insbesondere übernimmt der Anbieter keine Gewähr dafür, dass sich beim Kunden ein bestimmter Lernerfolg einstellt oder dass der Kunde ein bestimmtes Leistungsziel erreicht. Dies ist nicht zuletzt auch vom persönlichen Einsatz und Willen des Kunden abhängig, auf den der Anbieter keinen Einfluss hat.</p>

            <p><b>2.4</b>&nbsp;Soweit der Anbieter digitale Inhalte in Form von reproduzierbarem Videomaterial bereitstellt, erfolgt dies ausschließlich über die Bereitstellung eines Online-Video-Streams unter Einsatz entsprechender technischer Mittel. Für die ordnungsgemäße Reproduktion des Streams muss das System des Kunden bestimmte Mindestvoraussetzungen erfüllen, die dem Kunden auf der Website des Anbieters mitgeteilt werden. Für die Einhaltung der Systemvoraussetzungen trägt der Kunde die Verantwortung. Der Anbieter haftet nicht für technische Probleme, die auf mangelhafte Systemvoraussetzungen beim Kunden zurückzuführen sind.</p>

            <p>Soweit der Anbieter sonstige digitale Inhalte liefert, die kein reproduzierbares Videomaterial enthalten, erfolgt die Lieferung per Bereitstellung zum Download.</p>

            <h2>3) Vertragsschluss</h2>

            <p><b>3.1</b>&nbsp;Die auf der Website des Anbieters beschriebenen Leistungen stellen keine verbindlichen Angebote seitens des Anbieters dar, sondern dienen zur Abgabe eines verbindlichen Angebots durch den Kunden.</p>

            <p><b>3.2</b>&nbsp;Der Kunde kann sein Angebot über das auf der Website des Anbieters bereitgestellte Online-Formular abgeben. Dabei gibt der Kunde, nachdem er seine Daten in das Formular eingetragen hat, durch Klicken des den Anmeldevorgang abschließenden Buttons ein rechtlich verbindliches Vertragsangebot in Bezug auf die ausgewählte Leistung ab.</p>

            <p><b>3.3</b>&nbsp;Der Anbieter kann das Angebot des Kunden innerhalb von fünf Tagen annehmen,</p>

            <ul>
                <li>indem er dem Kunden eine schriftliche Anmeldebestätigung oder eine Anmeldebestätigung in Textform (Fax oder E-Mail) übermittelt, wobei insoweit der Zugang der Anmeldebestätigung beim Kunden maßgeblich ist, oder</li>
                <li>indem er den Kunden nach Abgabe von dessen Vertragserklärung zur Zahlung auffordert</li>
            </ul>

            <p>Liegen mehrere der vorgenannten Alternativen vor, kommt der Vertrag in dem Zeitpunkt zustande, in dem eine der vorgenannten Alternativen zuerst eintritt. Nimmt der Anbieter das Angebot des Kunden innerhalb vorgenannter Frist nicht an, so gilt dies als Ablehnung des Angebots mit der Folge, dass der Kunde nicht mehr an seine Willenserklärung gebunden ist. Bei einem Vertrag zur Teilnahme an einem Online-Live-Kurs gilt dies entsprechend für den Fall, dass der vom Kunden ausgewählte Kurs schon vor Ablauf der Annahmefrist beginnt und der Anbieter das Angebot des Kunden nicht bis spätestens 24 Stunden vor Beginn des Kurses annimmt, sofern zwischen den Parteien nichts anderes vereinbart wurde.</p>

            <p><b>3.4</b>&nbsp;Bei der Abgabe eines Angebots über das Online-Formular des Anbieters wird der Vertragstext nach dem Vertragsschluss vom Anbieter gespeichert und dem Kunden nach Absendung von dessen Angebot in Textform (z. B. E-Mail, Fax oder Brief) übermittelt. Eine darüberhinausgehende Zugänglichmachung des Vertragstextes durch den Anbieter erfolgt nicht. Sofern der Kunde vor Absendung des Angebots ein Nutzerkonto auf der Website des Anbieters eingerichtet hat, werden die Daten über die beauftragte Leistung auf der Website des Anbieters archiviert und können vom Kunden über dessen passwortgeschütztes Nutzerkonto unter Angabe der entsprechenden Login-Daten kostenlos abgerufen werden.</p>

            <p><b>3.5</b>&nbsp;Vor verbindlicher Abgabe des Angebots über das Online-Formular kann der Kunde seine Eingaben laufend über die üblichen Tastatur- und Mausfunktionen korrigieren. Darüber hinaus werden alle Eingaben vor der verbindlichen Abgabe des Angebots noch einmal in einem Bestätigungsfenster angezeigt und können auch dort mittels der üblichen Tastatur- und Mausfunktionen korrigiert werden.</p>

            <p><b>3.6</b>&nbsp;Für den Vertragsschluss steht die deutsche Sprache zur Verfügung.</p>

            <p><b>3.7</b>&nbsp;Die Kontaktaufnahme des Anbieters zur Vertragsabwicklung erfolgt in der Regel per E-Mail. Der Kunde hat sicherzustellen, dass die von ihm bei der Abgabe des Angebots angegebene E-Mail-Adresse zutreffend ist, sodass unter dieser Adresse die vom Anbieter versandten E-Mails empfangen werden können. Insbesondere hat der Kunde bei dem Einsatz von SPAM-Filtern sicherzustellen, dass alle vom Anbieter oder von diesem mit der Vertragsabwicklung beauftragten Dritten versandten E-Mails zugestellt werden können.</p>

            <h2>4) Widerrufsrecht</h2>

            <p>Nähere Informationen zum Widerrufsrecht ergeben sich aus der Widerrufsbelehrung des Anbieters.</p>

            <h2>5) Preise und Zahlungsbedingungen</h2>

            <p><b>5.1</b>&nbsp;Die vom Anbieter angegebenen Preise sind Gesamtpreise. Umsatzsteuer wird nicht ausgewiesen, da der Anbieter Kleinunternehmer im Sinne des UStG ist.</p>

            <p><b>5.2</b>&nbsp;Dem Kunden stehen verschiedene Zahlungsmöglichkeiten zur Verfügung, die auf der Website des Anbieters angegeben werden.</p>

            <p><b>5.3</b>&nbsp;Der Kaufpreis wird sofort mit Vertragsschluss fällig. Bei Abonnementverträgen erfolgt die Zahlung im Voraus zu Beginn der jeweiligen Vertragslaufzeit.</p>

            <p><b>5.4</b>&nbsp;Kommt der Kunde in Zahlungsverzug, so ist der Anbieter berechtigt, nach den gesetzlichen Vorschriften Verzugszinsen in Höhe von fünf Prozentpunkten über dem Basiszinssatz zu verlangen. Ist der Kunde Unternehmer, so beträgt der Verzugszins neun Prozentpunkte über dem Basiszinssatz. Der Anbieter behält sich vor, einen höheren Verzugsschaden nachzuweisen und geltend zu machen.</p>

            <h2>6) Inhalte der Leistungen und Lehrmaterial</h2>

            <p><b>6.1</b>&nbsp;Der Anbieter stellt dem Kunden die zur Leistungserbringung notwendigen Lehrmaterialien zur Verfügung. Diese können in elektronischer Form (z. B. PDF-Dokumente) oder in anderer Form bereitgestellt werden.</p>

            <p><b>6.2</b>&nbsp;Das Lehrmaterial ist nur für den persönlichen Gebrauch des Kunden bestimmt. Eine Weitergabe an Dritte, die Vervielfältigung oder die kommerzielle Verwendung sind nicht gestattet. Der Kunde hat die ihm zur Verfügung gestellten Inhalte sorgfältig zu behandeln und vor dem Zugriff Dritter zu schützen.</p>

            <h2>7) Bereitstellung von digitalen Inhalten und Einräumung von Nutzungsrechten</h2>

            <p><b>7.1</b>&nbsp;Der Anbieter stellt dem Kunden die digitalen Inhalte bereit, sobald der Kaufpreis vollständig gezahlt ist. Bei Abonnementverträgen erfolgt die Bereitstellung zu Beginn der vereinbarten Vertragslaufzeit.</p>

            <p><b>7.2</b>&nbsp;Der Anbieter räumt dem Kunden ein einfaches, nicht übertragbares und nicht ausschließliches Nutzungsrecht an den bereitgestellten digitalen Inhalten ein. Das Nutzungsrecht erlischt mit Beendigung des Vertrages.</p>

            <h2>8) Vertragsdauer und Vertragsbeendigung bei Abonnementverträgen</h2>

            <p><b>8.1</b>&nbsp;Der Vertrag wird auf unbestimmte Zeit geschlossen, sofern nicht ausdrücklich eine andere Laufzeit vereinbart wurde. Der Kunde kann den Vertrag jederzeit mit einer Frist von zwei Wochen zum Monatsende kündigen. Die Kündigung hat in Textform (z. B. per E-Mail) zu erfolgen.</p>

            <p><b>8.2</b>&nbsp;Das Recht zur außerordentlichen Kündigung bleibt unberührt.</p>

            <h2>9) Mängelhaftung</h2>

            <p><b>9.1</b>&nbsp;Es gelten die gesetzlichen Vorschriften zur Mängelhaftung.</p>

            <h2>10) Haftung</h2>

            <p><b>10.1</b>&nbsp;Der Anbieter haftet unbeschränkt für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit, die auf einer vorsätzlichen oder fahrlässigen Pflichtverletzung des Anbieters oder eines gesetzlichen Vertreters oder Erfüllungsgehilfen beruhen. Für sonstige Schäden haftet der Anbieter nur, wenn diese auf einer vorsätzlichen oder grob fahrlässigen Pflichtverletzung des Anbieters, eines gesetzlichen Vertreters oder Erfüllungsgehilfen beruhen. Im Übrigen ist die Haftung ausgeschlossen.</p>

            <h2>11) Anwendbares Recht</h2>

            <p>Es gilt das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts.</p>

            <h2>12) Alternative Streitbeilegung</h2>

            <p>Die EU-Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie unter <a href="https://ec.europa.eu/consumers/odr" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr</a> finden. Der Anbieter ist nicht verpflichtet und nicht bereit, an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
        </div>
    );
}
