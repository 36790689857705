const RmmBracketOrder = {
    headers : [
        'Risk and Money Management',
    ], 

    titles : [
        'Money Management - Position Size',
        'Bracket Order',
    ],

    media : [
        {type: 'image', src: '3CourseAdditionalMedien/positionSize.png', alt: 'Position Size'},
        {type: 'image', src: '3CourseAdditionalMedien/BracketOrder.png', alt: 'Bracket Order'},
    ],

    texts : [
        {
            content: [
                {type: 'paragraph', text: 'Position size and risk management are important aspects of trading. They determine how much money you invest in a trade and how much risk you take.'},
                {type: 'paragraph', text: 'Futures are leveraged products and can lead to high losses. Therefore, it is important to control position size and risk.'},
                {type: 'paragraph', text: 'Position size is defined based on the risk per trade and the stop-loss. The risk per trade should generally be 1-3% of the total capital. The stop-loss determines the difference between the entry price and the stop-loss price. Position size is then calculated by dividing the risk per trade by the difference between the entry price and the stop-loss price.'},
                {type: 'paragraph', text: 'Example: You enter at $4500 and the stop-loss is at $4440. The difference is $60. Since futures are leveraged, you need to research the units the market is traded in and how much value each unit represents.'},
                {type: 'paragraph', text: 'For the S&P 500 Micro MES, the risk per tick value is $1.25. If we have a capital of $50,000 and want to risk 3%, then our maximum risk is $1500, which we can trade. Since a point value of 0.25 is determined and a point value corresponds to $1.25, 60 $ risk / 0.25 = 240 points and 240 points * $1.25 = $300 risk per trade. Now, if we divide $1500 / $300, we can trade a maximum of 5 contracts.'},
                {type: 'paragraph', text: 'You can either use the Futures Calculator on our website or research the data for the individual futures on Barchart.'},
                {type: 'paragraph', text: 'As you can see on the right side of the image, different account sizes have different risks, and additionally, depending on the market situation and trade direction.'},
                {type: 'download', text: 'Barchart', href: 'https://www.barchart.com/futures'},

            ]
        },
        {
            content: [
                {type: 'paragraph', text: 'Bracket orders are a way to control risk and secure profits. They consist of an entry order, a stop-loss, and a take-profit.'},
                {type: 'paragraph', text: 'Bracket orders are placed before the trade and automatically executed once the market reaches the entry price. The stop-loss and take-profit are also automatically executed once the market reaches these prices.'},
                {type: 'paragraph', text: 'Bracket orders are an effective way to minimize risk and secure profits. They allow you to manage the trade without constantly watching the market.'},
                {type: 'list', items: [
                   'Entry = Stop Limit = Stop Price; For long trades: High + 1 tick; For short trades: Low - 1 tick or the calculated entry +/- 1 tick',
                   'In addition to the entry, we need a stop-limit = limit = stop price +/- max 5 ticks. This is important because the price at market opening sometimes has a gap, i.e., a different opening value than the previous day\'s closing price. This ensures that the trade is only opened within this price range of max. 5 ticks.',
                   'The take-profit is referred to as a limit order.',
                   'The stop-loss is referred to as a stop order.'
                ]},
                {type: 'paragraph', text: '!!! Important: Calculate the values tick- or point-accurately, as this can be very expensive for contracts like a normal future where one tick can correspond to $12.5.'},
                ]
        },
    ]
};

export default RmmBracketOrder;