import React from 'react';
import { collection, onSnapshot, doc, addDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import '../css/checkout.css';
import { toast } from 'react-toastify';
import { getAuth } from 'firebase/auth';
import CheckIcon from './CheckIcon';
import { useNavigate } from 'react-router-dom';
import { useAuthStatus } from '../hooks/useAuthStatus';
import { usePaymentStatus } from '../hooks/usePaymentStatus';
import Footer from '../landingComponents/Footer';

export default function Checkout() {

  const navigate = useNavigate();
  const { loggedIn } = useAuthStatus();
  const auth = getAuth();
  const currentUser = auth.currentUser;
  const paymentStatus = usePaymentStatus();

  const createCheckoutSession = async (priceId, mode, productId) => {
    try {
      // Wenn der Benutzer nicht eingeloggt ist, zur Registrierungsseite weiterleiten
      if (!loggedIn) {
        navigate('/sign-up');
        return;
      }

      // Überprüfen, ob der Benutzer die Terms and Conditions und Risk Disclosure akzeptiert hat
      if (currentUser) {
          const userDocRef = doc(db, 'users', currentUser.uid);
          const userDoc = await getDoc(userDocRef);
      
          if (userDoc.exists()) {
              const userData = userDoc.data();
              if (!userData.agb || !userData.riskDisclosure) {
                  toast.error("You must accept the Terms and Conditions and Risk Disclosure to proceed.");
                  navigate('/profile'); // Umleiten zur Profilseite
                  return;
              }
          } else {
              console.log("No such document!");
              toast.error("User data not found.");
              navigate('/profile'); // Umleiten zur Profilseite
              return;
          }
      }

      // Überprüfen, ob das Produkt bereits gekauft wurde (nur für den Master Course)
      if (productId === 2 && paymentStatus === 'succeeded') {
        toast.info("You already own this product.");
        return;
      }

      // Überprüfen, ob der Benutzer den Master Course gekauft hat, bevor er den Evaluation Service kauft
      if (productId === 3 && paymentStatus !== 'succeeded') {
        toast.info("Please buy the Master Course to access this service.");
        return;
      }

      if (currentUser) {
        const docRef = await addDoc(
          collection(doc(db, 'customers', currentUser.uid), 'checkout_sessions'),
          {
            mode: mode,
            price: priceId,
            success_url: window.location.origin + '/welcome',
            cancel_url: window.location.origin + '/checkout',
          }
        );
        toast.success('You will be redirected to the payment page');

        onSnapshot(docRef, async (snap) => {
          const { error, url } = snap.data();

          if (error) {
            toast.error('Error creating checkout session');
          } else if (url) {
            window.location.href = url;
            toast.info('Please note: It may take a short time for your purchase to be registered in the system to unlock the content.');
            
            if (productId === 1) {
              showSubscriptionInfoPopup();
            } else if (productId === 3) {
              payedEvaluationService();
            }
          }
        });
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
    }
  };

  const showSubscriptionInfoPopup = () => {
    alert(
      "Thank you for purchasing the COT Trading Pro - Indicators Subscription! " +
      "To activate the indicators, please send me a message on my TradingView account: " +
      "https://www.tradingview.com/u/sammypi/" +
      "The indicators will be activated within 24 hours. You can find them under the 'Invite-only scripts' section in TradingView."
    );
  };

  const payedEvaluationService = () => {
    alert(
      'Thank you for purchasing the COT Trading Pro - Evaluation Service! ' +
      'Please send your backtest evaluations to "salamontraderinfo@gmail.com" using the email you registered with. ' +
      'The evaluation will be completed within 96 hours. '
    )
  };

  const products = [
    {
      id: 1,
      title: "COT Trading Pro - Indicators Subscription",
      price: "$20.00/month",
      priceId: "price_1PvGXkL3qVLRkkN4RXsanV2E",
      imgSrc: "../../Medien/kapics/indicatorsub.png",
      features: [
        "Monthly subscription for the following TradingView indicators:",
        "Trendfilter Chart",
        "Trendfilter AD1 and AD2",
        "Trendfilter MTF-C",
        "COT Trendfilter + Signals (Cycles, Momentum, Normal)",
        "Regular updates and new indicators",
        "Exclusive support for setup and use through TradingView Chat",
      ],
      mode: "subscription",
    },
    {
      id: 2,
      title: "COT Trading Pro - Master Course",
      price: "Now only $99.00 instead of $499.00",
      priceId: "price_1PpTR9L3qVLRkkN4yKZhKw5y",
      imgSrc: "../../Medien/kapics/buyCard.jpg",
      features: [
        "Fundamental analysis based on COT data",
        "Creating the weekly watchlist",
        "Chart analysis with entry patterns",
        "Timing and trade setups",
        "Trade management & stop techniques",
        "Risk-money management",
        "Trading psychology",
        "TradingView indicators + Price Action I & II",
        "Seasonals - statistical evaluation",
        "Short intro into cycle analysis",
      ],
      mode: "payment",
    },
    {
      id: 3,
      title: "COT Trading Pro - Evaluation Service",
      price: "$149.00",
      priceId: "price_1PvGh2L3qVLRkkN4qMVaPMg0",
      imgSrc: "../../Medien/kapics/coaching.png",
      features: [
        "Review of backtest evaluations",
        "Feedback on trade executions",
        "Detailed analysis of COT and daily patterns",
        "Ensures correct understanding and application",
        "Evaluation of a maximum 3-year historical backtest or 50 trades",
        "Analysis based on weekly COT data and daily setup patterns",
      ],
      mode: "payment",
    },
  ];

  return (
    <>
      <div className="Checkout-Container">
        {products.map((product) => (
          <div
            key={product.id}
            className={`checkout-content ${product.id === 2 ? 'main-product' : ''}`}
          >
            <img
              src={product.imgSrc}
              alt={product.title}
              className="checkout-img"
            />
            <div>
              <h3 className="checkout-h3">{product.title}</h3>
              <h5 className="checkout-h5">Amount to Pay: {product.price}</h5>
            </div>
            <ul className="checkout-features">
              {product.features.map((feature, index) => (
                <li key={index}>
                  <CheckIcon />
                  {feature}
                </li>
              ))}
            </ul>
            <button
              className="checkoutButton"
              onClick={() => createCheckoutSession(product.priceId, product.mode, product.id)}
            >
              Buy Now
            </button>
          </div>
        ))}
      </div>
      <Footer />
    </>
  );
}
