const WheaterReport = {
    headers : [
            'Weather Report',
        ], 
    
        titles : [
            'Weather Influences on Commodities',
        ],
    
        media : [
            {type: 'image', src: '3CourseAdditionalMedien/weather.png', alt: 'Weather', caption: '@ drought.com'},



        /*{type: 'image', src: 'pics/weeklyCotAnalysis/abcd-market.png', alt: '18 MA Pattern'},
        {type: 'video', src: 'videos/CotDataCotIndexAnalysis.mp4', alt: 'Zick Zack Trendanalyse'},
        {type: 'gif', src: 'path/to/your-gif.gif', alt: 'Gewinnen'}, // Direkter Link zum GIF-Bild
        // weitere Medieneinträge...*/
    ],


    texts : [
        {
            content: [
                {type: 'paragraph', text: 'Weather influences can affect commodity prices. Some commodities are particularly weather-dependent, such as agricultural commodities.'},
                {type: 'paragraph', text: 'Droughts, floods, frost, or dryness can impact crop yields and lead to supply fluctuations.'},
                {type: 'paragraph', text: 'This can, in turn, lead to price fluctuations as supply and demand change.'},
                {type: 'paragraph', text: 'Traders and investors can therefore keep an eye on weather conditions and adjust their trading decisions accordingly.'},
                {type: 'paragraph', text: 'Certainly, it takes time and effort to stay informed about weather conditions, but it can be worthwhile as they can have a significant impact on commodity prices. However, it must be personally weighed whether this effort provides added value.'},
                {type: 'download', text: 'Current Drought Conditions & Outlook', href: 'https://www.drought.gov/'},
                {type: 'download', text: 'National Weather Service Alert Map', href: 'https://www.weather.gov/'},
                {type: 'download', text: 'Today\'s Agricultural Weather Highlights', href: 'https://www.usda.gov/'},
                {type: 'download', text: 'Radar Map', href: 'https://weather.com/'},
                {type: 'download', text: 'Dark Sky Weather Maps & Forecasts', href: 'https://www.cleardarksky.com/csk/'},
                {type: 'download', text: 'Weekly Weather & Crop Bulletin', href: 'https://www.usda.gov/'},
                {type: 'download', text: 'Canadian Weather', href: 'https://weather.gc.ca/'},
                {type: 'download', text: 'Fire Weather', href: 'https://www.weather.gov/fire/'},
                {type: 'download', text: '6 to 10-Day Precipitation Outlook', href: 'https://www.cpc.ncep.noaa.gov/'},
                {type: 'download', text: '6 to 10-Day Temperature Outlook', href: 'https://www.cpc.ncep.noaa.gov/'},
                {type: 'download', text: 'Daily/Monthly Forecasts', href: 'https://weather.com/'},
                {type: 'download', text: '30-Day Outlook Maps', href: 'https://www.cpc.ncep.noaa.gov/'},
                {type: 'download', text: 'Seasonal Outlook Maps', href: 'https://www.cpc.ncep.noaa.gov/'},
                {type: 'download', text: 'National & Regional Snow Maps', href: 'https://www.nohrsc.noaa.gov/'},
                {type: 'download', text: 'National Hurricane Center', href: 'https://www.nhc.noaa.gov/'},
                {type: 'download', text: 'Hurricane and Tropical Cyclones', href: 'https://www.wunderground.com/'},
                {type: 'download', text: 'Weekly Drought Monitor', href: 'https://droughtmonitor.unl.edu/'},
                {type: 'download', text: 'Drought Monitor 12-Week Slideshow', href: 'https://droughtmonitor.unl.edu/'},
                {type: 'download', text: 'Palmer Drought Index', href: 'https://www.cpc.ncep.noaa.gov/'},
                {type: 'download', text: 'Seasonal Drought Outlook', href: 'https://www.cpc.ncep.noaa.gov/'}
            ]
        },

        /*{
            content: [
                {type: 'paragraph', text: 'Hier ist ein Absatztext für das Zick Zack Pattern'},
                {type: 'list', items: ['Kurzfristig A', 'Mittelfristig B', 'Langfristig C'] }
                {type: 'download', text: 'Einstellungen für die Wochenanalyse', href: '1CourseWeeklyPictures/COTSettingsWeekly.png'}
            ]
        }*/
    ]
};

export default WheaterReport;



