import WhatIsCot from '../content/1WhatIsCot.js';
import IndicatorSettings from '../content/2IndicatorSettings.js';
import WeeklyCotSignals from '../content/3WeeklyCotSignals.js';
import CotExtreme from '../content/4CotExtreme.js';
import CotSentiment from '../content/5CotSentiment.js';
import CotBubble from '../content/6CotBubble.js';
import AbcdMarkets from '../content/8ABCDMarkets.js';
import MarketPriceStructure from '../content/7Marketstructure.js';
import SeasonalsCycles from '../content/9SeasonalCycles.js';
import WeeklyAnalysisSummary from '../content/10WeeklyAnalysisSummary.js';
import Pattern from '../content/11Pattern.js';
import StoplossTechniques from '../content/12StoplossTechniques.js';
import ProfitTarget from '../content/13ProfitTarget.js';
import ProffPoiv from '../content/14ProffPoiv.js';
import Backtesting from '../content/15Backtesting.js';
import RmmBracketOrder from '../content/16RmmBracketOrder.js';
import ContractSpreads from '../content/17ContractsSpreads.js';
import FinViz from '../content/18finViz.js';
import RelativeStrengthComparrison from '../content/19RelativeStrengthComparrison.js';
import Correlation from '../content/20Corrrelation.js';
import Tradingplan from '../content/21Tradingplan.js';
import Psychology from '../content/22Psychology.js';
import WasdeReport from '../content/23WasdeReport.js';
import WheaterReport from '../content/24WeahterReport.js';
import Broker from '../content/25Broker.js';
import Examples from '../content/26Examples.js';

const courseContent = [
    { id: 1, title: 'What is COT?', content: WhatIsCot },
    { id: 2, title: 'Indicator Settings', content: IndicatorSettings},
    { id: 3, title: 'Weekly COT Signals', content: WeeklyCotSignals},
    { id: 4, title: 'COT Extreme', content: CotExtreme},
    { id: 5, title: 'COT Sentiment', content: CotSentiment},
    { id: 6, title: 'COT Bubble', content: CotBubble},
    { id: 7, title: 'Market Price Structure', content: MarketPriceStructure},
    { id: 8, title: 'ABCD Markets', content: AbcdMarkets},
    { id: 9, title: 'Seasonals & Cycles', content: SeasonalsCycles},
    { id: 10, title: 'Weekly Analysis Summary', content: WeeklyAnalysisSummary},
    { id: 11, title: 'Pattern', content: Pattern},
    { id: 12, title: 'Stoploss Techniques', content: StoplossTechniques},
    { id: 13, title: 'Profit Target', content: ProfitTarget},
    { id: 14, title: 'Proff, POIV Indikatoren', content: ProffPoiv},
    { id: 15, title: 'Backtests', content: Backtesting},
    { id: 16, title: 'RMM Bracket Order', content: RmmBracketOrder},
    { id: 17, title: 'Contracts & Spreads', content: ContractSpreads},
    { id: 18, title: 'FinViz', content: FinViz},
    { id: 19, title: 'Relative Strength Comparrison', content: RelativeStrengthComparrison},
    { id: 20, title: 'Correlation', content: Correlation},
    { id: 21, title: 'Tradingplan & Tradingjournal', content: Tradingplan},
    { id: 22, title: 'Psychology', content: Psychology},
    { id: 23, title: 'Wasde Report', content: WasdeReport},
    { id: 24, title: 'Wheater Report', content: WheaterReport},
    { id: 25, title: 'Broker', content: Broker},
    { id: 26, title: 'Examples', content: Examples},


    // Weitere Kurse können hier hinzugefügt werden...
];

export default courseContent;
