const Examples = {
    headers : [
        'Beispieltrades',
    ], 

    titles : [
        'Hier finden Sie einige Beispieltrades, die auf den in diesem Kurs behandelten Konzepten basieren. Diese Trades dienen dazu, die Anwendung der Konzepte in der Praxis zu veranschaulichen und Ihnen zu zeigen, wie Sie sie in Ihrem eigenen Trading umsetzen können.',

    ],

    media : [
        {type: 'image', src: '3CourseAdditionalMedien/patternexample.png', alt: 'Preischart', caption: '@ TradingView.com'},



        /*{type: 'image', src: 'pics/weeklyCotAnalysis/abcd-market.png', alt: '18 MA Pattern'},
        {type: 'video', src: 'videos/CotDataCotIndexAnalysis.mp4', alt: 'Zick Zack Trendanalyse'},
        {type: 'gif', src: 'path/to/your-gif.gif', alt: 'Gewinnen'}, // Direkter Link zum GIF-Bild
        // weitere Medieneinträge...*/
    ],


    texts : [
        {
            content: [
                {type: 'paragraph', text: 'Hier finden Sie einige Beispieltrades, die auf den in diesem Kurs behandelten Konzepten basieren. Diese Trades dienen dazu, die Anwendung der Konzepte in der Praxis zu veranschaulichen und Ihnen zu zeigen, wie Sie sie in Ihrem eigenen Trading umsetzen können.'},
            ]
        },

        /*{
            content: [
                {type: 'paragraph', text: 'Hier ist ein Absatztext für das Zick Zack Pattern'},
                {type: 'list', items: ['Kurzfristig A', 'Mittelfristig B', 'Langfristig C'] }
                {type: 'download', text: 'Einstellungen für die Wochenanalyse', href: '1CourseWeeklyPictures/COTSettingsWeekly.png'}
            ]
        }*/
    ]
};

export default Examples;



