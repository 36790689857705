import * as d3 from 'd3';

export const groupByYear = (data) => {
    const years = {};
    data.forEach(row => {
        const year = new Date(row.time).getFullYear();
        if (!years[year]) {
            years[year] = [];
        }
        years[year].push(row);
    });
    return years;
};

export const calculatePercentageChanges = (tableData, usePreviousClose) => {
    const percentageChanges = {};

    Object.keys(tableData).forEach(year => {
        const yearData = tableData[year];
        percentageChanges[year] = yearData.map((row, colIndex) => {
            const open = parseFloat(row.open);
            const close = parseFloat(row.close);

            if (isNaN(open) || isNaN(close)) {
                return null;
            }

            if (usePreviousClose) {
                if (colIndex === 0) {
                    // Für den ersten Datensatz eines jeden Jahres: close - open
                    const change = ((close - open) / open) * 100;
                    return {
                        date: row.date,
                        change: `${close} - ${open} / ${open} * 100 = ${change.toFixed(6)}%`,
                        value: change
                    };
                } else {
                    // Für die darauf folgenden Datensätze: close - previous close
                    const previousClose = parseFloat(yearData[colIndex - 1].close);
                    if (!isNaN(previousClose)) {
                        const changeFromPreviousClose = ((close - previousClose) / previousClose) * 100;
                        return {
                            date: row.date,
                            change: `${close} - ${previousClose} / ${previousClose} * 100 = ${changeFromPreviousClose.toFixed(6)}%`,
                            value: changeFromPreviousClose
                        };
                    }
                    return null;
                }
            } else {
                if (row.open === undefined || row.open === null || row.open === '' || row.close === undefined || row.close === null || row.close === '') {
                    return null;
                }
                const change = ((row.close - row.open) / row.open) * 100;
                return {
                    date: row.date,
                    change: `${close} - ${open} / ${open} * 100 = ${change.toFixed(6)}%`,
                    value: change
                };
            }
        });
    });

    return percentageChanges;
};

export const calculatePercentageStats = (percentageChanges, maxColumns, usePreviousClose) => {
    const columnCount = usePreviousClose ? maxColumns - 1 : maxColumns;
    const sums = Array(columnCount).fill(0);
    const counts = Array(columnCount).fill(0);
    const positiveCounts = Array(columnCount).fill(0);
    const positiveSums = Array(columnCount).fill(0);
    const negativeCounts = Array(columnCount).fill(0);
    const negativeSums = Array(columnCount).fill(0);
    const zeroCounts = Array(columnCount).fill(0); // Hinzufügen eines Arrays für Nullwerte

    Object.values(percentageChanges).forEach(yearData => {
        yearData.forEach((change, colIndex) => {
            if (change !== null && change.value !== null) {
                const value = change.value;
                sums[colIndex] += value;
                counts[colIndex] += 1;
                if (value > 0) {
                    positiveCounts[colIndex] += 1;
                    positiveSums[colIndex] += value;
                } else if (value < 0) {
                    negativeCounts[colIndex] += 1;
                    negativeSums[colIndex] += value;
                } else {
                    zeroCounts[colIndex]++; // Nullwerte zählen
                }
            }
        });
    });

    const averages = sums.map((sum, index) => (counts[index] > 0 ? (sum / counts[index]).toFixed(2) : 0));
    const positiveAverages = positiveSums.map((sum, index) => (positiveCounts[index] > 0 ? (sum / positiveCounts[index]).toFixed(2) : 0));
    const negativeAverages = negativeSums.map((sum, index) => (negativeCounts[index] > 0 ? (sum / negativeCounts[index]).toFixed(2) : 0));
    const positivePercentages = positiveCounts.map((count, index) => (counts[index] > 0 ? ((count / counts[index]) * 100).toFixed(2) : 0));
    const negativePercentages = negativeCounts.map((count, index) => (counts[index] > 0 ? ((count / counts[index]) * 100).toFixed(2) : 0));

    return { sums, counts, averages, positiveCounts, positiveSums, positiveAverages, negativeCounts, negativeSums, negativeAverages, positivePercentages, negativePercentages, zeroCounts };
};

//// for seasonal decomposition
// Funktion zur Berechnung der saisonalen, Trend- und Residualkomponente
// Symmetrischer gleitender Durchschnitt
// Funktion zur Berechnung der saisonalen, Trend- und Residualkomponenten
export const decomposeTimeSeries = (data, smoothingAlgorithm) => {
    const smoothedData = smoothingAlgorithm(data);
    
    const trend = smoothedData.map(d => d.close); // Trend basierend auf den geglätteten Daten
    const seasonal = data.map((d, i) => d.close - trend[i]); // Saisonale Komponente
    const residual = data.map((d, i) => d.close - trend[i] - seasonal[i]); // Residual

    return { trend, seasonal, residual };
};

// Kernel-Glättung
export const kernelSmoothing = (data, sigma) => {
    const kernel = (x, sigma) => Math.exp(-0.5 * (x / sigma) ** 2) / (sigma * Math.sqrt(2 * Math.PI));
    return data.map((d, i) => {
        let weights = data.map((_, j) => kernel(i - j, sigma));
        let weightSum = d3.sum(weights);
        weights = weights.map(w => w / weightSum);
        let smoothedValue = d3.sum(data.map((d, j) => d.close * weights[j]));
        return { ...d, close: smoothedValue };
    });
};