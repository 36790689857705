import React, { useState } from 'react';
import '../css/standard.css';
import '../css/formular.css';
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, getAuth } from 'firebase/auth';
import { toast } from 'react-toastify';
import OAuth from '../components/OAuth';
import Footer from '../landingComponents/Footer';

export default function SignIn() {
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const { email, password } = formData;
  const navigate = useNavigate();

  function onChange(e) {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value
    }));
  }

  async function onSubmit(e) { 
    e.preventDefault();
    try {
      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      if (userCredential.user) {
        navigate('/welcome');
      }
    } catch (error) {
      toast.error('Bad user credentials');
    }
  }

  return (
    <>
      <section className="SignIn-Container" id='profil-content'>
        <h1 className="si-header">Sign In</h1>

        <div className="si-sub-container">
          {/*
          <div className="login-image">
            <img src="../../Medien/formular/login.jpg" alt="login" />
          </div>*/}

          <div className="si-form-container">
            <form className="login-form" onSubmit={onSubmit}>
              <input 
                type="email" 
                id="email" 
                value={email} 
                onChange={onChange}
                placeholder='Email Address'
              />
              <div className="form-password">
                <input 
                  type={showPassword ? 'text' : 'password'}
                  id="password" 
                  value={password}
                  onChange={onChange}
                  placeholder='Password'
                />
                {showPassword ? (
                  <AiFillEyeInvisible 
                    className="eye-icon"
                    onClick={() => setShowPassword(prevState => !prevState)}
                  />
                  ) : (
                  <AiFillEye 
                    className="eye-icon"
                    onClick={() => setShowPassword(prevState => !prevState)}
                  />
                  )}
              </div>
              <div className="si-forgot">
                <p>Don't have a account? 
                  <Link to="/sign-up">Sign Up</Link>
                </p>
                <p>
                  <Link to="/forgot-password">Forgot password?</Link>
                </p>
              </div>
              <input type="submit" value="Sign In" />
              <OAuth />
            </form>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}