import React from 'react';

export default function RiskDisclosure() {
  return (
    <div>
      <h2>Risk Disclosure</h2>
      <p>
        Trading in futures, forex, and CFDs carries a high level of risk and is not suitable for all investors. There is a possibility of losing 
        more than the initial investment. Only risk capital or funds that can be lost without affecting your financial situation should be used 
        for trading. Past performance does not guarantee future results.
      </p>
      <p>
        Hypothetical performance results have many limitations. The results shown are based on historical data and do not involve actual financial risk. 
        No hypothetical trading result can fully represent the risks of actual trading. For example, losses can lead to positions being closed earlier, 
        which can affect results. There are many factors that are not considered in hypothetical results but can significantly affect actual results.
      </p>
      <p>
        The testimonials shown on this website are not representative of all clients and do not guarantee future performance or success.
      </p>
      <p>
        All published information is based on sources we consider reliable. Despite careful review, we do not guarantee the accuracy of the information. 
        Good results in the past do not guarantee future success. Trading in options, futures, CFDs, ETFs, and other derivatives involves high profit 
        potential but also high risk of loss.
      </p>
      <p>
        Our strategies and trades are speculative and short-term oriented. It is not recommended to finance such speculative investments on credit. 
        In extreme cases, there can be a total loss of the invested capital as well as margin calls. This can lead to further losses beyond the invested capital.
      </p>
      <p>
        The analyses published on this website do not constitute investment advice. They are neither a recommendation nor an offer or solicitation to 
        buy/sell stocks, options, futures, and/or currencies. There is no guarantee that the trading methods described on our pages will generate profits 
        or protect against losses.
      </p>
      <p>
        The provided analyses are for informational purposes only and do not replace individual advice. Liability for the consequences of the provided 
        information is excluded.
      </p>
      <p>
        The CFTC (Commodity Futures Trading Commission) recommends the acceptance of the following points:
      </p>
      <ul>
        <li>All results shown are based on simulated or hypothetical performance results with certain limitations, as they do not represent actual trading.</li>
        <li>Since these transactions were not actually executed, these results may misrepresent the impact of certain market conditions, such as lack of liquidity.</li>
        <li>Hypothetical trading programs are created retrospectively. There is no guarantee that future results will resemble those shown.</li>
      </ul>
      <p>
        Hypothetical performance results have many limitations, such as the benefit of hindsight. Since there are no financial risks in hypothetical trading, 
        these results cannot reflect the impact of financial risks in real trading. The ability to endure losses or adhere to a trading program can significantly 
        affect actual results.
      </p>
      <p>
        Past results of COT Trading Pro are not representative of future performance.
      </p>
      <p>
        Monthly and annual results should be considered hypothetical. In reality, these results do not represent the performance of the method creator or users. 
        There is no guarantee that applying the described methods will lead to the same results.
      </p>
      <p>
        It is important to understand that technical issues such as data or server outages can occur. These can increase risk. Markets do not always guarantee 
        exact executions, and rapid market movements can lead to slippage.
      </p>
      <p>
        Futures transactions involve the obligation to deliver or receive the underlying asset of the contract at a future date or to settle the position in cash. 
        They carry a high level of risk. The use of leverage can lead to significant gains as well as significant losses.
      </p>
      <p>
        CFTC Risk Disclosure: Trading in futures, forex, and options carries significant risks. You should only engage in these transactions if you fully understand 
        the nature of the contracts and can accept the risks. There is a possibility of a complete loss of the invested capital and potential margin calls.
      </p>
      <p>
        The following statement is provided in accordance with Regulation 1.55(c) of the Commodity Futures Trading Commission (CFTC). This brief statement does not 
        disclose all the risks and other significant aspects of trading in futures, forex, and options. Given the risks, you should only engage in such transactions 
        if you fully understand the nature of the contracts (and contractual relationships) you are entering into and the extent of your risk. Trading in futures, 
        forex, and options is not suitable for many members of the public. You should carefully consider whether trading is appropriate for you in light of your 
        experience, objectives, financial resources, and other relevant circumstances.
      </p>
      <p>
        The risk of loss in trading commodity futures contracts and foreign currencies can be substantial. You should therefore carefully consider whether such trading 
        is suitable for you in light of your circumstances and financial resources. You should be aware of the following points:
      </p>
      <ol>
        <li>You may sustain a total loss of the funds you deposit with your broker to establish or maintain a position in the commodity futures or foreign exchange markets, and you may incur losses beyond these amounts. If the market moves against your position, your broker may call upon you to deposit a substantial additional margin on short notice to maintain your position. If you do not provide the required funds within the time prescribed by your broker, your position may be liquidated at a loss, and you will be liable for any resulting deficit in your account.</li>
        <li>The funds you deposit with a futures commission merchant for trading futures and options on futures are not protected by the Federal Deposit Insurance Corporation (FDIC) or, in the case of funds deposited with a broker-dealer for trading foreign exchange, by the Securities Investor Protection Corporation (SIPC).</li>
        <li>The funds you deposit with your broker are not protected from loss.</li>
        <li>You may be required to liquidate positions at unfavorable times and prices or to make additional margin payments to meet margin requirements.</li>
        <li>It may be difficult or impossible to liquidate a position due to certain market conditions. Placing contingent orders, such as "stop-loss" or "stop-limit" orders, will not necessarily limit your losses to the intended amounts, as market conditions may make it impossible to execute such orders.</li>
      </ol>
      <p>
        Under certain market conditions, it may be difficult or impossible to liquidate a position. This can occur, for example, when the market reaches a daily price fluctuation limit ("limit move").
      </p>
      <p>
        Placing contingent orders by you or your trading advisor, such as a "stop-loss" or "stop-limit" order, will not necessarily limit your losses to the intended amounts, as market conditions may make it impossible to execute such orders. A "spread" position may not be less risky than a simple "long" or "short" position. The high leverage that can be achieved in futures and options trading due to low margin requirements can work against you as well as for you. The use of leverage can lead to large losses as well as gains. In some cases, managed commodity accounts are subject to substantial fees for management and advisory services. These accounts may need to achieve significant trading gains to avoid depletion or exhaustion of their assets. You should promptly review your account statements when you receive them. To clarify your rights and obligations in the event of a dispute with your broker, you should review all documentation and statements provided by your broker.
      </p>
      <p>
        Note: Trading in financial instruments carries significant risks and can lead to the loss of the entire invested capital. 
        Please ensure that you understand and comply with the specific regulations and requirements of your country. 
        Many countries have specific regulatory authorities, such as the European Securities and Markets Authority (ESMA) in the EU, 
        the Financial Conduct Authority (FCA) in the United Kingdom, the Australian Securities and Investments Commission (ASIC) in Australia, 
        and the Canadian Securities Administrators (CSA) in Canada. Be informed about the relevant regulations and requirements 
        of your local regulatory authority.
      </p>
      <hr />
      <h2>Risikohinweis</h2>
      <p>
        Der Handel mit Futures, Forex und CFDs birgt ein erhebliches Risiko und ist nicht für jeden Investor geeignet. Es besteht die Möglichkeit, 
        mehr als das eingesetzte Kapital zu verlieren. Für den Handel sollten nur Risikokapital oder Kapitalanteile verwendet werden, deren Verlust 
        keinen Einfluss auf die finanzielle Situation hat. Historische Ergebnisse garantieren keine zukünftigen Gewinne.
      </p>
      <p>
        Hypothetische Performanceergebnisse haben viele Einschränkungen. Die dargestellten Ergebnisse beruhen auf historischen Daten und 
        beinhalten kein tatsächliches finanzielles Risiko. Kein hypothetisches Handelsergebnis kann die Risiken eines tatsächlichen Handels 
        vollständig darstellen. Beispielsweise können Verluste dazu führen, dass Positionen früher geschlossen werden, was die Ergebnisse 
        beeinflussen kann. Es gibt viele Faktoren, die in den hypothetischen Ergebnissen nicht berücksichtigt werden, aber die tatsächlichen 
        Ergebnisse stark beeinflussen können.
      </p>
      <p>
        Die auf dieser Website gezeigten Testimonials sind nicht repräsentativ für alle Kunden und garantieren keine zukünftigen Leistungen oder Erfolge.
      </p>
      <p>
        Alle veröffentlichten Informationen beruhen auf Quellen, die wir für zuverlässig halten. Trotz sorgfältiger Prüfung übernehmen wir keine 
        Gewähr für die Richtigkeit der Angaben. Gute Ergebnisse in der Vergangenheit garantieren keine zukünftigen Erfolge. Der Handel mit Optionen, 
        Futures, CFDs, ETFs und anderen Derivaten ist mit hohen Gewinnchancen, aber auch mit hohem Verlustrisiko verbunden.
      </p>
      <p>
        Unsere Strategien und Trades sind spekulativ und kurzfristig orientiert. Es wird davon abgeraten, solche spekulativen Investitionen auf Kredit zu 
        finanzieren. In Extremfällen kann es zu einem Totalverlust des investierten Kapitals sowie zu Nachschusspflichten kommen. Dies kann zu weiteren 
        Verlusten führen, die über das eingesetzte Kapital hinausgehen.
      </p>
      <p>
        Die auf dieser Website veröffentlichten Analysen stellen keine Anlageberatung dar. Sie sind weder eine Empfehlung noch ein Angebot oder eine 
        Aufforderung zum Kauf/Verkauf von Aktien, Optionen, Futures und/oder Währungen. Es gibt keine Garantie, dass die auf unseren Seiten beschriebenen 
        Handelsmethoden Gewinne generieren oder vor Verlusten schützen.
      </p>
      <p>
        Die bereitgestellten Analysen dienen lediglich der Information und ersetzen keine individuelle Beratung. Eine Haftung für die Folgen aus den 
        bereitgestellten Informationen ist ausgeschlossen.
      </p>
      <p>
        Die CFTC (Commodity Futures Trading Commission) empfiehlt die Annahme folgender Punkte:
      </p>
      <ul>
        <li>Alle dargestellten Ergebnisse basieren auf simulierten oder hypothetischen Performanceergebnissen mit bestimmten Einschränkungen, da sie nicht den tatsächlichen Handel abbilden.</li>
        <li>Da diese Transaktionen nicht tatsächlich durchgeführt wurden, könnten diese Ergebnisse die Auswirkungen bestimmter Marktbedingungen, wie z.B. fehlender Liquidität, falsch darstellen.</li>
        <li>Hypothetische Handelsprogramme sind rückblickend erstellt. Es gibt keine Garantie, dass zukünftige Ergebnisse den gezeigten ähneln.</li>
      </ul>
      <p>
        Hypothetische Performanceergebnisse weisen viele Einschränkungen auf, wie zum Beispiel den Vorteil der Nachbetrachtung. Da es keine finanziellen Risiken 
        beim hypothetischen Handel gibt, können diese Ergebnisse nicht die Auswirkungen finanzieller Risiken im realen Handel widerspiegeln. Die Fähigkeit, 
        Verluste zu ertragen oder an einem Handelsprogramm festzuhalten, kann die tatsächlichen Ergebnisse stark beeinflussen.
      </p>
      <p>
        Vergangene Ergebnisse von COT Trading Pro sind nicht repräsentativ für zukünftige Leistungen.
      </p>
      <p>
        Die monatlichen und jährlichen Ergebnisse sollten als hypothetisch angesehen werden. Tatsächlich repräsentieren diese Ergebnisse weder die Performance 
        des Methodikerstellers noch die der Nutzer. Es gibt keine Garantie, dass die Anwendung der beschriebenen Methoden zu den gleichen Ergebnissen führt.
      </p>
      <p>
        Es ist wichtig zu verstehen, dass es zu technischen Problemen wie Daten- oder Serverausfällen kommen kann. Diese können das Risiko erhöhen. Märkte 
        garantieren nicht immer exakte Ausführungen, und schnelle Marktbewegungen können zu Slippage führen.
      </p>
      <p>
        Futures-Transaktionen beinhalten die Verpflichtung, den Basiswert des Vertrags zu einem zukünftigen Zeitpunkt zu liefern oder zu empfangen oder die 
        Position in bar auszugleichen. Sie tragen ein hohes Risiko. Der Einsatz von Hebelwirkung kann sowohl zu erheblichen Gewinnen als auch zu erheblichen 
        Verlusten führen.
      </p>
      <p>
        CFTC Risikohinweis: Der Handel mit Futures, Forex und Optionen birgt erhebliche Risiken. Sie sollten diese Transaktionen nur durchführen, wenn Sie 
        die Natur der Verträge vollständig verstehen und die Risiken akzeptieren können. Es besteht die Möglichkeit eines vollständigen Verlustes des 
        eingesetzten Kapitals und eventueller Nachschusspflichten.
      </p>
      <p>
        Die folgende Erklärung wird gemäß der Verordnung 1.55(c) der Commodity Futures Trading Commission („CFTC“) bereitgestellt. Diese kurze Erklärung offenbart nicht alle Risiken und anderen wesentlichen Aspekte des Handels mit Futures, Forex und Optionen. Angesichts der Risiken sollten Sie solche Transaktionen nur durchführen, wenn Sie die Natur der Verträge (und vertraglichen Beziehungen), in die Sie eintreten, und das Ausmaß Ihres Risikos vollständig verstehen. Der Handel mit Futures, Forex und Optionen ist für viele Mitglieder der Öffentlichkeit nicht geeignet. Sie sollten sorgfältig abwägen, ob der Handel für Sie angesichts Ihrer Erfahrung, Ziele, finanziellen Mittel und anderer relevanter Umstände geeignet ist.
      </p>
      <p>
        Das Risiko von Verlusten beim Handel mit Warenterminkontrakten und Fremdwährungen kann erheblich sein. Sie sollten daher sorgfältig abwägen, ob ein solcher Handel angesichts Ihrer Umstände und finanziellen Mittel für Sie geeignet ist. Sie sollten sich der folgenden Punkte bewusst sein:
      </p>
      <ol>
        <li>Sie können einen vollständigen Verlust der Gelder erleiden, die Sie bei Ihrem Broker hinterlegen, um eine Position auf dem Warenterminmarkt oder dem Devisenmarkt zu eröffnen oder aufrechtzuerhalten, und Sie können Verluste über diese Beträge hinaus erleiden. Wenn sich der Markt gegen Ihre Position bewegt, kann Ihr Broker Sie auffordern, kurzfristig einen erheblichen zusätzlichen Marginbetrag zu hinterlegen, um Ihre Position aufrechtzuerhalten. Wenn Sie die erforderlichen Gelder nicht innerhalb der von Ihrem Broker festgelegten Zeit bereitstellen, kann Ihre Position mit Verlust liquidiert werden, und Sie sind für das daraus resultierende Defizit auf Ihrem Konto verantwortlich.</li>
        <li>Die Gelder, die Sie bei einem Futures-Kommissionshändler für den Handel mit Futures und Optionen auf Futures hinterlegen, sind nicht durch die Federal Deposit Insurance Corporation („FDIC“) versichert, oder im Falle von bei einem Broker-Dealer hinterlegten Geldern für den Handel mit Fremdwährungen durch die Securities Investor Protection Corporation („SIPC“) versichert.</li>
        <li>Die Gelder, die Sie bei Ihrem Broker hinterlegen, sind nicht vor Verlust geschützt.</li>
        <li>Sie können gezwungen sein, Positionen zu ungünstigen Zeiten und Preisen zu liquidieren oder zusätzliche Marginzahlungen zu leisten, um Marginanforderungen zu erfüllen.</li>
        <li>Es kann schwierig oder unmöglich sein, eine Position aufgrund bestimmter Marktbedingungen zu liquidieren. Das Platzieren von bedingten Aufträgen, wie z.B. "Stop-Loss" oder "Stop-Limit" Aufträgen, wird Ihre Verluste nicht unbedingt auf die beabsichtigten Beträge begrenzen, da Marktbedingungen die Ausführung solcher Aufträge unmöglich machen können.</li>
      </ol>
      <p>
        Unter bestimmten Marktbedingungen kann es schwierig oder unmöglich sein, eine Position zu liquidieren. Dies kann beispielsweise der Fall sein, wenn der Markt ein tägliches Preisschwankungslimit ("Limit Move") erreicht.
      </p>
      <p>
        Das Platzieren von bedingten Aufträgen durch Sie oder Ihren Handelsberater, wie z.B. einem "Stop-Loss" oder "Stop-Limit" Auftrag, wird Ihre Verluste nicht unbedingt auf die beabsichtigten Beträge begrenzen, da Marktbedingungen die Ausführung solcher Aufträge unmöglich machen können. Eine "Spread"-Position kann nicht weniger riskant sein als eine einfache "Long"- oder "Short"-Position. Der hohe Hebel, der im Futures- und Optionshandel aufgrund der geringen Marginanforderungen oft erreichbar ist, kann sowohl gegen Sie als auch für Sie arbeiten. Der Einsatz von Hebelwirkung kann zu großen Verlusten sowie Gewinnen führen. In einigen Fällen unterliegen verwaltete Rohstoffkonten erheblichen Gebühren für Management- und Beratungsdienste. Es kann erforderlich sein, dass diese Konten erhebliche Handelsgewinne erzielen müssen, um eine Erschöpfung oder Aufzehrung ihrer Vermögenswerte zu vermeiden. Sie sollten Ihre Kontoauszüge umgehend überprüfen, wenn Sie diese erhalten. Um Ihre Rechte und Pflichten im Falle eines Streits mit Ihrem Broker zu klären, sollten Sie alle von Ihrem Broker bereitgestellten Kontodokumentationen und -auszüge überprüfen.
      </p>
      <p>
        Hinweis: Der Handel mit Finanzinstrumenten birgt erhebliche Risiken und kann zum Verlust des gesamten investierten Kapitals führen. 
        Bitte stellen Sie sicher, dass Sie die spezifischen Vorschriften und Anforderungen Ihres Landes verstehen und einhalten. 
        In vielen Ländern gibt es spezifische Regulierungsbehörden, wie z.B. die European Securities and Markets Authority (ESMA) in der EU, 
        die Financial Conduct Authority (FCA) im Vereinigten Königreich, die Australian Securities and Investments Commission (ASIC) in Australien, 
        und die Canadian Securities Administrators (CSA) in Kanada. Informieren Sie sich über die entsprechenden Vorschriften und Anforderungen 
        Ihrer lokalen Regulierungsbehörde.
      </p>
    </div>
  );
}