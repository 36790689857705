import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';  // Achte darauf, die korrekte Datenbankinstanz zu importieren
import React from 'react';
import ManageSubscription from '../components/CancelSub';

// Definieren des benutzerdefinierten Hooks
function useSubscriptionStatus() {
    const [subscriptionStatus, setSubscriptionStatus] = useState('Loading...');
    const [subscriptionDetails, setSubscriptionDetails] = useState(null);
    const auth = getAuth();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const subscriptionsCollection = collection(db, 'customers', user.uid, 'subscriptions');
                const q = query(subscriptionsCollection, where('status', '==', 'active'));
                const snapshot = await getDocs(q);
                // Setzen Sie den Abonnementstatus basierend auf den abgerufenen Daten
                if (!snapshot.empty) {
                    const subscriptionData = snapshot.docs[0].data();
                    setSubscriptionStatus('Active');
                    setSubscriptionDetails(subscriptionData);
                } else {
                    setSubscriptionStatus('Inactive');
                    setSubscriptionDetails(null);
                }
            } else {
                setSubscriptionStatus('No user logged in');
                setSubscriptionDetails(null);
            }
        });

        return () => unsubscribe();
    }, [auth]);

    return { subscriptionStatus, subscriptionDetails };
}

// Definieren der Komponente, die den Hook verwendet
const SubscriptionStatus = () => {
  const { subscriptionStatus, subscriptionDetails } = useSubscriptionStatus();

  if (!subscriptionDetails) {
    return null; // Komponente nicht anzeigen, wenn keine Subscription vorliegt
  }

  return (
    <>
      <div className='or-line'><p>Manage Subscription</p></div>
      <ManageSubscription /> {/* Button und Spinner nur anzeigen, wenn eine Subscription vorhanden ist */}
      <div className="subscription-container">
        <p className="subscription-heading">
          <span className="subscription-label">Subscription Status: </span>
          <span className={`subscription-status ${subscriptionStatus.toLowerCase()}`}>{subscriptionStatus}</span>
        </p>
        <div className="subscription-details">
          {subscriptionDetails.cancel_at_period_end && (
            <p className="subscription-cancel-message">
              Your subscription is active until {new Date(subscriptionDetails.current_period_end.seconds * 1000).toLocaleString()}.
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default SubscriptionStatus;