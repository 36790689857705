const WasdeReport = {
    headers : [
        'Wasde Report',
    ], 

    titles : [
        'Wasde Report',
    ],

    media : [
        {type: 'image', src: '3CourseAdditionalMedien/Wasde.png', alt: 'Wasde Report'},
    ],

    texts : [
        {
            content: [
                {type: 'paragraph', text: 'The WASDE report (World Agricultural Supply and Demand Estimates) is a monthly report by the USDA that contains global supply and demand forecasts for agricultural products.'},
                {type: 'paragraph', text: 'This report is published monthly and includes estimates for global production, consumption, and stocks of agricultural products such as grains, oilseeds, and sugar.'},
                {type: 'paragraph', text: 'The WASDE report is an important indicator for agricultural markets and can have a significant impact on the prices of agricultural products.'},
                {type: 'paragraph', text: 'Traders and investors use the WASDE report to make their trading decisions and stay informed about current developments in the agricultural markets.'},
                {type: 'download', text: 'WASDE Report - USDA', href: 'https://www.usda.gov/oce/commodity/wasde/'},
                {type: 'paragraph', text: '!!! Tip: Download the WASDE report and upload it to ChatGPT to get an analysis !!!'}
            ]
        },
    ]
};

export default WasdeReport;