export default function Disclaimer() {
    return (
      <>
        <h2>Disclaimer</h2>
        <p>
            The information provided is for general informational purposes only and does not constitute legal, financial, or other professional advice. 
            While we strive to provide accurate and up-to-date information, we make no warranties or guarantees about the completeness, accuracy, or timeliness of the content. 
            Any liability for damages arising from the use of the information provided is excluded.
        </p>
        <p>
            Despite careful content control, we assume no liability for the content of external links. 
            The operators of the linked pages are solely responsible for their content.
        </p>
        <p>
            The content of this website is protected by copyright. 
            The information provided is intended for personal use only and may not be reproduced, distributed, transmitted, displayed, published, or broadcast without the prior written permission of COT Season.
        </p>
        <p>
            This disclaimer is governed by the laws of [Your Country]. Any disputes arising from the use of this website shall be subject to the exclusive jurisdiction of the courts of [Your Country].
        </p>
      </>
    );
}