const Broker = {
    headers : [
        'Broker',
    ], 

    titles : [
        'Broker Selection',
    ],

    media : [
        {type: 'image', src: '3CourseAdditionalMedien/broker.png', alt: 'Handshake', caption: '@ pixabay.com'},
    ],

    texts : [
        {
            content: [
                {type: 'paragraph', text: 'Direct Market Access vs. Market Maker:'},
                {type: 'paragraph', text: 'Direct Market Access: A broker that is directly connected to the exchange sends your orders directly to the market. This usually results in faster execution and transparency, as your orders reach the real market and are not processed by an intermediary. This is important for trading futures and other financial instruments. Since we plan and place our trades tick-accurately and each tick can cost a lot of money, it is important to have a broker that does not manipulate prices.'},
                {type: 'paragraph', text: 'Market Maker: Market makers are brokers who set their own prices and can act as the counterparty to your trades. This can lead to conflicts of interest, as market makers can profit from your losses. When trading large or volatile positions, it can be more difficult to obtain tight spreads and fair prices, as market makers can set prices at their discretion.'},
                {type: 'paragraph', text: 'Real Futures vs. CFDs (Spread):'},
                {type: 'paragraph', text: 'Real Futures: Trading real futures contracts involves direct participation in the underlying market. Futures have standardized contracts and are traded on organized exchanges. They offer true price discovery and a wealth of information. The downside is that futures contracts often have higher capital requirements.'},
                {type: 'paragraph', text: 'CFDs (Contracts for Difference): CFDs are derivatives that allow you to speculate on price movements of assets without owning the underlying asset. CFDs require less capital and can be used for leveraged trading. The downside is that they do not offer the same level of transparency and market access as real futures.'},
                {type: 'paragraph', text: 'ECN (Electronic Communication Network) CFD Broker:'},
                {type: 'paragraph', text: 'ECN CFD brokers are brokers that use a trading infrastructure that allows orders to be forwarded directly to other market participants. They typically offer faster execution and tight spreads, as they receive prices from liquidity providers (e.g., banks or institutional traders). ECN brokers tend to be more transparent and minimize conflicts of interest, as they are not the counterparty to your trades.'},
                {type: 'paragraph', text: 'In summary, choosing a broker is crucial and depends on your specific trading goals, risk profile, and strategy. It is important to research and ensure that the chosen broker meets your needs and preferences, whether it is direct market access, real futures, or an ECN CFD broker. Consider costs, security, and regulatory aspects when selecting a broker.'},
                {type: 'download', text: 'My personal Favorite: Interactive Brokers', href: 'https://www.interactivebrokers.com/en/home.php'},
            ]
        }
    ]
};

export default Broker;