import React, { useState, useEffect } from 'react';
import '../css/standard.css';
import '../css/formular.css';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, createUserWithEmailAndPassword, updateProfile, sendEmailVerification } from 'firebase/auth';
import OAuth from '../components/OAuth';
import { setDoc, doc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebaseConfig'; 
import { toast } from 'react-toastify';
import TermsConditions from './TermsConditions';
import RiskDisclosure from './RiskDisclosure';
import { useAuthStatus } from '../hooks/useAuthStatus';
import { usePaymentStatus } from '../hooks/usePaymentStatus';
import Footer from '../landingComponents/Footer';

export default function SignUp() {
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    tradingviewName: '',
    email: '',
    password: '',
    confirmPassword: '',
    agb: false,
    riskDisclosure: false
  });
  const [overlayContent, setOverlayContent] = useState('');
  const { firstName, lastName, tradingviewName, email, password, confirmPassword, agb, riskDisclosure } = formData;
  const navigate = useNavigate();

  const { loggedIn } = useAuthStatus();
  const paymentStatus = usePaymentStatus();

  // useEffect, um auf Änderungen von paymentStatus zu reagieren
  useEffect(() => {
    if (paymentStatus !== 'succeeded' && loggedIn) {
      navigate('/checkout');
    } else if (!loggedIn) {
      navigate('/sign-up');
    }
  }, [paymentStatus, loggedIn, navigate]); 

  const onChange = (e) => {
    const { id, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: type === 'checkbox' ? checked : value
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
  
    if (password !== confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }
  
    if (!agb || !riskDisclosure) {
      toast.error('Please accept the terms and conditions and risk disclosure');
      return;
    }
  
    try {
      const auth = getAuth();
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
  
      await updateProfile(auth.currentUser, {
        displayName: `${firstName} ${lastName}`
      });
  
      const user = userCredential.user;
  
      const formDataCopy = { ...formData };
      delete formDataCopy.password;
      delete formDataCopy.confirmPassword;
      formDataCopy.timestamp = serverTimestamp();
  
      await setDoc(doc(db, 'users', user.uid), formDataCopy);
  
      // Senden der Bestätigungs-E-Mail
      await sendEmailVerification(auth.currentUser);
  
      toast.success('Registration successful! Please check your email to verify your account.');
      navigate('/checkout');
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        toast.error('Email address is already in use.');
      } else if (error.code === 'auth/invalid-email') {
        toast.error('Invalid email address format.');
      } else if (error.code === 'auth/weak-password') {
        toast.error('Password should be at least 6 characters long.');
      } else {
        toast.error('Something went wrong with your registration. Please try again.');
      }
    }
  };

  const showOverlay = (content) => {
    setOverlayContent(content);
    document.getElementById('signup-overlay').style.display = 'flex';
  };

  const closeOverlay = () => {
    document.getElementById('signup-overlay').style.display = 'none';
  };

  useEffect(() => {
    const closeButton = document.getElementById('close-button-signup');
    closeButton.addEventListener('click', closeOverlay);

    return () => {
      closeButton.removeEventListener('click', closeOverlay);
    };
  }, []);


  return (
    <>
      <section className="SignIn-Container" id='profil-content' >
        <h1 className="si-header">Sign Up</h1>
        <div className="si-sub-container">
          <div className="si-form-container">
            <form className="login-form" onSubmit={onSubmit}>
              <div className="form-row">
                <input
                  placeholder='First Name'
                  type="text"
                  id="firstName"
                  value={firstName}
                  onChange={onChange}
                  pattern="[A-Za-z]{3,12}"
                  title="Only letters"
                  maxLength="25"
                  required
                />
              </div>
              <div className="form-row">
                <input
                  placeholder='Last Name' 
                  type="text"
                  id="lastName"
                  value={lastName}
                  onChange={onChange}
                  pattern="[A-Za-z]{3,12}"
                  title="Only letters"
                  maxLength="25"
                  required
                />
              </div>
              <div className="form-row">
                <input
                  placeholder='Tradingview Username (optional)'
                  type="text"
                  id="tradingviewName"
                  value={tradingviewName}
                  onChange={onChange}
                  pattern="^[A-Za-z0-9]{3,25}$"
                  title="Only letters and numbers, no spaces, and between 3 to 25 characters"
                  maxLength="25"
                />
              </div>
              <div className="form-row">
                <input
                  placeholder='Email Address'
                  type="email"
                  id="email"
                  value={email}
                  onChange={onChange}
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                  maxLength="35"
                  required
                />
              </div>
              <div className="form-row">
                <div className="form-password">
                  <input
                    placeholder='Password'
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    value={password}
                    onChange={onChange}
                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}"
                    title="Password must be at least 8 characters long and contain at least one digit, one uppercase letter, one lowercase letter, and one special character."
                    required
                  />
                  {showPassword ? (
                    <AiFillEyeInvisible
                      className="eye-icon"
                      onClick={() => setShowPassword((prevState) => !prevState)}
                    />
                  ) : (
                    <AiFillEye
                      className="eye-icon"
                      onClick={() => setShowPassword((prevState) => !prevState)}
                    />
                  )}
                </div>
              </div>
              <div className="form-row">
                <input
                  placeholder='Confirm Password'
                  type="password"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={onChange}
                  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}"
                  title="Password must be at least 8 characters long and contain at least one digit, one uppercase letter, one lowercase letter, and one special character."
                  required
                />
              </div>
              <div className="form-extra">
                <div className="form-row-diff">
                  <label>
                    I have read and accept the <span className="clickable-text" onClick={() => showOverlay('terms')}>terms and conditions</span>:
                  </label>
                  <input
                    type="checkbox"
                    id="agb"
                    checked={agb}
                    onChange={onChange}
                    required
                  />
                </div>
                <div className="form-row-diff">
                  <label>
                    I have read and accept the <span className="clickable-text" onClick={() => showOverlay('risk')}>risk disclosure</span>:
                  </label>
                  <input
                    type="checkbox"
                    id="riskDisclosure"
                    checked={riskDisclosure}
                    onChange={onChange}
                    required
                  />
                </div>
              </div>
              <input type="submit" id="buy-button" value="Sign Up"/>
              <OAuth />
              <div className="si-forgot">
                <p>You already have an account?
                  <Link to="/sign-in">Sign In</Link>
                </p>
              </div>
            </form>
          </div>
        </div>
        <div id="signup-overlay" className="overlay">
          <div className="overlay-content">
            <button id="close-button-signup" className="close-button">&times;</button>
            {overlayContent === 'terms' && (
              <div>
                <TermsConditions />
              </div>
            )}
            {overlayContent === 'risk' && (
              <div>
                <RiskDisclosure />
              </div>
            )}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}