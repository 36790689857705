export default function Impressum() {
  return (
    <div style={{ marginTop: "120px"}}>
      <p>Samuel Pietrowski</p>
      <p>COTSeason.com</p>
      <p>Mendelssohnstraße 11</p>
      <p>49762 Lathen</p>
      <p>Deutschland</p>

      <p>Tel.: 01717307259</p>
      <p>E-Mail: salamontraderinfo@gmail.com</p>

      <p>USt. wird nicht ausgewiesen (Kleinunternehmerregelung)</p>

      <p>Zuständige Aufsichtsbehörde für das Angebot audiovisueller Mediendienste:</p>
      <p>Niedersächsische, Landesmedienanstalt (NLM), Seelhorststraße 18, 30175 Hannover</p>

      <p>Plattform der EU-Kommission zur Online-Streitbeilegung: <a href="https://ec.europa.eu/odr">https://ec.europa.eu/odr</a></p>

      <p>Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle weder verpflichtet noch bereit.</p>

      <h3>Copyright und Haftungsausschluss</h3>
      <p>Alle auf dieser Website veröffentlichten Inhalte, einschließlich Texte, Bilder, Videos, Analysetools mit statistischer Auswertung und sämtliche interaktiven Elemente, unterliegen dem Urheberrecht und anderen Gesetzen zum Schutz geistigen Eigentums. Eine unerlaubte Vervielfältigung, Weitergabe an Dritte, Veröffentlichung auf anderen Websites oder sonstige Verwendung ist ohne unsere vorherige schriftliche Genehmigung strikt untersagt.</p>
      <p>Namentlich gekennzeichnete Beiträge spiegeln ausschließlich die Meinung des jeweiligen Autors/der jeweiligen Autoren wider und nicht die Meinung von COTSeason. Das Urheberrecht für veröffentlichte und von den Autoren selbst erstellte Inhalte verbleibt allein beim Autor. Eine Vervielfältigung oder Verwendung von Grafiken, Texten, Videomaterial und anderen Inhalten in elektronischen oder gedruckten Publikationen ist ohne ausdrückliche Zustimmung des Autors nicht gestattet.</p>

      <h3>Maßnahmen bei Verstößen</h3>
      <p>Bei Verstößen behalten wir uns das Recht vor, das Abonnement ohne Rückerstattung des Abonnement-Betrags sofort zu beenden, das Kundenkonto zu löschen und gegebenenfalls rechtliche Schritte einzuleiten.</p>
      <p>Sollten Inhalte oder grafische Elemente auf dieser Website die Rechte Dritter oder gesetzliche Bestimmungen verletzen oder wettbewerbsrechtliche Probleme verursachen, bitten wir um eine entsprechende Benachrichtigung gemäß § 8 Abs. 4 UWG, ohne Kostennote. Wir sichern zu, dass zu Recht beanstandete Passagen zeitnah entfernt oder den rechtlichen Anforderungen entsprechend angepasst werden, ohne dass die Einschaltung eines Rechtsanwalts erforderlich ist.</p>
      <p>Die Beauftragung eines Rechtsbeistands zur kostenpflichtigen Abmahnung ohne vorherige Kontaktaufnahme würde gegen § 13 Abs. 5 UWG verstoßen, da dies die Verfolgung sachfremder Ziele, insbesondere eine Kostenerzielungsabsicht, darstellen könnte und damit gegen die Schadensminderungspflicht.</p>

      <hr/>

      <p>Samuel Pietrowski</p>
      <p>COTSeason.com</p>
      <p>Mendelssohnstraße 11</p>
      <p>49762 Lathen</p>
      <p>Germany</p>

      <p>Phone.: +49 171 7307259</p>
      <p>e-mail: salamontraderinfo@gmail.com</p>

      <p>VAT is not shown (small business regulation)</p>

      <p>Competent supervisory authority for the provision of audiovisual media services:</p>
      <p>Lower Saxony, State Media Authority (NLM), Seelhorststraße 18, 30175 Hanover</p>

      <p>Platform of the EU Commission for online dispute resolution: <a href="https://ec.europa.eu/odr">https://ec.europa.eu/odr</a></p>

      <p>We are neither obliged nor willing to participate in dispute resolution proceedings before a consumer arbitration board.</p>

      <h3>Copyright and disclaimer</h3>
      <p>All content published on this website, including texts, images, videos, analysis tools with statistical evaluation and all interactive elements, are subject to copyright and other laws for the protection of intellectual property. Unauthorized reproduction, disclosure to third parties, publication on other websites or other use is strictly prohibited without our prior written consent.</p>
      <p>Contributions identified by name reflect exclusively the opinion of the respective author(s) and not the opinion of COTSeason. The copyright for published content created by the authors themselves remains solely with the author. Reproduction or use of graphics, texts, video material and other content in electronic or printed publications is not permitted without the express consent of the author.</p>

      <h3>Measures in the event of infringements</h3>
      <p>In the event of violations, we reserve the right to terminate the subscription immediately without refunding the subscription amount, to delete the customer account and, if necessary, to take legal action.</p>
      <p>Should content or graphic elements on this website infringe the rights of third parties or statutory provisions or cause problems under competition law, we request that you notify us accordingly in accordance with Section 8 (4) of the German Act Against Unfair Competition (UWG), without charge. We guarantee that passages that are rightly objected to will be promptly removed or adapted in accordance with legal requirements without the need to involve a lawyer.</p>
      <p>Engaging legal counsel to issue a warning without prior contact would violate Section 13 (5) UWG, as this could constitute the pursuit of extraneous objectives, in particular an intention to generate costs, and thus violate the duty to minimize damages.</p>
    </div>
  );
}