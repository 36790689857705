// App.js
import React from 'react';
import ImageSwiper from '../js/ImageSwiper';
import '../css/standard.css';

const Indicator = () => {
  return (
    <div >
      <div className='indicator-container'>
        <ImageSwiper />
      </div>
      <div className="indicator-text-box" id='courseTarget'>
            <h1>Master the Market with Powerful Indicators and Tools</h1>
            <p>Technical indicators are the cornerstone of a successful trading strategy. They provide you with valuable insights into price movements and help you identify trends early to make profitable decisions.</p>
            <p><strong>Exclusive for our course participants:</strong> When you purchase our course, you get free access to a comprehensive collection of proven indicators. These are directly integrated into the lessons, so you can learn exactly how to use them effectively.</p>
            <p><strong>Developed by experts, optimized for you:</strong> In the first part of the course, you will work with indicators based on the theoretical concepts and calculations of trading legend Larry Williams. In the advanced section, we take it a step further: You will use enhanced indicators that integrate Larry Williams' theories and calculations, exclusive calculations from Lars von Thienen's book <em>'Decoding The Hidden Market Rhythm - Third Edition'</em>, and our own programming techniques to elevate their performance to a new level.</p>

            <p>Take the opportunity to elevate your trading to the next level – with indicators inspired by the best in the industry and optimized for your needs.</p>
      </div>
    </div>
  );
};

export default Indicator;