import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { Line, Bubble } from 'react-chartjs-2';
import Footer from '../landingComponents/Footer';
import 'chart.js/auto';
import '../css/COTDataFetcher.css'; // Importiere die CSS-Datei für das Styling

const COTData = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [weeks, setWeeks] = useState(1);
    const [contractMarketNames, setContractMarketNames] = useState([]);
    const [selectedContract, setSelectedContract] = useState('GOLD');
    const [selectedColumns, setSelectedColumns] = useState([
        'pct_of_oi_comm_long_all', 'pct_of_oi_comm_short_all', 'pct_of_oi_comm_net_all',
    ]);
    const [selectedColumnsSecondChart, setSelectedColumnsSecondChart] = useState([
        'comm_positions_net'
    ]);


    const allColumns = [
        'open_interest_all',
        'noncomm_positions_long_all', 'noncomm_positions_short_all', 'noncomm_positions_net',        
        'change_in_noncomm_long_all', 'change_in_noncomm_short_all', 'change_in_noncomm_net',        
        'pct_of_oi_noncomm_long_all', 'pct_of_oi_noncomm_short_all', 'pct_of_oi_noncomm_net_all', 

        'comm_positions_long_all', 'comm_positions_short_all', 'comm_positions_net',         
        'change_in_comm_long_all', 'change_in_comm_short_all', 'change_in_comm_net',        
        'pct_of_oi_comm_long_all', 'pct_of_oi_comm_short_all', 'pct_of_oi_comm_net_all', 

        'nonrept_positions_long_all', 'nonrept_positions_short_all', 'nonrept_positions_net',        
        'change_in_nonrept_long_all', 'change_in_nonrept_short_all', 'change_in_nonrept_net',        
        'pct_of_oi_nonrept_long_all', 'pct_of_oi_nonrept_short_all', 'pct_of_oi_nonrept_net_all', 
    ];

    const formatDate = (date) => {
        const d = new Date(date);
        return d.toISOString().split('T')[0];
    };

    const formatDisplayDate = (date) => {
        const d = new Date(date);
        return d.toLocaleDateString('en-US'); // Formatieren Sie das Datum im deutschen Format
    };

    const getWeeksAgoDate = useCallback(() => {
        const today = new Date();
        const weeksAgo = new Date(today.setDate(today.getDate() - weeks * 52 * 7));
        return formatDate(weeksAgo);
    }, [weeks]);

    const calculateNetPositions = (data) => {
        return data.map(item => ({
            ...item,
            noncomm_positions_net: item.noncomm_positions_long_all - item.noncomm_positions_short_all,
            comm_positions_net: item.comm_positions_long_all - item.comm_positions_short_all,
            nonrept_positions_net: item.nonrept_positions_long_all - item.nonrept_positions_short_all,
            change_in_noncomm_net: item.change_in_noncomm_long_all - item.change_in_noncomm_short_all,
            change_in_comm_net: item.change_in_comm_long_all - item.change_in_comm_short_all,
            change_in_nonrept_net: item.change_in_nonrept_long_all - item.change_in_nonrept_short_all,
            pct_of_oi_noncomm_net_all: item.pct_of_oi_noncomm_long_all - item.pct_of_oi_noncomm_short_all,
            pct_of_oi_comm_net_all: item.pct_of_oi_comm_long_all - item.pct_of_oi_comm_short_all,
            pct_of_oi_nonrept_net_all: item.pct_of_oi_nonrept_long_all - item.pct_of_oi_nonrept_short_all
        }));
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                
                const today = formatDate(new Date());
                const weeksAgo = getWeeksAgoDate();

                // Liste der gewünschten Märkte
                const desiredMarkets = [
                    // Metalle
                    'GOLD', 'SILVER', 'COPPER- #1', 'PALLADIUM', 'PLATINUM', 'ALUMINUM',
                
                    // Agrarprodukte
                    'CORN', 'SOYBEAN MEAL', 'COTTON NO. 2', 'ROUGH RICE', 'FRZN CONCENTRAATED ORANGE JUICE',
                    'MILK, Class III', 'LEAN HOGS', 'LIVE CATTLE', 'LUMBER', 'FEEDER CATTLE', 'LUMGER',
                    'COCOA', 'SUGAR NO.11', 'COFFEE C', 'OATS', 'WHEAT-HRW', 'WHEAT-SRW', 'WHEAT-HRSpring', 'SOYBEANS', 'SOYBEAN OIL',

                    // ANIMALS
                    'LUMBER', 'LIVE CATTLE', 'FEEDER CATTLE', 'LEAN HOGS',
                
                    // Energie
                    'CRUDE OIL, LIGHT SWEET-WTI', 'GASOLINE RBOB', 'WTI FINANCIAL CRUDE OIL', 'E-MINI NATURAL GAS', 'HEATING OIL', 
                    'NY HARBOR ULSD', 'NAT GAS NYME', 'E-MINI NATURAL GAS',
                
                    // Währungen
                    'CANADIAN DOLLAR', 'SWISS FRANC', 'MEXICAN PESO', 'BRITISH POUND', 'JAPANESE YEN', 'EURO FX', 'NZ DOLLAR', 'AUSTRALIAN DOLLAR',
                    'USD INDEX', 'EURODOLLAR',
                
                    // Indizes
                    'VIX FUTURES', 'MICRO E-MINI S&P 500 INDEX', 'E-MINI S&P 500', 'NASDAQ MINI', 'RUSSELL E-MINI', 'MICRO E-MINI RUSSELL 2000 INDX', 
                    'MICRO E-MINI DJIA (X$0.5)', 'E-MINI DOW JONES ($5)', 'ULTRA UST BOND', 'UST BOND', 'UST 10Y NOTE', 'UST 5Y NOTE', 'UST 2Y NOTE', 
                    'ULTRA UST 10Y', 'FED FUNDS',
                
                    // Kryptowährungen
                    'MICRO BITCOIN', 'BITCOIN', 'MICRO ETHER',
                
                    // Sonstiges
                    'MICRO 100 YEAR YIELD'
                ];

                // Erstellen Sie eine Bedingung für die gewünschten Märkte
                const marketCondition = desiredMarkets.map(market => `contract_market_name='${market}'`).join(' OR ');

                const response = await axios.get('https://publicreporting.cftc.gov/resource/6dca-aqww.json', {
                    params: {
                        $limit: 25000,
                        $$app_token: process.env.REACT_APP_COTDATA,
                        $where: `report_date_as_yyyy_mm_dd between '${weeksAgo}' and '${today}' AND futonly_or_combined='FutOnly' AND (${marketCondition})`,
                        $order: 'report_date_as_yyyy_mm_dd ASC' // Sortiere die Daten nach Datum aufsteigend
                    }
                });
                const fetchedData = response.data;
                console.log(fetchedData);
                const processedData = calculateNetPositions(fetchedData);
                setData(processedData);
    
                const names = [...new Set(fetchedData.map(item => item.contract_market_name))];
                setContractMarketNames(names);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };
    
        fetchData();
    }, [getWeeksAgoDate]);

    const handleSelectChange = (event) => {
        setSelectedContract(event.target.value);
    };

    const handleColumnChange = (event) => {
        const value = Array.from(event.target.selectedOptions, option => option.value);
        setSelectedColumns(value);
    };

    const filteredData = data
        .filter(item => !selectedContract || item.contract_market_name === selectedContract)
        .sort((a, b) => new Date(b.report_date_as_yyyy_mm_dd) - new Date(a.report_date_as_yyyy_mm_dd)); // Sortiere die Daten nach Datum absteigend für die Tabelle

    const isCalculatedColumn = (col) => {
        return [
            'noncomm_positions_net', 'comm_positions_net', 'nonrept_positions_net',
            'change_in_noncomm_net', 'change_in_comm_net', 'change_in_nonrept_net',
            'pct_of_oi_noncomm_net_all', 'pct_of_oi_comm_net_all', 'pct_of_oi_nonrept_net_all'
        ].includes(col);
    };

    const getColorForColumn = (col) => {
        if (col.includes('short')) return '#8a1a1a'; // Rot für Short-Daten
        if (col.includes('long')) return 'rgb(27, 111, 27)'; // Grün für Long-Daten
        if (col.includes('net')) return '#095F92'; // Blau für Net-Daten
        return 'rgba(75, 192, 192, 1)'; // Standardfarbe
    };

    const createChartData = () => {
        const chartData = [...filteredData].reverse(); // Daten umkehren, damit das älteste Datum als erstes steht
        return {
            labels: chartData.map(item => formatDisplayDate(item.report_date_as_yyyy_mm_dd)),
            datasets: selectedColumns.map(col => ({
                label: col.replace(/_/g, ' ').toUpperCase(),
                data: chartData.map(item => item[col]),
                borderColor: getColorForColumn(col),
                backgroundColor: getColorForColumn(col).replace('1)', '0.2)'),
                fill: false,
            }))
        };
    };

    const createBubbleChartData = () => {
        const chartData = [...filteredData].reverse(); // Daten umkehren, damit das älteste Datum als erstes steht
    
        // Berechnung der maximalen Werte für die Skalierung
        const maxChangeInNonCommNet = Math.max(...chartData.map(item => Math.abs(item.change_in_noncomm_net)));
        const maxChangeInCommNet = Math.max(...chartData.map(item => Math.abs(item.change_in_comm_net)));
        const maxChangeInNonReptNet = Math.max(...chartData.map(item => Math.abs(item.change_in_nonrept_net)));
    
        // Dynamische Skalierungsfaktoren
        const scaleFactorNonCommNet = maxChangeInNonCommNet / 20; // Beispiel: Skalierung auf maximalen Radius von 20
        const scaleFactorCommNet = maxChangeInCommNet / 20;
        const scaleFactorNonReptNet = maxChangeInNonReptNet / 20;
    
        return {
            labels: chartData.map(item => formatDisplayDate(item.report_date_as_yyyy_mm_dd)),
            datasets: [
                {
                    label: 'Change in Non Comm Net',
                    data: chartData.map(item => ({
                        x: new Date(item.report_date_as_yyyy_mm_dd), // X-Achse: Datum
                        y: item.change_in_noncomm_net, // Y-Achse: "change_in_noncomm_net"
                        r: Math.abs(item.change_in_noncomm_net) / scaleFactorNonCommNet, // Dynamischer Radius
                    })),
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1,
                },
                {
                    label: 'Change in Comm Net',
                    data: chartData.map(item => ({
                        x: new Date(item.report_date_as_yyyy_mm_dd),
                        y: item.change_in_comm_net,
                        r: Math.abs(item.change_in_comm_net) / scaleFactorCommNet, // Dynamischer Radius
                    })),
                    backgroundColor: 'rgba(255, 99, 132, 0.2)',
                    borderColor: 'rgba(255, 99, 132, 1)',
                    borderWidth: 1,
                },
                {
                    label: 'Change in non Rept Net',
                    data: chartData.map(item => ({
                        x: new Date(item.report_date_as_yyyy_mm_dd),
                        y: item.change_in_nonrept_net,
                        r: Math.abs(item.change_in_nonrept_net) / scaleFactorNonReptNet, // Dynamischer Radius
                    })),
                    backgroundColor: 'rgba(54, 162, 235, 0.2)',
                    borderColor: 'rgba(54, 162, 235, 1)',
                    borderWidth: 1,
                }
            ]
        };
    };

    


    
    ///////// Seasonal and yearly comparison 
    const groupDataByYearAndWeek = (data) => {
        const groupedData = {};
    
        data.forEach(item => {
            const date = new Date(item.report_date_as_yyyy_mm_dd);
            const year = date.getFullYear();
            const week = getWeekNumber(date);
    
            if (!groupedData[year]) {
                groupedData[year] = {};
            }
            if (!groupedData[year][week]) {
                groupedData[year][week] = [];
            }
            groupedData[year][week].push(item);
        });
    
        return groupedData;
    };
    
    // Helper function to get the week number of a date
    const getWeekNumber = (date) => {
        const startOfYear = new Date(date.getFullYear(), 0, 1);
        const pastDaysOfYear = (date - startOfYear) / 86400000;
        return Math.ceil((pastDaysOfYear + startOfYear.getDay() + 1) / 7);
    };

    const handleColumnChangeSecondChart = (event) => {
        const selectedValue = event.target.value;
        setSelectedColumnsSecondChart([selectedValue]);
    };
    

    const createLineChartData = () => {
        const chartData = [...filteredData].reverse(); // Daten umkehren, damit das älteste Datum als erstes steht
        const groupedData = groupDataByYearAndWeek(chartData);
        const weeks = Array.from({ length: 52 }, (_, i) => i + 1); // Wochen 1 bis 52
        
        const colors = [
            'rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)', 
            'rgba(75, 192, 192, 0.2)', 'rgba(153, 102, 255, 0.2)', 'rgba(255, 159, 64, 0.2)'
        ];
        
        const borderColors = [
            'rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)', 
            'rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)', 'rgba(255, 159, 64, 1)'
        ];
        
        const currentYear = new Date().getFullYear();
        const currentWeek = getWeekNumber(new Date());
        
        // Berechnen der saisonalen Linie
        const seasonalData = weeks.map(week => {
            const values = Object.keys(groupedData).filter(year => {
                const yearInt = parseInt(year);
                return yearInt !== currentYear && Object.keys(groupedData[year]).length === 52;
            }).map(year => {
                const weekData = groupedData[year][week];
                return weekData ? weekData[0][selectedColumnsSecondChart[0]] : null;
            }).filter(value => value !== null);
        
            const average = values.reduce((sum, value) => sum + value, 0) / values.length;
            return average;
        });
        
        const datasets = Object.keys(groupedData).filter(year => {
            const yearInt = parseInt(year);
            if (yearInt === currentYear) {
                return true; // Aktuelles Jahr immer anzeigen
            }
            return Object.keys(groupedData[year]).length === 52; // Nur vollständige Jahre anzeigen
        }).map((year, index) => ({
            label: `Year ${year}`,
            data: weeks.map(week => {
                if (parseInt(year) === currentYear && week > currentWeek) {
                    return null; // Keine Daten für zukünftige Wochen im aktuellen Jahr
                }
                const weekData = groupedData[year][week];
                return weekData ? weekData[0][selectedColumnsSecondChart[0]] : null; // Beispiel: Verwenden Sie die erste ausgewählte Spalte
            }).filter(value => value !== null), // Null-Werte entfernen
            backgroundColor: colors[index % colors.length],
            borderColor: borderColors[index % borderColors.length],
            borderWidth: parseInt(year) === currentYear ? 3 : 1, // BorderWidth für das aktuelle Jahr auf 3 setzen
            fill: false,
            hidden: parseInt(year) !== currentYear // Standardmäßig alle Jahre außer dem aktuellen Jahr ausblenden
        }));
        
        // Hinzufügen der saisonalen Linie
        datasets.push({
            label: 'Seasonal Average',
            data: seasonalData,
            backgroundColor: 'rgba(255, 255, 255, 0.2)', // Weiß als Hintergrundfarbe
            borderColor: 'rgba(255, 255, 255, 1)', // Weiß als Randfarbe
            borderWidth: 3,
            fill: false,
        });
        
        const data = {
            labels: weeks,
            datasets: datasets
        };
        
        const options = {
            plugins: {
                filler: {
                    propagate: false
                },
                'samples-filler-analyser': {
                    target: 'chart-analyser'
                }
            },
            interaction: {
                intersect: false
            },
            scales: {
                x: {
                    type: 'category',
                    labels: weeks
                },
                y: {
                    beginAtZero: true
                }
            }
        };
        
        return { data, options };
    };


    return (
        <div className="table-container">
            {loading && (
                <div className="loading-overlay">
                    <div className="loading-spinner"></div>
                </div>
            )}
            {error && <div className="error-message">Error: {error.message}</div>}
            <h1>Commitment of Traders Data: Futures Only</h1>
            <div className="dropdown-wrapper">
                <div className="dropdown-container">
                    <label htmlFor="contract-dropdown">Select Contract Market Name:</label>
                    <select id="contract-dropdown" value={selectedContract} onChange={handleSelectChange}>
                        <option value="">All</option>
                        {contractMarketNames.map((name, index) => (
                            <option key={index} value={name}>
                                {name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="dropdown-container">
                    <label htmlFor="weeks-input">Number of Years:</label>
                    <input
                        id="weeks-input"
                        type="number"
                        value={weeks}
                        onChange={(e) => setWeeks(e.target.value)}
                        min="1"
                        max="10"
                    />
                </div>
                <div className="dropdown-container">
                    <label htmlFor="column-dropdown">Select Columns for Table & First Chart:</label>
                    <select id="column-dropdown" multiple value={selectedColumns} onChange={handleColumnChange}>
                        {allColumns.map((col, index) => (
                            <option key={index} value={col}>
                                {col.replace(/_/g, ' ').toUpperCase()}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="dropdown-container">
                    <label htmlFor="column-dropdown-second">Select Column for Second Chart:</label>
                    <select 
                        id="column-dropdown-second" 
                        value={selectedColumnsSecondChart[0] || ''} 
                        onChange={handleColumnChangeSecondChart}
                        className="second-dropdown"
                    >
                        <option value="">Select a column</option>
                        {allColumns.map((col, index) => (
                            <option key={index} value={col}>
                                {col.replace(/_/g, ' ').toUpperCase()}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="scrollable-table">
                <table>
                    <thead>
                        <tr>
                            <th>Report Date</th>
                            {selectedColumns.map(col => (
                                <th key={col}>{col.replace(/_/g, ' ').toUpperCase()}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData.map(item => (
                            <tr key={item.id}>
                                <td>{formatDisplayDate(item.report_date_as_yyyy_mm_dd)}</td>
                                {selectedColumns.map(col => (
                                    <td key={col} className={isCalculatedColumn(col) ? (item[col] > 0 ? 'positive' : 'negative') : ''}>
                                        {typeof item[col] === 'number' ? item[col].toFixed(2) : item[col]}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {selectedContract && selectedColumns.length > 0 && (
                <div className="charts-container-cot">
                    <div className="chart-cot">
                        <h2>Selected Data Chart</h2>
                        <Line data={createChartData()} />
                    </div>
                    <div className="chart-cot">
                        <h2>Bubble Chart</h2>
                        <Bubble data={createBubbleChartData()}  />
                    </div>
                    <div className="chart-cot">
                        <h2>{`Chart by Year for ${selectedColumnsSecondChart[0]?.replace(/_/g, ' ') || 'Selected Column'}`}</h2>
                        <Line data={createLineChartData().data} options={createLineChartData().options} />
                    </div>
                </div>
            )}
            <Footer />
        </div>
    );
}

export default COTData;