import { getAuth } from 'firebase/auth';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';  // Stelle sicher, dass du die richtige Firebase-Datenbank-Instanz importierst

export function usePaymentStatus() {
    const [paymentStatus, setPaymentStatus] = useState('Loading...');
    const auth = getAuth();
    const priceIdToCheck = 'price_1PpUgrL3qVLRkkN4Vjzgavkl';  // Die Preis-ID, die überprüft werden soll

    console.log(paymentStatus);

    useEffect(() => {
        const currentUser = auth.currentUser;

        if (currentUser) {
            const q = query(
                collection(db, 'customers', currentUser.uid, 'payments'),
                where('status', '==', 'succeeded')
            );

            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                if (!querySnapshot.empty) {
                    let found = false;

                    querySnapshot.forEach((doc) => {
                        const payment = doc.data();
                        if (payment && payment.items) {
                            payment.items.forEach((item) => {
                                if (item.price && item.price.id === priceIdToCheck) {
                                    found = true;
                                }
                            });
                        }
                    });

                    if (found) {
                        setPaymentStatus('succeeded');
                    } else {
                        setPaymentStatus('Please purchase the course to gain further access.');
                    }
                } else {
                    setPaymentStatus('No payments found.');
                }
            });

            return () => unsubscribe();  // Cleanup the payment status
        } else {
            setPaymentStatus('Not logged in.');
        }
    }, [auth.currentUser]);

    return paymentStatus;
}