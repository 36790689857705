
const CotExtreme = {
    headers : [
        'COT Extreme Analyse',
    ],


    titles : [
        'Was sind Extremwerte in der COT Analyse?',
        'COT Extrem Werte Überblick',
    ],

    media : [
        {type: 'image', src: '1CourseWeeklyMedien/extreme-values.png', alt: 'COT Extrem Werte', caption: 'Chart provided by TradingView'},
        {type: 'image', src: '1CourseWeeklyMedien/cot-priceTrend.png', alt: 'COT Extrem Werte Überblick', caption: 'Chart provided by TradingView'},



        /*{type: 'image', src: 'pics/weeklyCotAnalysis/abcd-market.png', alt: '18 MA Pattern'},
        {type: 'video', src: 'videos/CotDataCotIndexAnalysis.mp4', alt: 'Zick Zack Trendanalyse'},
        {type: 'gif', src: 'path/to/your-gif.gif', alt: 'Gewinnen'}, // Direkter Link zum GIF-Bild
        // weitere Medieneinträge...*/
    ],


    texts : [
        {
            content: [
                {type: 'paragraph', text: 'Zu den historische COT Extrem Werten gehören:'},
                {type: 'list', items: ['Das Allzeit -hoch und -tief, sowie im einer 5% Spanne an der 0 Linie.'] },
                {type: 'paragraph', text: 'Wie wird die 5 % Spanne berechnet?'},
                {type: 'list', items: ['0er Linie + 5 % vom historisches COT Hoch'] },
                {type: 'list', items: ['0er Linie - 5 % vom historisches COT Tief'] },
                {type: 'paragraph', text: 'Wir werden diese Berechnungen jedoch nicht ausführen müssen, da ich den Indikator so programmiert habe, dass er die Extremwerte automatisch anzeigt.'},
                {type: 'paragraph', text: 'Sie können dies an der gelben Einfärbung der Commercials Linie erkennen, als auch an den farblich markierten 0er Linie +/- 5 %.'},
                {type: 'paragraph', text: 'Die Extremwerte markieren sehr gute Chancen für den Einstieg in den Markt, da sie eine hohe Wahrscheinlichkeit für eine Trendwende aufweisen.'},
            ]
        },
        {
            content: [
                {type: 'paragraph', text: 'Wie Sie in der Grafik sehr schön erkennen können, markieren die Extremwerte sehr gute Einstiegschancen in den Markt.'},
                {type: 'paragraph', text: 'Betrachte die Grafik und betrachte den Preisverlauf an den markierten Extremwerten. Was fällt dir auf?'},
                {type: 'paragraph', text: 'Die markierten Extremwerte markieren sehr gute Einstiegschancen in den Markt. In der eingezeichneten Range sind Extremwerte besonders an den Unterstützungs- und Widerstandslinien gute Signale die einen zusätzlichen Wertungspunkt in der Analyse verdienen.'},
            ]
        }

        
        /*{
            content: [
                {type: 'paragraph', text: 'Hier ist ein Absatztext für das Zick Zack Pattern'},
                {type: 'list', items: ['Kurzfristig A', 'Mittelfristig B', 'Langfristig C'] }
            ]
        }*/
    ]
}

export default CotExtreme;