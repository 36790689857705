
const IndicatorSettings = {

    headers : [
            'Indicator Setup',
        ],
    
        titles : [
            'COT Data Indicators - Settings',
        ],
    
        media : [
            {type: 'video', src: 'videos/CotIndicator.mp4', alt: 'Settings of the COT Indicators', caption: 'Chart provided by TradingView'},


        /*{type: 'image', src: 'pics/weeklyCotAnalysis/abcd-market.png', alt: '18 MA Pattern', source: '@TradingView'},
        {type: 'video', src: 'videos/CotDataCotIndexAnalysis.mp4', alt: 'Zick Zack Trendanalyse'},
        {type: 'gif', src: 'path/to/your-gif.gif', alt: 'Gewinnen'}, // Direkter Link zum GIF-Bild
        // weitere Medieneinträge...*/
    ],

    texts : [
        {
          content: [
              {type: 'paragraph', text: 'The settings for the COT data indicators are shown in the figure.'},
              {type: 'list', items: ['Add the COT CFTC indicator to the chart twice and set it as shown in the figure.'] },
              {type: 'list', items: ['Add a Simple Moving Average (SMA) to the chart and set it as shown in the figure.'] },
              {type: 'download', text: 'Settings for the Weekly Analysis', href: 'https://firebasestorage.googleapis.com/v0/b/courseapp-e7938.appspot.com/o/1CourseWeeklyMedien%2FCOTSettingsWeekly.png?alt=media&token=8a69864e-4be0-43f3-a62d-6fd864eb877b'},
              {type: 'download', text: 'COT CFTC Indicator', href: 'https://de.tradingview.com/script/L367sdBJ/'},
              {type: 'list', items: ['COT Index for Commercials and Small Speculators with a setting of 26 weeks'] },
              {type: 'list', items: ['Display Futures Long - Short for net positions.'] },
              {type: 'list', items: ['Rescale Open Interest 260'] },
              {type: 'list', items: ['Simple Moving Average (SMA) with a setting of 52 weeks'] },
          ]
        },

        
        
        /*{
            content: [
                {type: 'paragraph', text: 'Hier ist ein Absatztext für das Zick Zack Pattern'},
                {type: 'list', items: ['Kurzfristig A', 'Mittelfristig B', 'Langfristig C'] }
                {type: 'download', text: 'Einstellungen für die Wochenanalyse', href: '1CourseWeeklyPictures/COTSettingsWeekly.png'}
            ]
        }*/
    ]
}

export default IndicatorSettings;