import React from 'react';

const SeasonalsDocumentation = () => {
  return (
    <div className="documentation">
      <h2>Documentation</h2>
      <p>
        This page allows you to upload a CSV file with trading data. The data is then grouped by year and displayed in a table. You can also calculate the percentage change from one trading day to the next and view the results in a table. The average percentage change, the number of positive and negative changes, and the probabilities are also calculated and displayed in a table. Finally, you can perform a custom calculation to determine the cumulative change from a specific column and view the results in a table.
      </p>
      <h3>Instructions</h3>
      <ol>
        <li>How to get a CSV file?
          <ol>
            <li>You can use Tradingview to export the data as a CSV file.</li>
            <li>Open a blank chart you want to export.</li>
            <li>Choose a symbol and timeframe. The timeframe should be Weekly or Daily.</li>
            <li>Select "B-ADJ" in the bottom right corner. This is important to avoid distortions in futures data, as the data would otherwise not be adjusted during contract changes, and the return from close to close would not be displayed correctly.</li>
            <li>All the data you want to export must be visible on the chart.</li>
            <li>Select the correct timeframe such as Weekly.</li>
            <li>Select the arrow for "Manage layout" in the top menu and choose "Export Chart Data..." and select "ISO time" for the time format.</li>
            <li>Save the file as a CSV file.</li>
            <li>Open the file and delete the first rows if the dataset does not start on the first trading day of the year. This is important to have a complete year at the beginning. For the last year, this does not need to be done. It is even important to have the data up to the current day, otherwise, we cannot calculate the correct current trading day of the year.</li>
            <li>Select the file and upload it.</li>
          </ol>
        </li>
        <li>Upload a CSV file with trading data.</li>
        <li>Open the table to view and proof the data.</li>
        <li>In the "Return from Column" field, you can enter a number or leave it at 1. This means that you can calculate the blue line for "Return from Column X" starting from a specific trading day or depending on the set period. With "Calculate Return," you can then update the line or reset the charts if necessary.</li>                
      </ol>
      <div className="example-matrix">
        <img src="../../Medien/inidicator/SeasonalsDoc.png" alt="Tradingview Export" />
        <img src="../../Medien/inidicator/fileuploadInfo.png" alt="CSV File" />
        <h2>What can be read from the charts?</h2>
        <p>Example: Gold</p>
        <p>The first chart shows the average percentage change for each week of the year. The second chart shows the probability that the price will rise or fall on a specific week of the year. The third chart shows the cumulative change in price from a specific week of the year. The fourth chart shows the average percentage change for each week of the year, with positive and negative changes shown separately.</p>
        <p>You can also check the exact data by opening the table. The table shows the percentage change from one week to the next, the sum of all percentage changes, the number of positive and negative changes, the average percentage change, the number of positive and negative changes, the sum of positive and negative changes, the average percentage change of positive and negative changes, the probability that the price will rise or fall on a specific week of the year, and the cumulative change in price from a specific week of the year.</p>
        <img src="../../Medien/inidicator/ReturnTable.png" alt="ReturnTable" />

        <img src="../../Medien/inidicator/seasonalDoc.png" alt="Seasonals" />
        <p>You can also switch between open to close or close to close. If you choose close to close, the previous week's price is taken as the basis. If you choose open to close, the week's price is taken as the basis.</p>
      </div>
      <div>
        <h2> Documentation for the BestTrades Component</h2>
        <p>
          <strong>Overview:</strong> The BestTrades component provides a comprehensive analysis of historical trading data and allows the simulation of trading strategies based on specified criteria such as holding period, stop-loss, and profit probability. The calculations are based on historical open, high, low, and close prices, with trades conducted for selected periods and trading years.
        </p>
        <p>
          <strong>Functionality:</strong> The component allows trades to be simulated by letting the user specify the number of trading days, the holding period in days, and various filter criteria.
          <ul>
            <li><strong>Trading Day Selection:</strong> The user selects a specific trading day on which the analysis is based. Important: For trades, a trading day of 2 or higher must be selected for the calculations to be performed correctly.</li>
            <li><strong>Holding Period in Days:</strong> Specifies how many days a position is held before it is closed.</li>
            <li><strong>Filter Criteria:</strong> Filters trades based on criteria such as profit/loss ratio (P/L Ratio), percentage win probability (% Win), and stop-loss.</li>
          </ul>
        </p>
        <p>
          <strong>Calculation Logic:</strong> 
          <ul>
            <li><strong>First Trading Day (Index 0) – Calculation Open to Close:</strong> The entry is made on the first trading day at the open price. The exit is made at the close price of the same trading day. Example: Entry: Open price of day 1. Exit: Close price of day 1.</li>
            <li><strong>From Trading Day 2 – Calculation Close to Close:</strong> For all trading calculations, the selected trading day must be 2 or higher. The entry is made at the close price of the previous day. The exit is made at the close price of the last trading day, based on the defined holding period. Example: Entry: Close price of day 1. Exit: Close price of day 2 (with a holding period of 1 day).</li>
          </ul>
        </p>
        <p>
          <strong>User Interface:</strong> 
          <ul>
            <li><strong>Matrix Display:</strong> The matrix shows the calculated results for all selected trading years and days in tabular form. The table includes columns such as entry time, holding period, achieved profit/loss, and other statistical metrics.</li>
            <li><strong>Filter Options:</strong> Selection of:
              <ul>
                <li>Month and year</li>
                <li>Stop-loss percentage</li>
                <li>Minimum win probability (% Win)</li>
                <li>Holding period in days</li>
              </ul>
            </li>
          </ul>
        </p>
        <p>
          <strong>Example:</strong> 
          <ul>
            <li><strong>First Trading Day:</strong> Entry on January 1, 2021, at the open price of 50. Exit on January 1, 2021, at the close price of 52. Result: +2.</li>
            <li><strong>Second Trading Day (or higher):</strong> Requirement: The trading day must be 2 or higher. Entry on January 2, 2021, at the close price of the previous day (52). Exit on January 3, 2021, at the close price of 54. Result: +2.</li>
          </ul>
        </p>
        <p>
          <strong>Notes:</strong> 
          <ul>
            <li>For the first trading day (index 0), the calculation is from open to close.</li>
            <li>For all other days (index ≥ 1), the calculation is from close to close.</li>
            <li>Important: For the calculation of trades from the second trading day, the trading day must be 2 or higher.</li>
          </ul>
        </p>
        <div className="example-matrix">
          <img src="../../Medien/inidicator/bestTrades.png" alt="BestTrades" />
        </div>
        <p>
          <strong>Summary:</strong> The BestTrades component provides detailed simulations of trading strategies based on historical data. By distinguishing between open-to-close for the first trading day and close-to-close for all subsequent days, the component ensures that the calculations remain realistic and comprehensible.
        </p>
        <p>
          <strong>Disclaimer:</strong> All information and calculations provided by the BestTrades component are for informational purposes only. They do not constitute financial advice or a recommendation to buy or sell securities. The user is solely responsible for their trading decisions and their outcomes. The site operator assumes no liability for losses or damages resulting from the use of the provided information. It is recommended to thoroughly inform oneself about the risks before trading financial instruments and to seek professional advice if necessary.
        </p>
      </div>
    </div>
  );
};

export default SeasonalsDocumentation;