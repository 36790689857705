import React from 'react';
import '../css/standard.css';
import '../css/formular.css'

export default function Logout() {
  return (
    <div className='home'>
      <h1>Log Out</h1>
      <p>Do you want to log out? </p>
    </div>
  );
}