import React, { useEffect, useState } from 'react';
import Buybutton from './Buybutton';
import CheckIcon from '../components/CheckIcon';

function Buy() {
  const [offer, setOffer] = useState('');

  useEffect(() => {
    const availableOffer = new Date("Dec 31, 2024 00:00:00").getTime();

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = availableOffer - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setOffer(`${days}d ${hours}h ${minutes}m ${seconds}s`);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div id='courseTarget'>
      <div id="buy" className="buy-container">
        <div className="left-offer-section">
          <h2>Become a Professional Trader</h2>
          <h4>Benefit from a proven strategy with a success rate of 75%!</h4>
          <ul>
            <li>
              <CheckIcon />
              Fundamental analysis based on COT data
            </li>
            <li>
              <CheckIcon />
              Creating the weekly watchlist
            </li>
            <li>
              <CheckIcon />
              Chart analysis with entry patterns
            </li>
            <li>
              <CheckIcon />
              Timing and trade setups
            </li>
            <li>
              <CheckIcon />
              Trade management & stop techniques
            </li>
            <li>
              <CheckIcon />
              Risk-money management
            </li>
            <li>
              <CheckIcon />
              Trading psychology
            </li>
            <li>
              <CheckIcon />
              TradingView indicators
            </li>
            <li>
              <CheckIcon />
              Seasonals - statistical evaluation
            </li>
            <li>
              <CheckIcon />
              Short intro into cycle analysis
            </li>
          </ul>
        </div>
        <div className="right-offer-section">
          <p id="newprice">Now for only $99</p>
          <div id="oldprice">
            <p>Price: $499</p>
          </div>
          <Buybutton />
          <p id="offer">Special offer valid only until: <br/> <span id="offerTill"> {offer} </span></p>
          <p id="countdownText">You will never witness such a low price again.<br /> Seize this unique opportunity now for lifetime access and exclusive future content.<br /> Don't miss out on this once-in-a-lifetime deal. Act now and secure your future benefits!</p>
        </div>
      </div>
    </div>
  );
}

export default Buy;
