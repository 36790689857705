import React, { useState, useEffect } from 'react';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../css/standard.css'; // Importieren der CSS-Datei

const ImageSwiper = () => {
  const [slides] = useState([
    {
      imgSrc: '../../Medien/inidicator/COTNormal.png',
      text: 'COT Normal Index Indicator'
    },
    {
      imgSrc: '../../Medien/inidicator/COTCycles.png',
      text: 'COT Cycles Index Indicator'
    },
    {
      imgSrc: '../../Medien/inidicator/COTMomentum.png',
      text: 'COT Momentum Index Indicator'
    },
    {
      imgSrc: '../../Medien/inidicator/TrendfilterChart.png',
      text: 'Trendfilter Chart with Patterns 1'
    },
    {
      imgSrc: '../../Medien/inidicator/TrendfilterAD1.png',
      text: 'Trendfilter AD1'
    },
    {
      imgSrc:  '../../Medien/inidicator/TrendfilterAD2.png',
      text: 'Trendfilter AD2 with Patterns 2'
    },
    {
      imgSrc: '../../Medien/inidicator/TrendfilterMTF-C.png',
      text: 'Trendfilter Multi Time Frame Cyclic'
    },
    {
      imgSrc: '../../Medien/inidicator/dailyAllSub.png',
      text: 'Daily All Sub Chart Indicator'
    }, 
    {
      imgSrc: '../../Medien/inidicator/dailyAll.png',
      text: 'Daily All Indicator'
    },
    {
      imgSrc: '../../Medien/inidicator/calculator.png',
      text: 'Seasonals and Futures Calculator'
    },
    {
      imgSrc: '../../Medien/inidicator/seasonals.png',
      text: 'Seasonals Indicator'
    },
    {
      imgSrc: '../../Medien/inidicator/ProbabilityChart.png',
      text: 'Find high Probability Trades'
    },
    {
      imgSrc: '../../Medien/inidicator/probabilities.png',
      text: 'Probabilities'
    },
    {
      imgSrc: '../../Medien/inidicator/bestTrades.png',
      text: 'Analyse Best Trades Probabilities'
    },
    {
      imgSrc: '../../Medien/inidicator/cotdata.png',
      text: 'COT Data Charts'
    }
  ]);

  useEffect(() => {
    new Swiper('.swiper', {
      modules: [Navigation, Pagination],
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }, []);

  return (
    <>
      <div className="swiper swiper-margin-top">
        <div className="swiper-wrapper">
          {slides.map((slide, index) => (
            <div className="swiper-slide" key={index}>
              <img src={slide.imgSrc} alt={`Slide ${index + 1}`} />
              <p>{slide.text}</p> {/* Text unter dem Bild */}
            </div>
          ))}
        </div>
        <div className="swiper-pagination"></div>
        <div className="swiper-button-prev"></div>
        <div className="swiper-button-next"></div>
      </div>
    </>
  );
};

export default ImageSwiper;