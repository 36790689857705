import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import '../css/course-frame.css'; 
import courseContent from './course-content';
import Navigation from './course-navigation';
import { getMediaUrls } from '../firebaseConfig';

function CourseUnit() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { courseId } = useParams();
  const currentYear = new Date().getFullYear();
  const [mediaUrls, setMediaUrls] = useState([]);
  const [isEnlarged, setIsEnlarged] = useState(false);

  const courseData = courseContent.find(course => course.id === parseInt(courseId));

  useEffect(() => {
    console.log("CourseUnit mounted");
    console.log("courseId:", courseId);
    console.log("courseData:", courseData);
    setCurrentIndex(0);
  }, [courseId, courseData]);

  useEffect(() => {
    const loadMediaUrls = async () => {
      if (courseData?.content.media) {
        const urls = await getMediaUrls(courseData.content.media);
        setMediaUrls(urls);
      }
    };

    loadMediaUrls();
  }, [courseData]);

  const navigate = useCallback((direction) => {
    setCurrentIndex(prevIndex => {
      let newIndex = prevIndex + direction;
      if (newIndex < 0) newIndex = 0;
      else if (newIndex >= courseData?.content.titles.length) newIndex = courseData.content.titles.length - 1;
      return newIndex;
    });
  }, [courseData]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
        event.preventDefault(); // Verhindert das Scrollen des Fensters
        if (event.key === 'ArrowLeft') {
          navigate(-1);
        } else if (event.key === 'ArrowRight') {
          navigate(1);
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [navigate]);

  const renderContent = () => {
    if (!courseData) {
      return <div>Loading...</div>;
    }

    const { headers, titles, texts } = courseData.content;
    const textContents = texts[currentIndex]?.content || [];
    const mediaContent = mediaUrls[currentIndex];

    return (
      <>
        <Navigation header={headers[0]} />
        <main className='grid-container' id='main'>
          <section id='course-text' className='grid-item'>
            <h2 id='topic'>{titles[currentIndex]}</h2>
            <div id='text'>
              {textContents.map((content, index) => {
                switch (content.type) {
                  case 'paragraph':
                    return <p key={index}>{content.text}</p>;
                  case 'headline':
                    return <h2 key={index}>{content.text}</h2>;
                  case 'list':
                    return (
                      <ul key={index}>
                        {content.items.map((item, itemIndex) => (
                          <li key={itemIndex}>{typeof item === 'object' ? <strong>{item.text}</strong> : item}</li>
                        ))}
                      </ul>
                    );
                  case 'download':
                    return (
                      <a 
                        key={index} 
                        href={content.href} 
                        download 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="download-button"
                      >
                        {content.text}
                      </a>
                    );
                  default:
                    return null;
                }
              })}
            </div>
          </section>

          <section id='course-visualisation' className='grid-item'>
            <div id='media'>
              {mediaContent?.type === 'image' && (
                <figure>
                  <img 
                    src={mediaContent.src} 
                    alt={mediaContent.alt} 
                    className={isEnlarged ? 'enlarged' : ''}
                    onClick={() => setIsEnlarged(!isEnlarged)}
                    />
                  <figcaption>{mediaContent.caption}</figcaption>
                </figure>
              )}
              {mediaContent?.type === 'video' && (
                <figure>
                  <video controls>
                    <source src={mediaContent.src} type="video/mp4" />
                    Ihr Browser unterstützt das Video-Tag nicht.
                  </video>
                  <figcaption>{mediaContent.caption}</figcaption>
                </figure>
              )}
              {mediaContent?.type === 'gif' && (
                <figure>
                  <img 
                    src={mediaContent.src} 
                    alt={mediaContent.alt} 
                    className={isEnlarged ? 'enlarged' : ''}
                    onClick={() => setIsEnlarged(!isEnlarged)}
                    />
                  <figcaption>{mediaContent.caption}</figcaption>
                </figure>
              )}
            </div>
          </section>

          <section className="grid-item">
            <div className='course-footer'>
              <p>&copy; <span id="year">{currentYear}</span> by COT Season.com</p>
            </div>
          </section>

          <section className='grid-item arrows'>
            {currentIndex > 0 && 
              (<i id='prev' className='fa-solid fa-arrow-left' onClick={() => navigate(-1)}></i>)}
            {currentIndex < courseData?.content.titles.length - 1 && 
              (<i id='next' className='fa-solid fa-arrow-right' onClick={() => navigate(1)}></i>)}
          </section>
        </main>
      </>
    );
  };

  return renderContent();
}

export default CourseUnit;
