import React, { useState, useCallback, useMemo, useRef } from 'react';
import Papa from 'papaparse';
import { Bar } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend, LineController } from 'chart.js';
import '../css/matrix.css';
import SeasonalsDocumentation from './seasonaldocumentation';
import Footer from '../landingComponents/Footer';
import SeasonalComponent from './TrendDecomposition';
import BestTrades from './BestTrades';
import { groupByYear, calculatePercentageChanges, calculatePercentageStats } from './SeasonalCalculation';
import { PercentChangeTable, StatsTable } from './seasonalTables';
import useDeepCompareEffect from 'use-deep-compare-effect';

// Registriere die Skalen, Elemente und Controller
Chart.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend, LineController);

const Seasonals = () => {
    const [csvFile, setCsvFile] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [maxColumns, setMaxColumns] = useState(0);
    const [isTable2Visible, setIsTable2Visible] = useState(false);
    const [isTable3Visible, setIsTable3Visible] = useState(true);

    const [usePreviousClose, setUsePreviousClose] = useState(false);
    const [seasonalCurve, setSeasonalCurve] = useState([]);

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (!file) {
            console.error("No file selected");
            return;
        }
        setCsvFile(file); // Setze die csvFile-Variable
        Papa.parse(file, {
            header: true,
            complete: (result) => {
                if (result.errors.length > 0) {
                    console.error("Error parsing CSV file:", result.errors);
                    return;
                }
                const data = result.data;
                // Sortiere nach Datum und gruppiere nach Jahr
                const sortedData = data.sort((a, b) => new Date(a.time) - new Date(b.time));
                const groupedData = groupByYear(sortedData);
                
                // Korrigiere den ersten Tag des ersten Jahres
                Object.keys(groupedData).forEach(year => {
                    const yearData = groupedData[year];
                    if (yearData.length > 0) {
                        // Setze den ersten Tag (day 1) mit sich selbst als Referenz
                        yearData[0].previousClose = yearData[0].close;
                    }
                });
    
                setTableData(groupedData);
                setMaxColumns(Math.max(...Object.values(groupedData).map(yearData => yearData.length)));
            }
        });
    };
    

    const getCurrentTradingDayOfYear = useCallback((tableData) => {
        const currentYear = new Date().getFullYear();
        let tradingDay = 0;

        if (tableData[currentYear]) {
            tableData[currentYear].forEach(row => {
                tradingDay++;
            });
        }

        return tradingDay;
    }, []);

    const handleToggle = () => {
        setUsePreviousClose(!usePreviousClose);
    };

    useDeepCompareEffect(() => {
        if (Object.keys(tableData).length > 0) {
            const parsedData = Object.values(tableData).reduce((acc, yearData) => acc.concat(yearData), []);
            handleSeasonalCurveUpdate(parsedData);
        }
    }, [tableData]);

    const handleSeasonalCurveUpdate = useCallback((curve) => {
        setSeasonalCurve(curve);
    }, []);

    const percentageChanges = useMemo(() => calculatePercentageChanges(tableData, usePreviousClose), [tableData, usePreviousClose]);
    const percentageStats = useMemo(() => calculatePercentageStats(percentageChanges, maxColumns, usePreviousClose), [percentageChanges, maxColumns, usePreviousClose]);

    const data1 = useMemo(() => ({
        labels: Array.from({ length: maxColumns - 1 }, (_, i) => i + 1),
        datasets: [
            {
                label: 'Average',
                data: percentageStats.averages.map(avg => avg),
                backgroundColor: percentageStats.averages.map(avg => avg >= 0 ? '#6a9bdd' : 'purple'),
            },
            {
                label: 'Positive Average',
                data: percentageStats.positiveAverages.map(avg => avg),
                backgroundColor: '#095F92',
                hidden: true,
            },
            {
                label: 'Negative Average',
                data: percentageStats.negativeAverages.map(avg => avg),
                backgroundColor: 'rgb(147, 24, 24)',
                hidden: true,
            },
        ],
    }), [percentageStats, maxColumns]);

    const options1 = useMemo(() => ({
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Trade TimeFrame',
                },
            },
            y: {
                display: true, // Aktiviert die Y-Achse
                title: {
                    display: true,
                    text: 'Average Return',
                }
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            tooltip: {
                callbacks: {
                    label: function(context) {
                        const index = context.dataIndex;
                        const positiveProbability = percentageStats.positivePercentages[index];
                        const negativeProbability = percentageStats.negativePercentages[index];
                        return [
                            `Average: ${context.raw}%`,
                            `Positive Probability: ${positiveProbability}%`,
                            `Negative Probability: ${negativeProbability}%`
                        ];
                    }
                }
            }
        }
    }), [percentageStats]);

    // Filtere die Daten, um nur die höhere Wahrscheinlichkeit anzuzeigen
    const filteredPositiveProbabilities = percentageStats.positivePercentages.map((value, index) => 
        value > percentageStats.negativePercentages[index] ? value : null
    );
    const filteredNegativeProbabilities = percentageStats.negativePercentages.map((value, index) => 
        value > percentageStats.positivePercentages[index] ? value : null
    );

    const data2 = useMemo(() => ({
        labels: Array.from({ length: maxColumns - 1 }, (_, i) => i + 1),
        datasets: [
            {
                label: 'Positive Probabilities',
                data: filteredPositiveProbabilities,
                backgroundColor: '#6a9bdd', // Helleres Blau
                borderColor: '#6a9bdd',
                borderWidth: 4,
            },
            {
                label: 'Negative Probabilities',
                data: filteredNegativeProbabilities,
                backgroundColor: 'purple', // Helleres Rot
                borderColor: 'purple',
                borderWidth: 4,
            },
        ],
    }), [filteredPositiveProbabilities, filteredNegativeProbabilities, maxColumns]);
    
    const options2 = useMemo(() => ({
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Trade TimeFrame',
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Probability',
                },
                min: 50, // Erzwingt, dass die Achse bei 40 beginnt
            },
        },
        responsive: true, // Stelle sicher, dass die Charts responsiv sind
        maintainAspectRatio: false, // Verhindere, dass die Charts verzerrt werden
    }), []);

    // Schritt 1: Referenz erstellen
    const seasonalsDocRef = useRef(null);

    // Schritt 2: Scroll-Funktion hinzufügen
    const handleScrollToDocumentation = () => {
        if (seasonalsDocRef.current) {
            seasonalsDocRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

        
    return (
        <div className="trade-analysis container-matrix">
            <div className="table-container">
                <div className='SeasonalSettings'>
                    <h2>Seasonals Analysis - CSV Upload</h2>
                    <p>Use Daily or Weekly data for the analysis</p>
                    <p onClick={handleScrollToDocumentation} style={{ cursor: 'pointer', color: 'purple', textDecoration: 'underline' }}>
                        For more information, please refer to the documentation below.
                    </p>
                    <input type="file" accept=".csv" onChange={handleFileUpload} />
                    <h3 style={{color: "#ddad6a"}}>Trading Time Now: {getCurrentTradingDayOfYear(tableData)}</h3>
            
                    <h3 onClick={() => setIsTable2Visible(!isTable2Visible)} style={{ cursor: 'pointer' }}>
                        Percent Change to next Trading Day {isTable2Visible ? '▲' : '▼'}
                    </h3>
                    <h3 onClick={() => setIsTable3Visible(!isTable3Visible)} style={{ cursor: 'pointer' }}>
                        Trading Time of Year {isTable3Visible ? '▲' : '▼'}
                    </h3>
                    <div className="matrix-button">
                        <button onClick={handleToggle}>
                            {usePreviousClose ? 'Use Open to Close' : 'Use Close to Previous Close'}
                        </button>
                    </div>
                </div>
                <div className="tables">
                    <div className="table-wrapper">
                        <PercentChangeTable
                            isTable2Visible={isTable2Visible}
                            percentageChanges={percentageChanges}
                            maxColumns={maxColumns}
                            usePreviousClose={usePreviousClose}
                        />
                    </div>
                    <div className="table-wrapper">
                        <StatsTable
                            isTable3Visible={isTable3Visible}
                            percentageStats={percentageStats}
                            maxColumns={maxColumns}
                            usePreviousClose={usePreviousClose}
                        />
                    </div>
                </div>
            </div>
            <BestTrades csvFile={csvFile}/>
            <div className="chart-container-m">
                <Bar data={data1} options={options1} />
            </div>
            <div className="chart-container-m">
                <Bar data={data2} options={options2} />
            </div>
            <div className="chart-container-m-season">
                <SeasonalComponent data={Object.values(tableData).reduce((acc, yearData) => acc.concat(yearData), [])} onUpdate={handleSeasonalCurveUpdate} />
            </div>
            <div ref={seasonalsDocRef}>
                <SeasonalsDocumentation />
            </div>
            <Footer />
        </div>
    );
}

export default Seasonals;