const FinViz = {
    headers : [
        'FinViz Screener - Market Overview',
    ], 

    titles : [
        'Daily Routine',
    ],

    media : [
        {type: 'image', src: '3CourseAdditionalMedien/finviz.png', alt: 'FinViz Screener', caption: '@ FinViz.com'},
    ],

    texts : [
        {
            content: [
                {type: 'paragraph', text: 'The FinViz Screener is a useful tool to get an overview of the markets.'},
                {type: 'paragraph', text: 'Under the Futures Charts tab, you can view various markets such as indices, commodities, and currencies.'},
                {type: 'paragraph', text: 'The screener shows you the current price movements and the performance of the markets. Therefore, it makes sense to get a daily overview of their movements, which helps develop a better understanding of price movements.'},
                {type: 'paragraph', text: 'As you will notice, markets from the same sector often tend to move in the same direction.'},
                {type: 'download', text: 'FinViz Screener', href: 'https://finviz.com/futures_charts.ashx'},
            ]
        },
    ]
};

export default FinViz;