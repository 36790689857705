import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useState, useEffect } from 'react';

export function useAuthStatus() {
    const [loggedIn, setLoggedIn] = useState(false);
    const [checkingStatus, setCheckingStatus] = useState(true);
    const [displayName, setDisplayName] = useState(''); 

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setLoggedIn(true);
                setDisplayName(user.displayName);  // Den Namen des Benutzers setzen
            } else {
                setLoggedIn(false);
                setDisplayName('');
            }
            setCheckingStatus(false);
        });

        return () => unsubscribe();  // Bereinigung des login-Status
    }, []);

    return { loggedIn, checkingStatus, displayName };
}