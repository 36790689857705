
const Pattern = {
    headers : [
            'Price Patterns',
        ],
    
        titles : [
            'Introduction to Trade Patterns',
            'Indicators for Daily Analysis',
            '18MA Impulse Pattern',
            '18MA Correction Pattern',
            'ADMA Impulse Pattern',
            'ADMA Correction Pattern',
            'Larry Williams PercentR 4 - Correction Pattern',
            'Inside Bar Pattern',
            'Inside Bar Pattern: Examples',
            'Outside Bar Pattern',
            'Momentum 28 Pattern: Bullish',
            'Momentum 28 Pattern: Bearish Scenario and Hidden Divergences',
            'Video: all Patterns'
        ],
    
        media : [
            {type: 'image', src: '2CourseDailyMedien/patterndiskussion.png', alt: 'Introduction to Trade Patterns', caption: '@ pixabay.com'},
            {type: 'video', src: 'videos/DailyIndicators.mp4', alt: 'Chart Template'},
            {type: 'image', src: '2CourseDailyMedien/18maPattern.png', alt: '18 MA Impulse Pattern', caption: 'Chart provided by TradingView'},
            {type: 'image', src: '2CourseDailyMedien/18maCorrectionPattern.png', alt: '18 MA Correction Pattern', caption: 'Chart provided by TradingView'},
            {type: 'image', src: '2CourseDailyMedien/ADMAPattern.png', alt: 'ADMA Impulse Pattern', caption: 'Chart provided by TradingView'},
            {type: 'image', src: '2CourseDailyMedien/ADMACorrection Pattern.png', alt: 'ADMA Correction Pattern', caption: 'Chart provided by TradingView'},
            {type: 'image', src: '2CourseDailyMedien/pRPattern.png', alt: 'Percent R Pattern', caption: 'Chart provided by TradingView'},
            {type: 'image', src: '2CourseDailyMedien/pptInsidePatterns.png', alt: 'Inside Bar Pattern'},
            {type: 'image', src: '2CourseDailyMedien/insidebarsexample.png', alt: 'Inside Bar Pattern Example', caption: 'Chart provided by TradingView'},
            {type: 'image', src: '2CourseDailyMedien/pptOutsidePatterns.png', alt: 'Outside Bar Pattern'},
            {type: 'image', src: '2CourseDailyMedien/mom28Pattern.png', alt: 'Momentum 28 Pattern Bullish', caption: 'Chart provided by TradingView'}, 
            {type: 'image', src: '2CourseDailyMedien/PTmomhiddenDiv.png', alt: 'Momentum 28 Pattern Bearish', caption: 'Chart provided by TradingView'},
            {type: 'video', src: 'videos/patterns.mp4', alt: 'Video: all Patterns'}
        ],


    texts : [
            {
                content: [
                    {type: 'paragraph', text: 'Trade patterns are patterns that appear on the chart and indicate a possible trend reversal or continuation. These patterns can be used as an indicator for entering a trade. There are different types of trade patterns based on different indicators or price patterns.'},
                    {type: 'paragraph', text: 'In this course, we will focus on the following trade patterns. Now use the daily chart:'},
                    {type: 'list', items: [
                        '18MA Impulse Pattern',
                        '18MA Correction Pattern',
                        'ADMA Impulse Pattern',
                        'ADMA Correction Pattern',
                        'Percent R Pattern',
                        'Inside Bar Pattern',
                        'Outside Bar Pattern',
                        'Momentum 28 Pattern'
                        ]
                    },
                    {type: 'paragraph', text: 'Each pattern has specific rules for entry, stop-loss, and trade validity. It is important to understand and follow these rules to trade successfully.'},
                    {type: 'paragraph', text: 'It is important to gain experience in trading with patterns and to consistently apply the rules. Thoroughly testing the patterns is important to develop market understanding and internalize the patterns.'},
                    {type: 'paragraph', text: 'Over time, you will build emotional capital and learn to master your strategy. It is important to test and apply the patterns in different market phases to develop a feel for the various scenarios.'},
                    {type: 'paragraph', text: 'However, first continue with the course to learn more rules before testing the patterns. A few more details will be discussed in the course that will help you trade patterns correctly.'},
                    {type: 'paragraph', text: 'Feel free to look at the patterns on the chart and compare them within and outside of COT signals.'},
                ]
            },
            {
                content: [
                    {type: 'paragraph', text: 'First, add the following indicators to the daily chart and make sure the settings are correct:'},
                    {type: 'download', text: 'Trend Filter Chart', href: 'https://de.tradingview.com/script/pa5d6t1l/'},
                    {type: 'download', text: 'ADMA Indicator', href: 'https://de.tradingview.com/script/hTZzkDZ3/'},
                    {type: 'download', text: 'Momentum 28 Indicator', href: 'https://www.tradingview.com/script/1sbSFdIJ-Momentum-28/'},
                    {type: 'list', items: [
                        '18 MA (Moving Average) - close',
                        '10 MA (Moving Average) - high',
                        '8 MA (Moving Average) - low',
                        'ADMA 57 Indicator',
                        'Larry Williams PercentR 4'
                        ]
                    },
                    {type: 'paragraph', text: 'You can also find the Percent R and Momentum in the standard scripts of Tradingview.'},
                ]
            },
            {
                content: [
                    {type: 'paragraph', text: 'The 18 MA Impulse Pattern focuses on the interaction between candles and the 18-day moving average. The pattern consists of three candles: '},
                    {type: 'list', items: [
                        'The first candle crosses the 18-day moving average, with the low of the candle below the moving average and the high of the candle above the moving average.',
                        'The positioning of the second and third candles determines the scenario: ',
    
                        {type: 'strong', text: 'Bullish Scenario:'},
                        'The lows of the second and third candles are above the 18-day moving average.',
                        {type: 'strong', text: 'Bearish Scenario:'},
                        'The highs of the second and third candles are below the 18-day moving average.',
    
                        'Inside bars are not considered. However, if the pattern shows inside bars, we need to wait for an additional candle for confirmation.',
                        'Thus, the 18MA Impulse Pattern consists of a total of 3 candles and with inside bar of at least 4 candles.'
                        ]
                    },
                    {type: 'paragraph', text: 'Rules for entry and stop-loss:'},
                    {type: 'list', items: [
                        'Long Entry: Open a long position at the highest high + 1 tick of the highest candle of the impulse pattern.',
                        'Initial Stop-Loss: Set just below the low - 1 tick of the candle crossing the MA, or according to',
                        'Market technique: Set just below the low - 1 tick of the candle from the last short-term low.',
                        ]
                    },
                    {type: 'list', items: [
                        'Short Entry: Open a short position at the lowest low - 1 tick of the lowest candle of the impulse pattern.',
                        'Initial Stop-Loss: Set just above the high + 1 tick of the candle crossing the MA or according to',
                        'Market technique: Set just above the high + 1 tick of the candle from the last short-term high.'
                        ]
                    },
                    {type: 'paragraph', text: 'Rules for the validity of trades:'},
                    {type: 'list', items: [
                        'Impulse patterns are valid for 5 days after their occurrence.',
                        'A valid COT (Commitment of Traders) signal is required for entry, with a validity of 25 trading days.'
                        ]
                    },
                    {type: 'paragraph', text: 'You can set the ticks by clicking on the gear and adjusting the settings.'},
                    {type: 'paragraph', text: 'If the price closes above or below the MA18, a new impulse pattern must be awaited. Advanced traders can also trade with a close above or below the MA18 if the price moves in the opposite direction of the impulse pattern. However, sufficient experience is required for this.'},
    
                ]
            },
            {
                content: [
                    {type: 'paragraph', text: 'Correction patterns are only traded if an impulse pattern has previously occurred.'},
                    {type: 'paragraph', text: 'Long Entry: '},
                    {type: 'list', items: [
                        'If the price of a rising market corrects downwards after an impulse pattern, a long position is opened when the candle range cuts the MA18.',
                        'The close of the candle should be above the 18-day moving average. If it is below the moving average, the position is not opened.',
                        'Entry: Open a long position at the high + 1 tick of the candle that cuts the MA18.',
                        'Stop-Loss: Set just below the low - 1 tick of the candle that cuts the MA18.'
                    ]
                    },
                    {type: 'paragraph', text: 'Short Entry: '},
                    {type: 'list', items: [
                        'If the price of a falling market corrects upwards after an impulse pattern, a short position is opened when the candle range cuts the MA18.',
                        'The close of the candle should be below the 18-day moving average. If it is above the moving average, the position is not opened.',
                        'Entry: Open a short position at the low - 1 tick of the candle that cuts the MA18.',
                        'Stop-Loss: Set just above the high + 1 tick of the candle that cuts the MA18.'
                    ]
                    },
                    {type: 'paragraph', text: 'Rules for the validity of trades:'},
                    {type: 'list', items: [
                        'Correction patterns are valid for 2 days after their occurrence.',
                        'A valid COT (Commitment of Traders) signal is required for entry, with a validity of 25 trading days.'
                        ]
                    }
                    
                ]
            },
            {
                content: [
                    {type: 'paragraph', text: 'The ADMA Impulse Pattern focuses on the interaction between candles and the 10 MA (data from the high) and the 8 MA (data from the low) and the ADMA 57 indicator. The pattern consists of 2 candles: '},
                    {type: 'paragraph', text: 'ADMA Impulse Pattern Rules: '},
                    {type: 'list', items: [
                        'Buy signal is identified by 2 bars low above the channel (10MA high) and a positive ADMA',
                        'Sell signal is identified by 2 bars high below the channel (8MA low) and a negative ADMA',
                        'Two consecutive bars above/below the channel line, in conjunction with a positive or negative ADMA indicator, signal a potential trend reversal.',
                        'The 2-bar pattern can also be traded with inside bars.',
                        ]
                    },
                    {type: 'paragraph', text: 'Entry and Stop-Loss:'},
                    {type: 'list', items: [
                        'Long Entry: Entry at the highest high + 1 tick of the candle that triggers the buy signal.',
                        'Short Entry: Entry at the lowest low - 1 tick of the candle that triggers the sell signal.',
                        'Stop-Loss for Long Position: Set just below the low - 1 tick according to market technique (STL).',
                        'Stop-Loss for Short Position: Set just above the high + 1 tick according to market technique (STH).',
                        ]
                    },
                    {type: 'paragraph', text: 'Rules for the validity of trades:'},
                    {type: 'list', items: [
                        'Impulse patterns are valid for 5 days after their occurrence.',
                        'A valid COT (Commitment of Traders) signal is required for entry, with a validity of 25 trading days.'
                        ]
                    },
                ]
            },
            {
                content: [
                    {type: 'paragraph', text: 'Correction patterns are only traded if an impulse pattern has previously occurred.'},
                    {type: 'paragraph', text: 'Long Entry: '},
                    {type: 'list', items: [
                        'If the price of a rising market corrects downwards after an impulse pattern, a long position is opened when the close falls below the lower band of the 8MA low.',
                        'The ADMA 57 must be positive.',
                        'Entry: Open a long position at the close + 1 tick of the candle that cuts the 8 MA low.',
                        'Stop-Loss: Set the stop from the lower band minus twice the channel width downwards.'
                        ]
                    },
                    {type: 'paragraph', text: 'Short Entry: '},
                    {type: 'list', items: [
                        'If the price of a falling market corrects upwards after an impulse pattern, a short position is opened when the close exceeds the upper band of the 10MA high.',
                        'The ADMA 57 must be negative.',
                        'Entry: Open a short position at the close - 1 tick of the candle that cuts the 10 MA high.',
                        'Stop-Loss: Set the stop from the upper band plus twice the channel width upwards.'
                        ]
                    },
                    {type: 'paragraph', text: 'Rules for the validity of trades:'},
                    {type: 'list', items: [
                        'Correction patterns are valid for 2 days after their occurrence.',
                        'A valid COT (Commitment of Traders) signal is required for entry, with a validity of 25 trading days.'
                        ]
                    },
                    
                ]
            },
            {
                content: [
                    {type: 'paragraph', text: 'The Percent R Pattern is based on the Percent R indicator with a 4-period setting on the close.'},
                    {type: 'paragraph', text: 'A correction pattern may only be traded, as with all other correction patterns, if an impulse pattern has previously been triggered.'},
                    {type: 'paragraph', text: 'Long Entry: '},
                    {type: 'list', items: [
                        'Look for long entry opportunities in an uptrend when Percent R is below 25 on the index.',
                        'Entry: High + 1 tick.',
                        'Stop-Loss: Low - 1 tick.',
                        'Alternative Stop-Loss: Use the channel width of the 8-period MA low and the 10-period MA high or set it according to the last short-term low using market technique.'
                        ]
                    },
                    {type: 'paragraph', text: 'Short Entry: '},
                    {type: 'list', items: [
                        'Look for short entry opportunities in a downtrend when Percent R is above 75 on the index.',
                        'Entry: Low - 1 tick of the candle.',
                        'Stop-Loss: High - 1 tick of the candle.',
                        'Alternative Stop-Loss: Use the channel width of the 8-period MA high and the 10-period MA low or set it according to the last short-term high using market technique.'
                        ]
                    },
                    {type: 'paragraph', text: 'If the trend continues to correct, adjust the entries to align with new Percent R patterns or other patterns.'},
                    {type: 'paragraph', text: 'Rules for the validity of trades:'},
                    {type: 'list', items: [
                        'Percent R patterns are valid for 2 days after their occurrence.',
                        'A valid COT (Commitment of Traders) signal is required for entry, with a validity of 25 trading days.'
                        ]
                    },
                    
                ]
            },
            {
                content: [
                    {type: 'paragraph', text: 'The Inside Bar Pattern is a pattern that occurs when a candle closes within the range of the previous candle. The pattern can be used as an indicator for a possible trend reversal or continuation.'},
                    {type: 'paragraph', text: 'Rules for entry and stop-loss:'},
                    {type: 'list', items: [
                        'Entries: Consider the close of the inside bar. This pattern works best in strong trends.',
                        'Pay attention to the open and close of the inside bar and the previous candle.',
                        'For example, if the market is in an uptrend and we have a positive candle (close > open) before the inside bar and a negative (close < open) inside bar candle, we look for an entry at the high + 1 tick of the inside bar candle and set the stop at the low of the previous candle.',
                        'For example, if we have a negative previous candle and a positive inside bar in an uptrend and the close of the candle is very close to the high, then a small distance to the high should be added. We take the distance between the high and the close and add this to the high + 1 tick of the inside bar to calculate the entry.',
                        'For example, if we have a negative previous candle and a negative inside bar, then the entry should be at the high + 1 tick of the inside bar and the stop should be set at the low of the previous candle.',
                
                        ]
                    },
                    {type: 'paragraph', text: 'Rules for the validity of trades:'},
                    {type: 'list', items: [
                        'Inside bar patterns are valid for 1 day after their occurrence.',
                        'A valid COT (Commitment of Traders) signal is required for entry, with a validity of 25 trading days.'
                        ]
                    }
                ]
            },
            {
                content: [
                    {type: 'paragraph', text: 'As you can see in the examples, it is important to observe the 1-day rule. You can adjust the entry if it has not been triggered the next day and a new pattern emerges.'},
                    {type: 'paragraph', text: 'It is important to test and apply the patterns in different market phases to develop a feel for the various scenarios.'},
                    
                ]
            },
            {
                content: [
                    {type: 'paragraph', text: 'The Outside Bar Pattern is a pattern that occurs when a candle exceeds the range of the previous candle. The pattern can be used as an indicator for a possible trend reversal or continuation.'},
                    {type: 'paragraph', text: 'Rules for entry and stop-loss:'},
                    {type: 'list', items: [
                        'The same system applies as with the inside bar, except that the range of the outside bar exceeds the range of the previous candle in high and low.',
                        'Pay attention to the open and close relationships of the candles.',
                        'Entries: Basically, we always take the high or low +/- 1 tick of the candle for the entry. However, there is an exception for the outside bar: ', 
                        'Since the range of the outside bar is very large, entries can also be made at the high or low +/- 1 tick of the previous candle if the close of the outside bar is very far from the high or low.',
                        'Stop-Loss: Set the stop at the low or high of the outside bar.',
                        'As with the inside bar, a strong trend is important. As you will find, there are numerous inside and outside bar patterns in a range that burn more money than generate profits.'
                        ]
                    },
                    {type: 'paragraph', text: 'Rules for the validity of trades:'},
                    {type: 'list', items: [
                        'Outside bar patterns are valid for 1 day after their occurrence.',
                        'A valid COT (Commitment of Traders) signal is required for entry, with a validity of 25 trading days.'
                        ]
                    }
                ]
            },
            {
                content: [
                    {type: 'paragraph', text: 'The Momentum 28 Pattern is based on the Momentum Indicator with a 28-period setting on the close.'},
                    {type: 'paragraph', text: 'To identify a Mom28 Pattern, we need 4 points and a divergence between the momentum and the price.'},
                    {type: 'list', items: [
                        {type: 'strong', text: 'Bullish Scenario:' },
                        'We look for a price (Point A) that is lower than the price in the last 25 days (Point D) in a falling market.',
                        'Additionally, we need a momentum value (Point B), vertically aligned with Point A, which is higher than the momentum value in the last 25 days (Point C).',
                        'The next step is to find the highest point of the momentum line between Point B and Point C and draw a horizontal line to the right.',
                        'Once the momentum line closes higher than this horizontal line, we can take an entry at the high + 1 tick of the candle.'
                    ]},
                    {type: 'paragraph', text: 'Stop-Loss: First, consider the market structure to set the stop. Choose the already explained techniques depending on the candle formations or set it according to market technique at the STH or STL.'},
                    {type: 'paragraph', text: 'Rules for the validity of trades: The pattern is valid for 5 days. A valid COT signal is required for entry, with a validity of 25 trading days.'},
                ]
            },
            {
                content: [
                    {type: 'list', items: [
                        {type: 'strong', text: 'Bearish Scenario:' },
                        'We look for a price (Point A) that is higher than the price in the last 25 days (Point D) in a rising market.',
                        'Additionally, we need a momentum value (Point B), vertically aligned with Point A, which is lower than the momentum value in the last 25 days (Point C).',
                        'The next step is to find the lowest point of the momentum line between Point B and Point C and draw a horizontal line to the right.',
                        'Once the momentum line closes lower than this horizontal line, we can take an entry at the low - 1 tick of the candle.'
                    ]},
            
                    {type: 'paragraph', text: 'A special case with divergences are hidden divergences. These occur when, for example, the market does not make a new high, but the indicator shows a new high. This can also indicate a possible trend reversal.'},
                    {type: 'list', items: [
                        {type: 'strong', text: 'Hidden Divergence Example:' },
                        'The market forms a double top, while the momentum indicator shows a new high.',
                        'The rules for entry and stop-loss remain the same as for normal divergences.',
                    ]},
                ]
            },
            {
                content: [
                    {type: 'paragraph', text: 'Watch the video to learn more about the patterns and how to apply them in practice.'},
                ]
            }
    ]
}

export default Pattern;

    /*{
        content: [
            {type: 'headline', text: 'Zick Zack Pattern'},
            {type: 'paragraph', text: 'Hier ist ein Absatztext für das Zick Zack Pattern'},
            {type: 'list', items: [
                'Kurzfristig A', 
                {type: 'strong', text: 'Mittelfristig B'}, // Fett gedruckt
                'Mittelfristig B', 
                'Langfristig C'
            ] }
            {type: 'download', text: 'Einstellungen für die Wochenanalyse', href: '1CourseWeeklyPictures/COTSettingsWeekly.png'}
        ]
    }*/

