
const WeeklyAnalysisSummary = {
    headers : [
            'Weekly Analysis - Summary',
        ],
    
        titles : [
            'Weekly Analysis in Excel',
            'Barchart',
            'Weekly Analysis - Example',
            'Weekly Watchlist',
            'Homework'
        ],
    
        media : [
            {type: 'image', src: '1CourseWeeklyMedien/weekly_analysis_table.png', alt: 'Excel Table'},
            {type: 'image', src: '1CourseWeeklyMedien/barchart.png', alt: 'Barchart', caption: '@ Barchart.com'},
            {type: 'image', src: '1CourseWeeklyMedien/cotAuswertung.png', alt: 'Evaluation Example', caption: 'Chart provided by TradingView'},
            {type: 'image', src: '1CourseWeeklyMedien/weeklyWatchlist.png', alt: 'Watchlist'},
            {type: 'video', src: 'videos/weeklyHomework.mp4', alt: 'Homework'},
        ],
    
        texts : [
            {
                content: [
                    {type: 'paragraph', text: 'In the evaluation of a market, a total of 5 criteria are considered. Work through the criteria one by one and evaluate the market:'},
                    {type: 'list', items: [
                        'Trend according to MA52 / Support and Resistance Levels / Short-, Medium-, Long-term Highs and Lows', 
                        'COT Extreme', 
                        'Bubble', 
                        'Sentiment', 
                        'Seasonals / Cycles'
                    ]},
                    {type: 'paragraph', text: 'Record the evaluation of the criteria in an Excel table. Note the ABCD value, the creation date of the COT signal, and the validity of the signal, and if applicable, whether it is a contra signal (super trend).'},
                    {type: 'paragraph', text: 'Beginners should only trade markets with at least 3 out of 5 criteria. Advanced traders can also trade or put a market on the watchlist with at least 2 out of 5 criteria.'},
                    {type: 'paragraph', text: 'The evaluation is done in an Excel table that summarizes the criteria in a table. This evaluation must be done weekly after the publication of the COT data on Saturday or Sunday.'},
                    {type: 'paragraph', text: 'To ensure that the new data has been published, you can set up an email notification on the CFTC website.'},
                    {type: 'download', text: 'CFTC Email Subscription', href: 'https://service.govdelivery.com/accounts/USCFTC/subscriber/new?topic_id=USCFTC_25'},
                ]
            },
            {
                content: [
                    {type: 'paragraph', text: 'The market analysis is generally conducted in the normal future contract. The markets are documented in the Excel table.'},
                    {type: 'paragraph', text: 'Since normal future contracts are usually very expensive, you can also switch to mini-future contracts. These are significantly cheaper and have a lower leverage.'},
                    {type: 'list', items: [
                        'To trade micro and mini-future contracts, it is important that there is enough open interest, i.e., enough trading interest or volume in the market.',
                        'Too little OI can lead to large gaps between the closing price and the opening price. This can negatively affect our order management or the orders may not be executed.',
                        'Note that the symbols shortcut can have different notations (Tradingview, Barchart, Broker).',
                        'Micro E-Mini S&P500: Barchart: ETH24, Tradingview: MES',
                        'Always make sure you choose the right contract with your broker!'
                    ]}
                ]
            },
            {
                content: [
                    {type: 'paragraph', text: 'The last signal here is a sell signal. Once a signal occurs, we analyze the market and decide whether to trade the market or not.'},
                    {type: 'list', items: [
                        'We examine the market for support and resistance levels, short-, medium-, and long-term highs and lows, or whether the Moving Average 52 is falling, rising, or moving sideways.',
                        'If there was the same COT signal at the same level in the last 3 years and it has not yet been broken by the price, we can assume a support or resistance level. Basically, these levels can also be drawn without a COT signal, but if there was a COT signal at the level, then it is a stronger level.',
                        'If the price is below the MA52 and the MA52 is falling, then the market is bearish. The same applies to a price above the MA52 and the MA52 is rising. If the MA52 is flat, the market is probably in a range.',
                        'Next, we check whether the market is rising through higher highs and lows or falling through lower highs and lows and where short-, medium-, and long-term highs and lows are located. Since these are weekly candles in this case, this must also be checked and weighted again in the daily chart.',
                        'Finally, it is also necessary to check whether the market is in a trend or in a range.',
                        'Now we can evaluate the market using the ABCD method.'
                    ]},
                    {type: 'paragraph', text: 'The market is in a long-term downtrend. Only an intermediate low has been formed, but no new intermediate high yet. The signal candle is at a resistance level with a previous sell signal at the same height. The MA 52 is flattening. The trend is in the direction of the sell signal.'},
                    {type: 'list', items: [
                        'Signal = Short',
                        'ABCD = A',
                        'Contra = No',
                        'Validity = until July 6, 2024',
                        'Trend S/R = Yes',
                        'Extreme = No',
                        'Bubble = Yes',
                        'Sentiment = No',
                        'Seasonals = Yes'
                    ]}
                ]
            },
            {
                content: [
                    {type: 'paragraph', text: 'Conclusion: Is the market interesting for our watchlist?'},
                    {type: 'paragraph', text: 'After all markets have been analyzed, we select a maximum of 10 markets (beginners a maximum of 5 markets) and put them on our watchlist. These markets are then analyzed daily after the market closes in the daily chart and possibly traded.'},
                    {type: 'paragraph', text: 'Initially, it may seem difficult to choose the right markets. However, it is important that you observe the selected markets daily in the daily chart in the following week and look for trading opportunities. Focus exclusively on the markets on your watchlist and do not add new markets that suddenly perform better than the originally chosen ones. You have taken enough time over the weekend to make a well-founded and rational decision. A spontaneous change of markets is often not advantageous, as these decisions are often influenced by emotional impulses and in most cases lead to losses.'}
                ]
                
            },
            {
                content: [
                    {type: 'paragraph', text: 'Analyze the Cocoa market from January 2019 - January 2024 with Tradingview and enter all COT trading signals and the evaluation of the signals in the Excel table.'},
                    {type: 'download', text: 'EXCEL Weekly Analysis Template', href: 'https://firebasestorage.googleapis.com/v0/b/courseapp-e7938.appspot.com/o/1CourseWeeklyMedien%2FCocoaWeeklyAnalysisTemplate.xlsx?alt=media&token=11e60345-1f6b-4ee4-a84c-8038c61c9925'},
                    {type: 'download', text: 'EXCEL Weekly Analysis Solution', href: 'https://firebasestorage.googleapis.com/v0/b/courseapp-e7938.appspot.com/o/1CourseWeeklyMedien%2FCocoaWeeklyAnalysisSolution.xlsx?alt=media&token=236f306d-8ede-4464-abc5-6480a1d1dd1e'},
                    {type: 'download', text: 'Trade Algorithm PDF', href: 'https://firebasestorage.googleapis.com/v0/b/courseapp-e7938.appspot.com/o/3CourseAdditionalMedien%2FTrade%20Algorithm.pdf?alt=media&token=edff7667-c939-4818-b5b9-dd279c4358b5'},
                    {type: 'download', text: 'Weekly Watchlist', href: 'https://firebasestorage.googleapis.com/v0/b/courseapp-e7938.appspot.com/o/1CourseWeeklyMedien%2FWeeklyWatchlist.xlsx?alt=media&token=fac91ae8-418c-43c2-b349-82d666b9c23e'},
                ]
            }
        ]
}

export default WeeklyAnalysisSummary;
