
const CotBubble = {
    headers : [
        'COT-Open Interest Bubble',
    ],


    titles : [
        'COT - OI Bubble',
        'COT - OI Bubble with Commercials',
        'COT - OI Bubble with Large Speculators',
        'COT - Bubble Video'

    ],

    media : [
        {type: 'image', src: '1CourseWeeklyMedien/bubble.png', alt: 'COT - OI Bubble', caption: 'Chart provided by TradingView'},
        {type: 'image', src: '1CourseWeeklyMedien/bubble-com.png', alt: 'COT - OI Bubble Commercials', caption: 'Chart provided by TradingView'},
        {type: 'image', src: '1CourseWeeklyMedien/bubble-largespec.png', alt: 'COT - OI Bubble Large Speculators', caption: 'Chart provided by TradingView'},
        {type: 'video', src: 'videos/COTBubble.mp4', alt: 'COT - Bubble Video', caption: 'Chart provided by TradingView'},




        /*{type: 'image', src: 'pics/weeklyCotAnalysis/abcd-market.png', alt: '18 MA Pattern'},
        {type: 'video', src: 'videos/CotDataCotIndexAnalysis.mp4', alt: 'Zick Zack Trendanalyse'},
        {type: 'gif', src: 'path/to/your-gif.gif', alt: 'Gewinnen'}, // Direkter Link zum GIF-Bild
        // weitere Medieneinträge...*/
    ],


    texts : [
        {
            content: [
                {type: 'paragraph', text: 'When the Open Interest and the Commercials move towards or away from each other, it indicates a bubble, which can mean that the market is either overbought or oversold.'},
                {type: 'list', items: ['A bubble is identified by drawing a line from the latest COT signal to the previous contrarian COT signal on the Commercials net line, from point A (new signal) to point B (contrarian last counter signal). The same approach is also applied to the Open Interest.'] },
                {type: 'list', items: ['If the resulting lines resemble a pair of scissors, it indicates the presence of a bubble.'] },
                {type: 'list', items: ['If the lines run parallel or do not intersect, it indicates that there is no bubble.'] }
            ]
        },
        {
            content: [
                {type: 'paragraph', text: 'In the image, you can see an example of a COT Open Interest Bubble analysis.'},
                {type: 'list', items: ['In the first example, you can see that the lines move towards the right and form a bubble.']},
                {type: 'list', items: ['In the second example, however, you can see that the Commercials and the Open Interest move apart and form a bubble.'] },
                {type: 'list', items: ['In the last example, there is no bubble as the lines run parallel to each other.'] },
            ]
        },
        {
            content: [
                {type: 'paragraph', text: 'If the net positions of the Commercials have been above the 0-line for 3 months, we use the Large Speculators to identify the bubble.'},
                {type: 'list', items: ['In this example, the Commercials have been above the 0-line for over 3 months and the Large Speculators have been below the 0-line for 3 months.']},
                {type: 'list', items: ['In the image, you can see that from the latest COT Commercials sell signal to the last opposite buy signal, points A and B were drawn once for the Large Speculators and once for the Open Interest to look for a bubble.'] },
                {type: 'list', items: ['In this example, there would be no bubble if the Commercials were compared with the Open Interest.'] },
            ]
        },
        {
            content: [
                {type: 'paragraph', text: 'In the video, you will learn how to identify a bubble using the COT Open Interest Bubble.'},
                {type: 'paragraph', text: 'The video will show you how to draw the lines and what to look for when identifying a bubble.'},
            ]
        }

        
        /*{
            content: [
                {type: 'paragraph', text: 'Hier ist ein Absatztext für das Zick Zack Pattern'},
                {type: 'list', items: ['Kurzfristig A', 'Mittelfristig B', 'Langfristig C'] }
            ]
        }*/
    ]
};

export default CotBubble;

