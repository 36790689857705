import React from 'react';

import Welcome from './Welcome';
import Slogan from './Slogan';
import Grid from './Grid';
import CourseTarget from './CourseTarget';
import Buy from './Buy';
import IndieProduct from './IndieProduct';
import '../css/standard.css';
import Footer from './Footer';
import Indicator from '../pages/Indicator';

function AppLanding() {
  return (
    <>
      <Welcome />
      <Slogan />
      <Grid />
      <CourseTarget />
      <Buy />
      <IndieProduct />
      <Indicator />
      <Footer />
    </>
  );
}

export default AppLanding;
