const ContractSpreads = {
    headers : [
        'Futures Contract Spreads',
    ], 

    titles : [
        'What is a Spread?',
        'Barchart Contract Comparison',
        'Spread Divergence Analysis', 
    ],

    media : [
        {type: 'image', src: '3CourseAdditionalMedien/Spreadeinstellung.png', alt: 'Spread Indicator', caption: 'Chart provided by TradingView'},
        {type: 'image', src: '3CourseAdditionalMedien/barchartSpread.png', alt: 'Barchart Spread', caption: '@ Barchart.com'},
        {type: 'image', src: '3CourseAdditionalMedien/SpreadDivergence.png', alt: 'Spread Divergence', caption: 'Chart provided by TradingView'},
    ],

    texts : [
        {
            content: [
                {type: 'paragraph', text: 'A spread is the difference between the buying and selling price of a financial instrument. In futures trading, the spread refers to the difference between the buying and selling price of a futures contract. The spread can serve as an indicator of market liquidity and volatility.'},
                {type: 'paragraph', text: 'In futures, there are different monthly contracts that have different prices. If the current month contract is more expensive than the next month contract, it is called contango. If the current month contract is cheaper than the next month contract, it is called backwardation.'},
                {type: 'paragraph', text: 'Why is a contango market more expensive than a backwardation market? The reason is that the current month contract is more expensive because the costs for storage and transportation of the underlying asset are higher than the costs for storage and transportation of the next month contract. This leads to the current month contract being more expensive than the next month contract.'},
                {type: 'paragraph', text: 'On Barchart, look for the two contracts with the highest open interest and use these monthly contracts for the spread indicator. The earlier contract is entered as the first value and the following contract as the second value.'},
                {type: 'download', text: 'Barchart', href: 'https://www.barchart.com/futures'},
                {type: 'download', text: 'Spread Indicator', href: 'https://de.tradingview.com/script/aFGQiVTm/'},
            ],
        },
        {
            content: [
                {type: 'paragraph', text: 'On the Barchart website, you can find the different monthly contracts for a specific future. Look for the future you want to analyze and click on the link to display the different monthly contracts.'},
                {type: 'paragraph', text: 'Look for the highest open interest for the next two monthly contracts and use these values for the spread indicator. In the image, this would be the March 2024 and May 2024 contracts.'},
                {type: 'paragraph', text: 'Pay special attention to the open interest in mini and micro futures, as these contracts are often less liquid and open interest plays an important role. If there is too little liquidity in one of these contracts, large price gaps can occur. Therefore, only choose contracts with at least 2000 open interest and additionally compare the gaps in the price chart with the corresponding normal future contract. If the gaps show too much difference, do not trade this mini or micro future.'},
            ],
        },
        {
            content: [
                {type: 'paragraph', text: 'In our spread indicator, this difference between the next two contracts with the highest open interest is calculated. Divergences for overbought and oversold markets can be identified. If the price continues to rise while the spread decreases, this is an indicator of a possible trend reversal. If the price continues to fall while the spread increases, this is an indicator of a possible trend reversal.'},
                {type: 'paragraph', text: 'Larry Williams also talks about the premium in the spread. If the spread is greater than 0, it is called a premium market, which means that the market is more bullish.'},
                {type: 'paragraph', text: 'The analysis is conducted on the daily chart!'},
            ],
        },
    ]
};

export default ContractSpreads;