const Tradingplan = {
    headers: [
        'Trading Plan & Journal',
    ],

    titles: [
        'Weekly and Daily Analysis',
        'Evaluation Criteria for Weekly Analysis',
        'Evaluation Criteria for Daily Analysis',
        'Trading Journal',
    ],

    media: [
        {type: 'image', src: '3CourseAdditionalMedien/strategyplanung.jpg', alt: 'Trading Plan'},
        {type: 'image', src: '3CourseAdditionalMedien/weeklyReport.jpg', alt: 'Weekly Report'},
        {type: 'image', src: '3CourseAdditionalMedien/analysis.jpg', alt: 'Daily Analysis'},
        {type: 'image', src: '3CourseAdditionalMedien/algo.jpg', alt: 'Trading Algorithm'},
    ],

    texts: [
        {
            content: [
                {type: 'paragraph', text: 'A trading journal is crucial for a trader\'s success. It allows for systematic documentation and evaluation of all trading activities. By keeping a trading journal, you can leverage the following benefits:'},
                {type: 'list', items: [
                    'Performance Evaluation: Track and evaluate your profits and losses over a specific period.',
                    'Error Analysis: Identify mistakes or recurring patterns in your trading and understand what went wrong.',
                    'Strategy Improvement: Optimize and adjust your trading strategies based on the recorded data.',
                    'Risk Management: Review how well you control your risk and whether you are risking too much capital on individual trades.',
                    'Emotional Control: Monitor your emotional reactions to wins and losses and work on trading more disciplined.',
                    'Long-term Learning: Learn from past experiences and continuously improve your trading skills and knowledge.',
                    'Compliance and Taxes: A trading journal can also help with tax compliance and record-keeping.',
                    'Self-confidence and Self-esteem: A trading journal can boost your confidence in your trading decisions by allowing you to reflect on your experiences and successes.',
                    'Accountability: A detailed journal helps you track all your trading activities and can be useful in case of discrepancies or questions.',
                ]},
                {type: 'paragraph', text: 'In summary, a trading journal helps make better-informed decisions, optimize performance, and continuously work on your skills. It is a valuable tool to enhance the trading experience and achieve long-term success.'},
            ]
        },
        {
            content: [
                {type: 'paragraph', text: 'Weekly Analysis'},
                {type: 'list', items: [
                    'COT-26 Week Buy Signal Date and valid until... (Signal + 25 days)',
                    'Trend Determination (ABCD Market) with 52MA and Market Technique (Short-term, MT, LT)',
                    'COT Extreme (e.g., extreme position within the last 3 years)',
                    'Bubble (Check for Open Interest COT Position Bubble)',
                    'Sentiment (Positions of small investors compared to large investors)',
                    'Seasonal and/or cyclical trends (e.g., trend in the direction of the signal)',
                ]},
                {type: 'paragraph', text: 'Conclusion: If 4 out of 5 points confirm the COT signal, the signal quality meets the rules of at least 3 out of 5 points. Advanced traders could achieve 2 out of 5 points.'},
            ]
        },
        {
            content: [
                {type: 'paragraph', text: 'Daily Analysis'},
                {type: 'list', items: [
                    'Take a screenshot of your trade and save it in your journal file.',
                    'Divergences (POIV, PROFF, MOM28)',
                    'Spread (Comparison of the nearest contracts with the highest OI)',
                    'Trend Structure (ST, MT, LT), 18MA, ADMA, Support & Resistance Areas',
                    'Price Patterns (Impulse Patterns, Correction Patterns)',
                    'Risk Management: Entry Price, Stop-Loss Price, Profit Target, Calculate the number of contracts according to personal risk, Check for correlation',
                    'Trade Management: Trailing Stop Technique (3Bar, Market Technique)',
                    'Exit: How was the trade closed? (Profit/Loss)',
                    'Trade Notes: Thoughts, psychological barriers, feelings, holding period, mistakes, ...',
                ]},
                {type: 'paragraph', text: 'Evaluate all your trading journals once a year to understand your performance and find out how you can improve to become a better trader.'},
            ]
        },
        {
            content: [
                {type: 'paragraph', text: 'Evaluation Criteria'},
                {type: 'list', items: [
                    'COT Signal & Entry Pattern: Confirm if a COT signal is present and if the entry pattern meets predefined standards.',
                    'First Stop: Ensure the first stop is set according to established guidelines.',
                    'Risk Management: Assess full risk for A and B markets; half risk for C markets. Check if contract quantities are correctly calculated.',
                    'Profit Target & RRR: Review profit targets in relation to the RRR (Risk-Reward Ratio), considering support and resistance levels. Beginners should aim for an RRR of 1:1 for impulse trades and 1.5:1 or 2:1 for correction trades.',
                    'Market Phase Analysis: Avoid entry during sideways movements in the daily chart. Look for signs of divergence or trend changes.',
                    'Type of Entry: For correction trades, confirm that a market correction is present, including inside and outside bar patterns. Avoid late impulse trades.',
                    'Consideration of Relative Market Strength: Evaluate relative market strength. Avoid trading in the weakest market just to align with RMM. In case of extreme market weakness, prefer to stay inactive.',
                ]},
                {type: 'download', text: 'Trading Journal', href: 'https://firebasestorage.googleapis.com/v0/b/courseapp-e7938.appspot.com/o/3CourseAdditionalMedien%2FTradingJournalTemplate.docx?alt=media&token=5aefbfff-e5a7-4e3b-94ac-e2008aa5800b'},
                {type: 'download', text: 'Trading Algorithm', href: 'https://firebasestorage.googleapis.com/v0/b/courseapp-e7938.appspot.com/o/3CourseAdditionalMedien%2FTrade%20Algorithm.pdf?alt=media&token=edff7667-c939-4818-b5b9-dd279c4358b5'},
            ]
        },
    ]
};

export default Tradingplan;