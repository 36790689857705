import React, { useState, useEffect, useCallback } from 'react';
import '../css/calculator.css';
import symbols from '../js/Symbols'; 

function Calculator() {
  const [market, setMarket] = useState('');
  const [direction, setDirection] = useState('Long');
  const [capital, setCapital] = useState('');
  const [riskPct, setRiskPct] = useState(1.5);
  const [entry, setEntry] = useState('');
  const [stoploss, setStoploss] = useState('');
  const [target, setTarget] = useState('');
  const [result, setResult] = useState(null);
  const [size, setSize] = useState('');
  const [tickValue, setTickValue] = useState('');
  const [pointValue, setPointValue] = useState('');
  const [perContract, setPerContract] = useState('');
  const [error, setError] = useState('');

  const updateSymbolInfo = useCallback(() => {
    if (symbols.hasOwnProperty(market)) {
      setSize(symbols[market].size);
      setTickValue(symbols[market].tick_value);
      setPointValue(symbols[market].point_value);
      setPerContract(symbols[market].per_contract);
    } else {
      setSize('');
      setTickValue('');
      setPointValue('');
      setPerContract('');
    }
  }, [market]);

	useEffect(() => {
		updateSymbolInfo();
	}, [market, updateSymbolInfo]);

	const calculator = (market, direction, capital, risk_pct, entry, stoploss, target, contracts = 1) => {
	  market = market.toUpperCase();
	
	  if (!symbols.hasOwnProperty(market)) {
		return "Symbol not supported";
	  }
	
	  const market_info = symbols[market];
	  const size = market_info.size;
	  const tick_value = market_info.tick_value;
	
	  let risk_dollar, win, risk_total, target_total, ticks, contract;
	
	  if (direction.toUpperCase() === "LONG") {
		risk_dollar = stoploss - entry; // Risiko negativ
		win = target - entry; // Gewinn positiv
	  } else if (direction.toUpperCase() === "SHORT") {
		risk_dollar = entry - stoploss; // Risiko negativ
		win = entry - target; // Gewinn positiv
	  } else {
		return "Invalid direction";
	  }
	
	  // Berechnung der Ticks
	  ticks = risk_dollar / tick_value;
	
	  // Berechnung des Gesamtrisikos und des Zielwerts
	  risk_total = ticks * tick_value * size * contracts;
	  target_total = (win / tick_value) * tick_value * size * contracts;
	
	  // Berechnung des Prozentsatzes des Kapitals und der Kontrakte
	  const pct_of_capital = (capital / 100) * risk_pct;
	  contract = pct_of_capital / Math.abs(risk_total); // Kontrakte immer positiv
	
	  return [risk_total, target_total, ticks, risk_dollar, risk_pct, contract];
	};

	const calculate = () => {
		if (!market) {
			setError('Bitte wählen Sie ein Marktsymbol aus.');
			return;
		}
		setError(''); // Fehler zurücksetzen, wenn ein Symbol ausgewählt ist
		const result = calculator(market, direction, parseFloat(capital), parseFloat(riskPct), parseFloat(entry), parseFloat(stoploss), parseFloat(target));
		setResult(result);
	};

  const handleInputChange = (setter) => (e) => {
    const value = e.target.value.replace(',', '.');
    setter(value);
  };

	return (
		<div id="calculator">
			<h1>Futures Risk Calculator</h1>
			<div className="form-results-container">
				<div className="input-group">
					<div className="input-item">
						<label htmlFor="symbol">Symbol:</label>
						<select id="symbol" className="input-field-calc" value={market} onChange={(e) => setMarket(e.target.value)}>
							<option value="">Select Symbol</option>
							{Object.keys(symbols).map((symbol) => (
								<option key={symbol} value={symbol}>{symbol}</option>
							))}
						</select>
					</div>

					<div className="input-item">
						<label htmlFor="direction">Direction:</label>
						<select id="direction" className="input-field-calc" value={direction} onChange={(e) => setDirection(e.target.value)}>
							<option value="Long">Long</option>
							<option value="Short">Short</option>
						</select>
					</div>

					<div className="input-item">
						<label htmlFor="capital">Capital:</label>
						<input type="text" id="capital" className="input-field-calc" value={capital} onChange={handleInputChange(setCapital)} style={{ width: '70%' }} />
					</div>

					<div className="input-item">
						<label htmlFor="risk">Risk %:</label>
						<input type="number" id="risk" className="input-field-calc" step="0.1" value={riskPct} min="0.1" max="10" onChange={handleInputChange(setRiskPct)} style={{ width: '70%' }} />
					</div>

					<div className="input-item">
						<label htmlFor="entry">Entry:</label>
						<input type="text" id="entry" className="input-field-calc" value={entry} onChange={handleInputChange(setEntry)} style={{ width: '70%' }} />
					</div>

					<div className="input-item">
						<label htmlFor="stoploss">Stoploss:</label>
						<input type="text" id="stoploss" className="input-field-calc" value={stoploss} onChange={handleInputChange(setStoploss)} style={{ width: '70%' }} />
					</div>

					<div className="input-item">
						<label htmlFor="target">Target:</label>
						<input type="text" id="target" className="input-field-calc" value={target} onChange={handleInputChange(setTarget)} style={{ width: '70%' }} />
					</div>

					<button onClick={calculate}>Calculate</button>
				</div>

				{error && <div className="error-message">{error}</div>} {/* Fehlermeldung anzeigen */}

				<div className="results-container">
					<div id="futureInfo">
						<div className="info-item">
							<label htmlFor="name">Name:</label>
							<span id="name"> {market && symbols[market]?.name} </span>
						</div>
						<div className="info-item">
							<label htmlFor="size">Contract Size $:</label>
							<span id="size"> {size} </span>
						</div>
						<div className="info-item">
							<label htmlFor="pointValue">Point Value $:</label>
							<span id="pointValue"> {pointValue} </span>
						</div>
						<div className="info-item">
							<label htmlFor="tickValue">Tick Value:</label>
							<span id="tickValue"> {tickValue} </span>
						</div>
						<div className="info-item">
							<label htmlFor="perContract">Per Tick $:</label>
							<span id="perContract"> {perContract} </span>
						</div>
					</div>
					<div className='or-line'><p>Result</p></div>
					<div id="result">
						{result && (
							<>
								<div className="result-item">Price Risk $: <span className="result-value">{result[3].toFixed(2)}</span></div>
								<div className="result-item">Calculated Risk: <span className="result-value">{result[0].toFixed(2)} $</span></div>
								<div className="result-item">Risk Ticks: <span className="result-value">{result[2].toFixed(2)}</span></div>
								<div className="result-item">Trade Win: <span className="result-value">{result[1].toFixed(2)} $</span></div>
								<div className="result-item">Tradeable Contracts: <span className="result-value">{result[5].toFixed(2)}</span></div>
							</>
						)}
					</div>
				</div>
			</div>
			<div className='or-line'><p id="disclaimer" style={{fontSize: '0.8rem', fontWeight: "400"}}>Disclaimer: This calculator is for educational purposes only. The results are not financial advice.</p></div>
		</div>
	);
}

export default Calculator;