import React, { useEffect } from 'react';
import '../css/standard.css'; // Import the CSS file
import RiskDisclosure from '../pages/RiskDisclosure';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import Impressum from '../pages/Impressum';
import Disclaimer from '../pages/Disclaimer';
import Cancellation from '../pages/CancellationPolicy';
import TermsConditions from '../pages/TermsConditions';

function Footer() {
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    // Add event listeners for the rollout elements
    const rollouts = document.querySelectorAll('.rollout');
    const overlay = document.getElementById('overlay');
    const overlayText = document.getElementById('overlay-text');
    const closeButton = document.getElementById('close-button-footer');

    rollouts.forEach(rollout => {
      rollout.addEventListener('click', () => {
        const nextElement = rollout.nextElementSibling;
        if (nextElement.classList.contains('rollout-text')) {
          overlayText.innerHTML = nextElement.innerHTML;
          overlay.style.display = 'flex';
        }
      });
    });

    closeButton.addEventListener('click', () => {
      overlay.style.display = 'none';
    });

    return () => {
      // Cleanup event listeners
      rollouts.forEach(rollout => {
        rollout.removeEventListener('click', () => {});
      });
      closeButton.removeEventListener('click', () => {});
    };
  }, []);

  return (
    <div id="footer-container" className="sticky-footer">
      <div id="overlay" className="overlay">
        <div className="overlay-content">
          <span id="close-button-footer" className="close-button-footer">X</span>
          <p id="overlay-text"></p>
        </div>
      </div>
      <div className="standard-css-footer">
        <p className='rollout'>&copy; <span id="year">{currentYear}</span> by COT Season</p>
        <p className="rollout">Privacy Policy</p>
        <div className="rollout-text">
          <PrivacyPolicy />
        </div>
        <p className="rollout">Terms and Conditions</p>
        <div className="rollout-text">
          <TermsConditions />
        </div>
        <p className="rollout">Cancellation Policy</p>
        <div className="rollout-text">
          <Cancellation />
        </div>
        <p className="rollout">Impressum</p>
        <div className="rollout-text">
          <Impressum />
        </div>
        <p className="rollout">Disclaimer</p>
        <div className="rollout-text">
          <Disclaimer />
        </div>
        <div className="rollout-text">
          <p>COT Season - Germany</p>
        </div>
        <p className="rollout">Risk Disclosure</p>
        <div className="rollout-text">
          <RiskDisclosure />
        </div>
      </div>
    </div>
  );
}

export default Footer;