import React from 'react';

export default function PrivacyPolicy() {
  return (
    <div style={{marginTop: "120px", backgroundColor: "white"}}>
      <iframe 
        src="https://itrk.legal/Uv4.96.OsW-iframe.html" 
        width="100%" 
        height="1000px"  // Feste Höhe, die groß genug ist, um den gesamten Inhalt anzuzeigen
        style={{border: "none", marginTop: "20px"}}
        title="Privacy Policy 2"
      ></iframe>
      <hr />
      <iframe 
        src="https://itrk.legal/Uv4.bg.OsW-iframe.html" 
        width="100%" 
        height="1000px"  // Feste Höhe, die groß genug ist, um den gesamten Inhalt anzuzeigen
        style={{border: "none"}}
        title="Privacy Policy 1"
      ></iframe>
    </div>
  );
}