import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

function IndieProduct() {
  const navigate = useNavigate();
  const videoRef = useRef(null);

  const navigateProduct = () => {
    navigate('/checkout');
  };

  const openEmailClient = () => {
    window.location.href = "mailto:salamontraderinfo@gmail.com";
  };

  const handleVideoClick = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };


  return (
    <>
      <div className="indie-product-container">
        {/* Section 1: Video and Text */}
        <div className="indie-product-section first-section">
          <div className="video-container">
            <video ref={videoRef} onClick={handleVideoClick} onEnded={() => videoRef.current.pause()}>
              <source src='../../Medien/videos/werbevideo.mp4' type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="indie-product-text">
            <h2>Maximize Your Trading Success with Our Professional Trading Course and Exclusive Indicator Packages!</h2>
            <hr />
            <h4>Your Path to Consistent Profits: The Comprehensive Trading Course</h4>
            <p>
              Dive into the world of successful trading with our highly professional <strong>Trading Course</strong>, which offers a complete strategy based on COT data (Commitments of Traders). This course is your key to sustainable success in the financial markets and covers all essential aspects:
            </p>
            <ul>
              <li><strong>Fundamental Analysis:</strong> Understand the economic forces that drive the markets.</li>
              <li><strong>Trade Setups:</strong> Learn precise entry and exit strategies.</li>
              <li><strong>Trading Journal:</strong> Keep a systematic journal to analyze and optimize your trades.</li>
              <li><strong>And many more important details:</strong> Everything you need to take your trading skills to the next level.</li>
            </ul>
            <p><strong>75% Success Rate</strong> – Our proven strategy has been highly effective and provides you with the tools to regularly place profitable trades.</p>
          </div>
        </div>
        <div className="indie-product-text">
          <h2>Exclusive Indicator Packages for Precise Trading</h2>
          <h3>Complement your trading course with our specialized <strong>Indicator Packages</strong>, specifically designed to support and optimize your trading decisions:</h3>
        </div>
        {/* Section 2: Text and Image */}
        <div className="indie-product-section">
          <div className="indie-product-text left-product-section">
          <h4>COT Trend Filter + Signals (Indicator for the Weekly Chart)</h4>
            <ul>
              <li><strong>Signals on Weekly Charts:</strong> Harness the power of COT data with the <strong>COT Trend Filter + Signals</strong>, which generates strong trading signals on weekly charts.</li>
              <li><strong>Advanced COT Analysis:</strong> The Cycle COT measures the strength of COT data and generates cleaner signals than the normal COT index.</li>
              <ul>
                <li>Cycle COT RSI + Signals</li>
                <li>Cycle COT Momentum + Signals</li>
                <li>COT Index Normal + Signals</li>
              </ul>
            </ul>
            <p>Ideal for precise COT analysis and identifying overbought or oversold market conditions using COT data from large and commercial players as well as small speculators, utilizing the cycle, momentum, and normal indices.</p>
          </div>
          <div className="indie-product-image">
            <img src='../../Medien/index/product1.png' alt="Indicator Package" />
          </div>
        </div>

        {/* Section 3: Image and Text */}
        <div className="indie-product-section">
          <div className="indie-product-image">
            <img src='../../Medien/index/product2.png' alt="Indicator Package" />
          </div>
          <div className="indie-product-text right-product-section">
            <h4>Trend Filter + Pattern Recognition</h4>
            <ul>
              <li><strong>Trend Filter Chart + Pattern 1:</strong> Identifies long-term market trends for informed trading decisions.</li>
              <li><strong>Trend Filter MTF-C:</strong> Multi-Timeframe Cycles Accumulation Distribution for analyzing the 4 hours timeframe in daily charts.</li>
              <li><strong>Trend Filter AD1 & AD2:</strong> Advanced trend filters with Accumulation Distribution for precise trend analysis.</li>
            </ul>
            <p>Ideal for swing traders looking for precise entry points.</p>
          </div>
        </div>

        {/* Last Section: Text Only */}
        <div className="indie-product-section last-section">
          <div className="indie-product-text">
            <h2>Why the Trading Course is the Best Choice</h2>
            <p>
              While our indicator packages already provide powerful tools for your trading, purchasing our <strong>Trading Course</strong> is the optimal choice. The course explains in detail how to apply and integrate these indicators, allowing you to fully leverage their potential. You will not only receive the tools but also the knowledge to effectively incorporate them into your trading strategy.
            </p>
            <hr />
            <h3>Flexible and Risk-Free Usage</h3>
            <p>
              Our indicator packages can be <strong style={{color: "#6a9bdd", background: "87a5c137"}} onClick={navigateProduct} className="landingClickElement">subscribed to monthly</strong>, and you have the option to use a <strong style={{color: "#6a9bdd"}} onClick={openEmailClient} className="landingClickElement">two-week trial period</strong> upon registration. This allows you to find out risk-free how our tools can help you achieve your trading goals. Simply send us your Tradingview username via email or private message in Tradingview. We will then activate the trial period for you.
            </p>
            <hr />
            <h3>Conclusion: Start Your Journey to Becoming a Professional Trader Today!</h3>
            <p>
              Take advantage of our tailored trading solutions to maximize your profits and elevate your trading to the next level. <strong style={{color: "#6a9bdd"}} onClick={navigateProduct} className="landingClickElement">Sign up today</strong> and benefit from our comprehensive trading course and exclusive indicator packages. Your success is just a click away!
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default IndieProduct;