import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, updateProfile, deleteUser } from 'firebase/auth';
import { toast } from 'react-toastify';
import { doc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import '../css/standard.css';
import '../css/formular.css';
import SubscriptionStatus from '../hooks/subscriptionStatus';
import Footer from '../landingComponents/Footer';

export default function Profile() {
  const auth = getAuth();
  const navigate = useNavigate();
  const [changeDetail, setChangeDetail] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    firstName: '',
    lastName: '',
    tradingviewName: '',
    agb: false,
    riskDisclosure: false,
  });

  useEffect(() => {
    async function fetchUserData() {
      const docRef = doc(db, 'users', auth.currentUser.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const userData = docSnap.data();
        setFormData({
          name: userData.name || auth.currentUser.displayName,
          email: userData.email || auth.currentUser.email,
          tradingviewName: userData.tradingviewName || '',
          firstName: userData.firstName || '',
          lastName: userData.lastName || '',
          agb: userData.agb || false,
          riskDisclosure: userData.riskDisclosure || false,
        });
      } else {
        console.log('No such document!');
      }
    }

    fetchUserData();
  }, [auth.currentUser]);

  const { name, email, tradingviewName, firstName, lastName, agb, riskDisclosure } = formData;

  function onLogout() {
    auth.signOut();
    navigate('/home');
  }

  function onClick() {
    if (changeDetail) {
      onSubmit();
    }
    setChangeDetail(!changeDetail);
  }

  function onChange(e) {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
    }));
  }

  async function onSubmit() {
    try {
      if (auth.currentUser.displayName !== name) {
        await updateProfile(auth.currentUser, {
          displayName: name,
        });
      }

      const docRef = doc(db, 'users', auth.currentUser.uid);
      await updateDoc(docRef, {
        name,
        tradingviewName,
        firstName,
        lastName,
        email, // Ensure email is also updated in Firestore
        agb,
        riskDisclosure,
      });

      toast.success('Profile details updated successfully');
    } catch (error) {
      toast.error('Could not update the profile details');
    }
  }

  async function onDeleteAccount() {
    const confirmDelete = window.confirm('Are you sure you want to delete your account? This action cannot be undone.');
    if (confirmDelete) {
      try {
        const docRef = doc(db, 'users', auth.currentUser.uid);
        console.log(docRef);
        const user = auth.currentUser; 
        
        // Delete user document from Firestore
        await deleteDoc(docRef);

        // Delete user from Firebase Authentication
        await deleteUser(user);

        toast.success('Account deleted successfully');
        navigate('/home');
      } catch (error) {
        toast.error('Could not delete the account');
      }
    } else {
      toast.info('Account deletion canceled');
    }
  }

  return (
    <>
      <section className="SignIn-Container" id='profil-content'>
        <h1 className="si-header">My Profile</h1>
        <div className="si-form-container">
          <form className="login-form" onSubmit={(e) => e.preventDefault()}>
            <input
              placeholder='Last Name' 
              type="text"
              id="lastName"
              pattern="[A-Za-z]{3,12}"
              title="Only letters"
              maxLength="25"
              required
              value={lastName}
              disabled={!changeDetail}
              onChange={onChange}
              style={{ backgroundColor: changeDetail ? '#b7c0ca' : 'white' }}
            />
            <input
              placeholder='First Name'
              pattern="[A-Za-z]{3,12}"
              title="Only letters"
              maxLength="25"
              required
              type="text"
              id="firstName"
              value={firstName}
              disabled={!changeDetail}
              onChange={onChange}
              style={{ backgroundColor: changeDetail ? '#b7c0ca' : 'white' }}
            />
            <input
              placeholder='Tradingview Username'
              type="text"
              id="tradingviewName"
              pattern="^[A-Za-z0-9]{3,25}$"
              title="Only letters and numbers, no spaces, and between 3 to 25 characters"
              maxLength='25'
              required
              value={tradingviewName}
              disabled={!changeDetail}
              onChange={onChange}
              style={{ backgroundColor: changeDetail ? '#b7c0ca' : 'white' }}
            />
            <input
              placeholder='Email Address'
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
              maxLength='35'
              type="email"
              required
              id="email"
              value={email}
              disabled={!changeDetail}
              onChange={onChange}
              style={{ backgroundColor: changeDetail ? '#b7c0ca' : 'white' }}
            />
            <div className="profil-checkbox-container"> 
              <label>
                Accept Terms and Conditions
                <input
                  type="checkbox"
                  id="agb"
                  checked={agb}
                  disabled={agb || !changeDetail} // Disable if already accepted
                  onChange={onChange}
                />
              </label>
              <label>
                Accept Risk Disclosure
                <input
                  type="checkbox"
                  id="riskDisclosure"
                  checked={riskDisclosure}
                  disabled={riskDisclosure || !changeDetail} // Disable if already accepted
                  onChange={onChange}
                />
              </label>
            </div>
            <div className="si-forgot" >
              <p>Do you want to change your details?
                <span onClick={onClick}>
                  {changeDetail ? 'Apply changes' : 'Edit'}
                </span>
              </p>
              <p onClick={onLogout}><span>Log Out</span></p>
            </div>

            <SubscriptionStatus />
            <div className='or-line'><p>Delete Account</p></div>
            <button className='delete-account'>
              <p onClick={onDeleteAccount} style={{ color: 'red', cursor: 'pointer' }}>
                <span>Delete Account</span>
              </p>
            </button>
          </form>
        </div>
      </section>
      <Footer />
    </>
  );
}