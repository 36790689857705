import React, { useState } from 'react';
import '../css/standard.css';
import '../css/formular.css';
import { Link } from 'react-router-dom';
import { sendPasswordResetEmail, getAuth } from 'firebase/auth';
import OAuth from '../components/OAuth';
import { toast } from 'react-toastify';



export default function ForgotPassword() {
  const [email, setEmail] = useState('');


  function onChange(e) {
    setEmail(e.target.value);
  }

  async function onSubmit(e) {
    e.preventDefault();

    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
      toast.success('Email was sent');
    } catch (error) {
      toast.error('Could not send reset password');
    }

  }

  return (
    <section className="SignIn-Container" id='profil-content'>
      <h1 className="si-header">Forgot Password</h1>

      <div className="si-sub-container">
        {/*
        <div className="login-image">
          <img src="../../Medien/formular/login.jpg" alt="login" />
        </div>
        */}
        <div className="si-form-container">
          <form className="login-form" onSubmit={onSubmit}>
            <input 
              type="email" 
              id="email" 
              value={email} 
              onChange={onChange}
              placeholder='Email Address'
            />

              
            <div className="si-forgot">
              <p>Don't have a account? 
                <Link to="/sign-up">Sign Up</Link>
              </p>
              <p>
                <Link to="/sign-in">Sign In</Link>
              </p>
            </div>
            <input type="submit" value="Send Reset Password" />
            <OAuth />
          </form>
        </div>
      </div>
    </section>
  );
}