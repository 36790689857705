const RelativeStrengthComparison = {
    headers : [
        'Relative Strength Comparison',
    ], 

    titles : [
        'Relative Strength Comparison of a Sector',
        'Relative Strength Comparison with the RS Percent Return Indicator',
    ],

    media : [
        {type: 'image', src: '3CourseAdditionalMedien/relativeStrengthComparrision.png', alt: 'Relative Strength Comparison of a Sector', caption: 'Chart provided by TradingView'},
        {type: 'image', src: '3CourseAdditionalMedien/relativeStrengthComp.png', alt: 'Relative Strength Comparison of a Sector', caption: 'Chart provided by TradingView'},
    ],

    texts : [
        {
            content: [
                {type: 'paragraph', text: 'In the image on the right, we compare the relative strength in the metal sector.'},
                {type: 'paragraph', text: 'By adding multiple markets to a chart, we can compare the relative strength of the markets with each other. Draw a vertical line from a specific point, usually at a high or low, and a horizontal line to measure the relative strength. You will quickly be able to identify which market is the strongest.'},
                {type: 'paragraph', text: 'For example, if there is a COT buy signal in gold, but silver shows more performance and is trending in the same direction, we could alternatively trade silver instead of gold.'},
            ]
        },
        {
            content: [
                {type: 'paragraph', text: 'With the RS Percent Return Indicator, we can evaluate the relative strength of a market much faster.'},
                {type: 'paragraph', text: 'Simply draw the vertical lines at the point from which you want to measure the market and click through the markets of the same sector. You can view the markets of the sectors on Barchart or FinViz.'},
                {type: 'paragraph', text: 'Note: Weak markets usually remain weak, while strong markets usually remain strong.'},
                {type: 'download', text: 'RS Percent Return Indicator', href: 'https://www.tradingview.com/script/jBM7QwtK-RS-PTR/'},
            ]
        },
    ]
};

export default RelativeStrengthComparison;