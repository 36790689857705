import React, { useState } from 'react';
import '../css/faq.css';
import Footer from '../landingComponents/Footer';

const Faq = () => {
  const [openSections, setOpenSections] = useState({});

  const toggleSection = (section) => {
    setOpenSections((prevSections) => ({
      ...prevSections,
      [section]: !prevSections[section],
    }));
  };

  return (
    <div>
      <div className="faq-container">
        <h1>FAQ</h1>

        <div className="faq-section">
          <h2 onClick={() => toggleSection('section1')} className="faq-header">
            What is the course about? <span className="arrow">{openSections['section1'] ? '▲' : '▼'}</span>
          </h2>
          <p className={openSections['section1'] ? 'open' : ''}>
            Our course is designed to help you become a successful trader. We provide you with all the tools and knowledge you need to make profitable decisions in the market. Whether you are a beginner or an experienced trader, our course will help you take your trading to the next level.
          </p>
        </div>

        <div className="faq-section">
          <h2 onClick={() => toggleSection('section2')} className="faq-header">
            Is the course suitable for beginners and advanced traders? <span className="arrow">{openSections['section2'] ? '▲' : '▼'}</span>
          </h2>
          <p className={openSections['section2'] ? 'open' : ''}>
            Yes, our course is designed for both beginners and experienced traders. We cover the basics for those new to trading and offer advanced strategies for those looking to improve their skills.
          </p>
        </div>

        <div className="faq-section">
          <h2 onClick={() => toggleSection('section3')} className="faq-header">
            What are COT data and why should I use them? <span className="arrow">{openSections['section3'] ? '▲' : '▼'}</span>
          </h2>
          <p className={openSections['section3'] ? 'open' : ''}>
            COT (Commitment of Traders) data provide insights into the positions of various market participants, such as commercial traders, non-commercial traders, and retail traders. By analyzing COT data, you can gain a better understanding of market sentiment and make more informed trading decisions.
          </p>
        </div>

        <div className="faq-section">
          <h2 onClick={() => toggleSection('section4')} className="faq-header">
            Do I get the indicators with the course or do I have to buy them separately? <span className="arrow">{openSections['section4'] ? '▲' : '▼'}</span>
          </h2>
          <p className={openSections['section4'] ? 'open' : ''}>
            All necessary indicators are included in the course. You do not need to purchase any additional indicators.
          </p>
        </div>

        <div className="faq-section">
          <h2 onClick={() => toggleSection('section5')} className="faq-header">
            Can I book the indicators without the course? <span className="arrow">{openSections['section5'] ? '▲' : '▼'}</span>
          </h2>
          <p className={openSections['section5'] ? 'open' : ''}>
            Yes, the indicators can be booked without the course.
          </p>
        </div>

        <div className="faq-section">
          <h2 onClick={() => toggleSection('section6')} className="faq-header">
            Do I need a paid account on TradingView? <span className="arrow">{openSections['section6'] ? '▲' : '▼'}</span>
          </h2>
          <p className={openSections['section6'] ? 'open' : ''}>
            It is not mandatory to have a paid account on TradingView, but it is recommended. With a free account, you can only use up to three indicators per chart, which can be cumbersome if you need to frequently switch between multiple indicators. A Plus subscription or higher is necessary for a smoother experience.
          </p>
        </div>

        <div className="faq-section">
          <h2 onClick={() => toggleSection('section7')} className="faq-header">
            What kind of support do I get during the course? <span className="arrow">{openSections['section7'] ? '▲' : '▼'}</span>
          </h2>
          <p className={openSections['section7'] ? 'open' : ''}>
            We offer comprehensive support during the course, including access to a dedicated support team, live Q&A sessions, and a community forum where you can interact with other students and instructors. Minor inquiries are handled free of charge. However, if you want your work evaluated, you can book our evaluation service.
          </p>
        </div>

        <div className="faq-section">
          <h2 onClick={() => toggleSection('section8')} className="faq-header">
            How long do I have access to the course materials? <span className="arrow">{openSections['section8'] ? '▲' : '▼'}</span>
          </h2>
          <p className={openSections['section8'] ? 'open' : ''}>
            Once you enroll in the course, you have access to all course materials, including future updates and additions as long our company is in business. You can access the course materials at any time and complete the course at your own pace.
          </p>
        </div>

        <div className="faq-section">
          <h2 onClick={() => toggleSection('section9')} className="faq-header">
            Are there any prerequisites for taking the course? <span className="arrow">{openSections['section9'] ? '▲' : '▼'}</span>
          </h2>
          <p className={openSections['section9'] ? 'open' : ''}>
            There are no specific prerequisites for taking the course. However, a basic understanding of financial markets and trading concepts is beneficial.
          </p>
        </div>

        <div className="faq-section">
          <h2 onClick={() => toggleSection('section10')} className="faq-header">
            Can I get a refund if I am not satisfied with the course? <span className="arrow">{openSections['section10'] ? '▲' : '▼'}</span>
          </h2>
          <p className={openSections['section10'] ? 'open' : ''}>
            Yes, you can request a refund if the strategy has not recouped the course costs after one year.
          </p>
        </div>

        <div className="faq-section">
          <h2 onClick={() => toggleSection('section11')} className="faq-header">
            How is the course delivered? <span className="arrow">{openSections['section11'] ? '▲' : '▼'}</span>
          </h2>
          <p className={openSections['section11'] ? 'open' : ''}>
            The course is delivered online in the form of a Slideshow presentation with theoretical texts, images, and videos. You can access the course materials at any time and complete the course at your own pace.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Faq;