import React from 'react';

function Welcome() {
  return (
    <div id="welcome">
      <div className="welcome-content">
        <h1>COMMODITIES TRADE SEASON</h1>
      </div>
    </div>
  );
}

export default Welcome;
