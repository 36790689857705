import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { getISOWeek } from 'date-fns';
import { decomposeTimeSeries, kernelSmoothing } from './SeasonalCalculation';
import useDeepCompareEffect from 'use-deep-compare-effect';
import '../css/matrix.css'; // Stellen Sie sicher, dass die CSS-Datei importiert wird

const SeasonalComponent = ({ data, onUpdate }) => {
    const [seasonalCurve, setSeasonalCurve] = useState([]);

    useDeepCompareEffect(() => {
        if (data.length > 0) {
            const smoothingFunction = (data) => kernelSmoothing(data, 10);
            const { seasonal } = decomposeTimeSeries(data, smoothingFunction);

            // Berechne die gemittelte saisonale Kurve
            const averagedSeasonalCurve = calculateAveragedSeasonalCurve(data, seasonal);
            setSeasonalCurve(averagedSeasonalCurve);
            onUpdate(averagedSeasonalCurve);
        }
    }, [data, onUpdate]);

    const calculateAveragedSeasonalCurve = (data, seasonal) => {
        const periodLength = 52; // Wöchentliche Daten

        let seasonalSums = new Array(periodLength).fill(0);
        let seasonalCounts = new Array(periodLength).fill(0);

        data.forEach((d, i) => {
            const periodIndex = getISOWeek(d.time) - 1; // Woche des Jahres extrahieren
            seasonalSums[periodIndex] += seasonal[i];
            seasonalCounts[periodIndex] += 1;
        });

        return seasonalSums.map((sum, i) => sum / seasonalCounts[i]);
    };

    // Berechnen Sie die minimalen und maximalen Werte der seasonalCurve
    const minYValue = Math.min(...seasonalCurve);
    const maxYValue = Math.max(...seasonalCurve);

    return (
        <div className="chart-container-m-season">
            {seasonalCurve.length > 0 && (
                <div className="chart-container">
                    <Line 
                        data={{
                            labels: Array.from({ length: seasonalCurve.length }, (_, i) => `Week ${i + 1}`),
                            datasets: [{
                                label: 'Seasonal',
                                data: seasonalCurve,
                                borderColor: '#6a9bdd', // Setze die Linienfarbe auf blau
                                borderWidth: 2,
                                fill: false
                            }]
                        }} 
                        options={{
                            responsive: true,
                            maintainAspectRatio: false, // Verhindert, dass der Chart verzerrt wird
                            scales: {
                                y: {
                                    min: minYValue,
                                    max: maxYValue,
                                    title: {
                                        display: true,
                                        text: 'Value'
                                    }
                                }
                            }
                        }}
                    />
                </div>
            )}
        </div>
    );
}

export default SeasonalComponent;