const symbols = {
    "ES": {
        "name": "E-mini S&P 500",
        "size": 50,
        "tick_value": 0.25,
        "point_value": 50,
        "per_contract": 12.50
    },
    "NQ": {
        "name": "E-mini Nasdaq 100",
        "size": 20,
        "tick_value": 0.25,
        "point_value": 20,
        "per_contract": 5.00
    },
    "YM": {
        "name": "Mini Dow Futures",
        "size": 5,
        "tick_value": 1,
        "point_value": 5,
        "per_contract": 5.00
    },
    "RTY": {
        "name": "E-mini Russell 2000",
        "size": 50,
        "tick_value": 0.10,
        "point_value": 50,
        "per_contract": 5.00
    },
    "EMD": {
        "name": "E-mini S&P Midcap",
        "size": 100,
        "tick_value": 0.10,
        "point_value": 100,
        "per_contract": 10.00
    },
    "MES": {
        "name": "Micro S&P 500",
        "size": 5,
        "tick_value": 0.25,
        "point_value": 5,
        "per_contract": 1.25
    },
    "MNQ": {
        "name": "Micro Nasdaq 100",
        "size": 2,
        "tick_value": 0.25,
        "point_value": 2,
        "per_contract": 0.50
    },
    "VX": {
        "name": "S&P 500 VIX",
        "size": 1000,
        "tick_value": 0.05,
        "point_value": 1000,
        "per_contract": 50.00
    },
    "GD": {
        "name": "S&P GSCI",
        "size": 250,
        "tick_value": 0.05,
        "point_value": 250,
        "per_contract": 12.50
    },
    "AW": {
        "name": "Bloom Commodity Index",
        "size": 100,
        "tick_value": 0.01,
        "point_value": 100,
        "per_contract": 1.00
    },
    "ZB": {
        "name": "30-Year T-Bond",
        "size": 100000,
        "tick_value": 0.03125,
        "point_value": 1000,
        "per_contract": 31.25
    },
    "UB": {
        "name": "Ultra T-Bond",
        "size": 100000,
        "tick_value": 0.03125,
        "point_value": 1000,
        "per_contract": 31.25
    },
    "ZN": {
        "name": "10-Year T-Note",
        "size": 100000,
        "tick_value": 0.015625,
        "point_value": 1000,
        "per_contract": 15.625
    },
    "TN": {
        "name": "Ultra 10-Year T-Note",
        "size": 100000,
        "tick_value": 0.015625,
        "point_value": 1000,
        "per_contract": 15.625
    },
    "ZF": {
        "name": "5-Year T-Note",
        "size": 100000,
        "tick_value": 0.0078125,
        "point_value": 1000,
        "per_contract": 7.8125
    },
    "ZT": {
        "name": "2-Year T-Note",
        "size": 200000,
        "tick_value": 0.0078125,
        "point_value": 2000,
        "per_contract": 7.8125
    },
    "ZQ": {
        "name": "30-Day Fed Funds",
        "size": 5000000,
        "tick_value": 0.00025,
        "point_value": 4167,
        "per_contract": 10.4175
    },
    "SR3": {
        "name": "3-Month SOFR",
        "size": 25,
        "tick_value": 0.00025,
        "point_value": 2500,
        "per_contract": 6.25
    },
    "DX": {
        "name": "U.S. Dollar Index",
        "size": 1000,
        "tick_value": 0.005,
        "point_value": 1000,
        "per_contract": 5.00
    },
    "MBT": {
        "name": "Bitcoin Micro",
        "size": 0.1,
        "tick_value": 5,
        "point_value": 0.1,
        "per_contract": 0.50
    },
    "MET": {
        "name": "Ether Micro",
        "size": 0.1,
        "tick_value": 0.50,
        "point_value": 0.1,
        "per_contract": 0.05
    },
    "6E": {
        "name": "Euro FX",
        "size": 125000,
        "tick_value": 0.00005,
        "point_value": 125000,
        "per_contract": 6.25
    },
    "6A": {
        "name": "Australian Dollar",
        "size": 100000,
        "tick_value": 0.00005,
        "point_value": 100000,
        "per_contract": 5.00
    },
    "6B": {
        "name": "British Pound",
        "size": 62500,
        "tick_value": 0.0001,
        "point_value": 62500,
        "per_contract": 6.25
    },
    "6C": {
        "name": "Canadian Dollar",
        "size": 100000,
        "tick_value": 0.00005,
        "point_value": 100000,
        "per_contract": 5.00
    },
    "6J": {
        "name": "Japanese Yen",
        "size": 12500000,
        "tick_value": 0.0000005,
        "point_value": 12500000,
        "per_contract": 6.25
    },
    "6S": {
        "name": "Swiss Franc",
        "size": 125000,
        "tick_value": 0.00005,
        "point_value": 125000,
        "per_contract": 6.25
    },
    "6M": {
        "name": "Mexican Peso",
        "size": 500000,
        "tick_value": 0.00001,
        "point_value": 500000,
        "per_contract": 5.00
    },
    "6N": {
        "name": "New Zealand Dollar",
        "size": 100000,
        "tick_value": 0.00005,
        "point_value": 100000,
        "per_contract": 5.00
    },
    "6L": {
        "name": "Brazilian Real",
        "size": 100000,
        "tick_value": 0.00005,
        "point_value": 100000,
        "per_contract": 5.00
    },
    "6Z": {
        "name": "South African Rand",
        "size": 500000,
        "tick_value": 0.000025,
        "point_value": 500000,
        "per_contract": 12.50
    },
    "BTC": {
        "name": "Bitcoin Futures",
        "size": 5,
        "tick_value": 5,
        "point_value": 5,
        "per_contract": 25.00
    },
    "ETH": {
        "name": "Ether Futures",
        "size": 50,
        "tick_value": 0.50,
        "point_value": 50,
        "per_contract": 25.00
    },
    "GC": {
        "name": "Gold",
        "size": 100,
        "tick_value": 0.10,
        "point_value": 100,
        "per_contract": 10.00
    },
    "SI": {
        "name": "Silver",
        "size": 5000,
        "tick_value": 0.005,
        "point_value": 5000,
        "per_contract": 25.00
    },
    "HG": {
        "name": "High Grade Copper",
        "size": 25000,
        "tick_value": 0.0005,
        "point_value": 25000,
        "per_contract": 12.50
    },
    "PL": {
        "name": "Platinum",
        "size": 50,
        "tick_value": 0.10,
        "point_value": 50,
        "per_contract": 5.00
    },
    "PA": {
        "name": "Palladium",
        "size": 100,
        "tick_value": 0.50,
        "point_value": 100,
        "per_contract": 50.00
    },
    "AL": {
        "name": "Aluminum",
        "size": 25,
        "tick_value": 0.25,
        "point_value": 25,
        "per_contract": 6.25
    },
    "HV": {
        "name": "US Midwest Steel CRU",
        "size": 20,
        "tick_value": 1,
        "point_value": 20,
        "per_contract": 20.00
    },
    "UX": {
        "name": "Uranium",
        "size": 250,
        "tick_value": 0.05,
        "point_value": 250,
        "per_contract": 12.50
    },
    "LM": {
        "name": "Lithium",
        "size": 1000,
        "tick_value": 0.01,
        "point_value": 1000,
        "per_contract": 10.00
    },
    "CL": {
        "name": "Crude Oil WTI",
        "size": 1000,
        "tick_value": 0.01,
        "point_value": 1000,
        "per_contract": 10.00
    },
    "HO": {
        "name": "ULSD NY Harbor",
        "size": 42000,
        "tick_value": 0.0001,
        "point_value": 42000,
        "per_contract": 4.20
    },
    "RB": {
        "name": "Gasoline RBOB",
        "size": 42000,
        "tick_value": 0.0001,
        "point_value": 42000,
        "per_contract": 4.20
    },
    "NG": {
        "name": "Natural Gas",
        "size": 10000,
        "tick_value": 0.001,
        "point_value": 10000,
        "per_contract": 10.00
    },
    "QA": {
        "name": "Crude Oil Brent (F)",
        "size": 1000,
        "tick_value": 0.01,
        "point_value": 1000,
        "per_contract": 10.00
    },
    "FL": {
        "name": "Ethanol Chicago",
        "size": 42000,
        "tick_value": 0.0001,
        "point_value": 42000,
        "per_contract": 4.20
    },
    "ZC": {
        "name": "Corn",
        "size": 5000,
        "tick_value": 0.0025,
        "point_value": 50,
        "per_contract": 12.50
    },
    "ZS": {
        "name": "Soybean",
        "size": 5000,
        "tick_value": 0.0025,
        "point_value": 50,
        "per_contract": 12.50
    },
    "ZM": {
        "name": "Soybean Meal",
        "size": 100,
        "tick_value": 0.10,
        "point_value": 100,
        "per_contract": 10.00
    },
    "ZL": {
        "name": "Soybean Oil",
        "size": 60000,
        "tick_value": 0.01,
        "point_value": 60000,
        "per_contract": 6.00
    },
    "ZW": {
        "name": "Wheat",
        "size": 5000,
        "tick_value": 0.0025,
        "point_value": 50,
        "per_contract": 12.50
    },
    "KE": {
        "name": "Hard Red Wheat",
        "size": 5000,
        "tick_value": 0.0025,
        "point_value": 50,
        "per_contract": 12.50
    },
    "MW": {
        "name": "Spring Wheat",
        "size": 5000,
        "tick_value": 0.0025,
        "point_value": 50,
        "per_contract": 12.50
    },
    "ZO": {
        "name": "Oats",
        "size": 5000,
        "tick_value": 0.0025,
        "point_value": 50,
        "per_contract": 12.50
    },
    "ZR": {
        "name": "Rough Rice",
        "size": 2000,
        "tick_value": 0.005,
        "point_value": 2000,
        "per_contract": 10.00
    },
    "RS": {
        "name": "Canola",
        "size": 20000,
        "tick_value": 0.10,
        "point_value": 2000,
        "per_contract": 2.00
    },
    "CT": {
        "name": "Cotton #2",
        "size": 50000,
        "tick_value": 0.0001,
        "point_value": 500,
        "per_contract": 5.00
    },
    "KC": {
        "name": "Coffee",
        "size": 37500,
        "tick_value": 0.0005,
        "point_value": 250,
        "per_contract": 18.75
    },
    "SB": {
        "name": "Sugar #11",
        "size": 112000,
        "tick_value": 0.0001,
        "point_value": 1120,
        "per_contract": 11.20
    },
    "CC": {
        "name": "Cocoa",
        "size": 10000,
        "tick_value": 1.00,
        "point_value": 10,
        "per_contract": 10.00
    },
    "LB": {
        "name": "Lumber Physical",
        "size": 27500,
        "tick_value": 0.50,
        "point_value": 27.50,
        "per_contract": 13.75
    },
    "OJ": {
        "name": "Orange Juice",
        "size": 15000,
        "tick_value": 0.0005,
        "point_value": 150,
        "per_contract": 7.50
    },
    "SD": {
        "name": "Sugar #16",
        "size": 112000,
        "tick_value": 0.0001,
        "point_value": 1120,
        "per_contract": 11.20
    },
    "LE": {
        "name": "Live Cattle",
        "size": 40000,
        "tick_value": 0.00025,
        "point_value": 400,
        "per_contract": 10.00
    },
    "GF": {
        "name": "Feeder Cattle",
        "size": 50000,
        "tick_value": 0.00025,
        "point_value": 500,
        "per_contract": 12.50
    },
    "HE": {
        "name": "Lean Hogs",
        "size": 40000,
        "tick_value": 0.00025,
        "point_value": 400,
        "per_contract": 10.00
    },
    "KM": {
        "name": "Pork Cutout",
        "size": 40000,
        "tick_value": 0.00025,
        "point_value": 400,
        "per_contract": 10.00
    },
    "DL": {
        "name": "Class III Milk",
        "size": 200000,
        "tick_value": 0.01,
        "point_value": 2000,
        "per_contract": 20.00
    },
    "DF": {
        "name": "Nonfat Dry Milk",
        "size": 44000,
        "tick_value": 0.025,
        "point_value": 440,
        "per_contract": 11.00
    },
    "DG": {
        "name": "Dry Whey",
        "size": 44000,
        "tick_value": 0.025,
        "point_value": 440,
        "per_contract": 11.00
    },
    "BD": {
        "name": "Butter Cash-Settled",
        "size": 20000,
        "tick_value": 0.025,
        "point_value": 200,
        "per_contract": 5.00
    },
    "BJ": {
        "name": "Cheese Cash-Settled",
        "size": 20000,
        "tick_value": 0.001,
        "point_value": 20000,
        "per_contract": 20.00
    }
}

export default symbols;