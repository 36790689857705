import React from 'react';
import '../css/standard.css';
import '../css/formular.css';
import { FcGoogle } from "react-icons/fc";
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { toast } from 'react-toastify';
import { getDoc, setDoc, doc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { useNavigate } from 'react-router-dom';


export default function OAuth() {
  const navigate = useNavigate();

  async function onGoogleClick() {
    try {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      
      // check for the user
      const docRef = doc(db, 'users', user.uid);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        await setDoc(docRef, {
          name: user.displayName,
          email: user.email,
          timestamp: serverTimestamp(),
        });
      }
      navigate('/welcome'); 
    } catch (error) {
      toast.error('Could not authorize with Google');

    }
  }

  return (
    <>
      <div className='or-line'><p>OR</p></div>
      <button 
        className="oauth-button" 
        onClick={onGoogleClick}
        type= "button"
      >
          <FcGoogle />
          Continue with Google
      </button>
    </>
  );
}