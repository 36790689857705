import React, { useState } from 'react';
import '../css/standard.css';

const UsefullLinks = () => {
    const [toggleDropdown1, setToggleDropdown1] = useState(false);
    const [toggleDropdown2, setToggleDropdown2] = useState(false);
    const [toggleDropdownBooks, setToggleDropdownBooks] = useState(false);

    const toggleDropdown = (event, dropdown) => {
        event.preventDefault();
        if (dropdown === 'dropdown1') {
            setToggleDropdown1(!toggleDropdown1);
        } else if (dropdown === 'dropdown2') {
            setToggleDropdown2(!toggleDropdown2);
        } else if (dropdown === 'dropdownBooks') {
            setToggleDropdownBooks(!toggleDropdownBooks);
        }
    }

    const closeDropdown = (dropdown) => {
        if (dropdown === 'dropdown1') {
            setToggleDropdown1(false);
        } else if (dropdown === 'dropdown2') {
            setToggleDropdown2(false);
        } else if (dropdown === 'dropdownBooks') {
            setToggleDropdownBooks(false);
        }
    }

    return (
        <>
            <div className="main-links-container">
                <div className="Links-Container" id='profil-content'>
                    <h1 className="links-header">Here you will find various links to resources for research</h1>
                    <ul className="Links-Container">
                        <li className={`dropdown-usefull-links ${toggleDropdownBooks ? 'active' : ''}`} onMouseLeave={() => closeDropdown('dropdownBooks')}>
                            <a onClick={(event) => toggleDropdown(event, 'dropdownBooks')} className="Links-download-button" href="#!" target="_self" rel="noopener noreferrer">
                                Book Recommendations
                                <span className="dropdown-icon-usefull-links">▼</span>
                            </a>
                            <span className="additional-text">Explore our recommended books</span>
                            <div className="dropdown-content-usefull-links">
                                <a href="https://www.kobo.com/de/de/ebook/trade-stocks-and-commodities-with-the-insiders" target="_blank" rel="noopener noreferrer">
                                    Trade Stocks and Commodities with the Insiders
                                </a>
                                <a href="https://www.amazon.com/-/de/dp/0470915730/ref=sr_1_1?__mk_de_DE=ÅMÅŽÕÑ&crid=1OR6BVKPJ75W2&dib=eyJ2IjoiMSJ9.dPSoB5Ipt-OUuFSWdjTNW4BznyVLLS0JPhfUN6EHnFJJ3aAR9XXqKoCH4NzfOodMbqyQIgM4UnxdkqUoFWA6C32uBL6fC45qsefOwjeAAsQHvNiTT9A2TWBmi5-vWPo41V6gVlQV4pZwUrBfQScFdXXEdrPD9raobcGf46TKsfM.AmC37Bs-x51MgPRhtjhjrODJkJ0d6iONa4HAmlcKl24&dib_tag=se&keywords=larry+williams+short+term&qid=1728377891&sprefix=larry+williams+short+term%2Caps%2C148&sr=8-1" target="_blank" rel="noopener noreferrer">
                                    Long-Term Secrets to Short-Term Trading
                                </a>
                                <a href="https://www.amazon.com/-/de/dp/1119210011/ref=sr_1_5?__mk_de_DE=ÅMÅŽÕÑ&crid=2ANZSSTKGEVRV&dib=eyJ2IjoiMSJ9.pFy6AMlB5jd7SFnq6OEHx0Bmlu5QzlWMMx-PePAAz5y9zUizfnPFDk1j6cS34P2ZjpyzsCrfMMExF8f7ynnexQc7E6EJ8XhpPtzkNgPHDAn_YkvwDDcGOqFloWPvPm4CD_1V-GQR3EgER1oqaPEz3KyW8RUdKvIoTpf2HqikZjKm8Njw5LZKmLCyBfEmILtqv63GyBsad5ajgx4PxuGSM_a3Ot1JQZ5ZuFL_3jmeF_I.8ubMYfagbU74-8NPZRikfHA7A584m0rahbCd4rGig6U&dib_tag=se&keywords=john+j.+murphy&qid=1728377624&sprefix=john+j.+murphy%2Caps%2C139&sr=8-5" target="_blank" rel="noopener noreferrer">
                                    Trading Intermarket Analysis
                                </a>
                            </div>
                        </li>
                        <li>
                            <a className="Links-download-button" href="https://finviz.com/futures_charts.ashx" target="_blank" rel="noopener noreferrer">Finviz Futures Charts</a>
                            <span className="additional-text">See all commodity charts at a glance here</span>
                        </li>
                        <li>
                            <a className="Links-download-button" href="https://www.mrci.com/special/corr030.php" target="_blank" rel="noopener noreferrer">MRCI Correlation Data</a>
                            <span className="additional-text">Pay attention to diversification in the portfolio</span>
                        </li>
                        <li className={`dropdown-usefull-links ${toggleDropdown1 ? 'active' : ''}`} onMouseLeave={() => closeDropdown('dropdown1')}>
                            <a onClick={(event) => toggleDropdown(event, 'dropdown1')} className="Links-download-button" href="#!" target="_self" rel="noopener noreferrer">
                                Barchart Futures
                                <span className="dropdown-icon-usefull-links">▼</span>
                            </a>
                            <span className="additional-text">Research everything about futures</span>
                            <div className="dropdown-content-usefull-links">
                                <a href="https://www.barchart.com/futures" target="_blank" rel="noopener noreferrer">Barchart Futures Overview</a>
                                <a href="https://www.barchart.com/futures/heat-map" target="_blank" rel="noopener noreferrer">Futures Heat Map</a>
                                <a href="https://www.barchart.com/futures/contract-specifications/currencies" target="_blank" rel="noopener noreferrer">Futures Contract Specifications</a>
                                <a href="https://www.barchart.com/futures/micro-contracts?viewName=143480&orderBy=contractExpirationDate&orderDir=asc" target="_blank" rel="noopener noreferrer">Mini and Micro Futures</a>
                                <a href="https://www.barchart.com/futures/commitment-of-traders" target="_blank" rel="noopener noreferrer">Commitment of Traders</a>
                            </div>
                        </li>
                        <li className={`dropdown-usefull-links ${toggleDropdown2 ? 'active' : ''}`} onMouseLeave={() => closeDropdown('dropdown2')}>
                            <a onClick={(event) => toggleDropdown(event, 'dropdown2')} className="Links-download-button" href="#!" >
                                Wetterdaten
                                <span className="dropdown-icon-usefull-links">▼</span>
                            </a>
                            <span className="additional-text">Research current weather data</span>
                            <div className="dropdown-content-usefull-links">
                                <a href="https://www.drought.gov/" target="_blank" rel="noopener noreferrer">Current Drought Conditions & Outlook</a>
                                <a href="https://www.weather.gov/" target="_blank" rel="noopener noreferrer">National Weather Service Alert Map</a>
                                <a href="https://www.usda.gov/" target="_blank" rel="noopener noreferrer">Today's Agricultural Weather Highlights</a>
                                <a href="https://weather.com/" target="_blank" rel="noopener noreferrer">Radar Map</a>
                                <a href="https://www.cleardarksky.com/csk/" target="_blank" rel="noopener noreferrer">Dark Sky Weather Maps & Forecasts</a>
                                <a href="https://www.usda.gov/" target="_blank" rel="noopener noreferrer">Weekly Weather & Crop Bulletin</a>
                                <a href="https://weather.gc.ca/" target="_blank" rel="noopener noreferrer">Canadian Weather</a>
                                <a href="https://www.weather.gov/fire/" target="_blank" rel="noopener noreferrer">Fire Weather</a>
                                <a href="https://www.cpc.ncep.noaa.gov/" target="_blank" rel="noopener noreferrer">6 to 10-Day Precipitation Outlook</a>
                                <a href="https://www.cpc.ncep.noaa.gov/" target="_blank" rel="noopener noreferrer">6 to 10-Day Temperature Outlook</a>
                                <a href="https://weather.com/" target="_blank" rel="noopener noreferrer">Daily/Monthly Forecasts</a>
                                <a href="https://www.cpc.ncep.noaa.gov/" target="_blank" rel="noopener noreferrer">30-Day Outlook Maps</a>
                                <a href="https://www.cpc.ncep.noaa.gov/" target="_blank" rel="noopener noreferrer">Seasonal Outlook Maps</a>
                                <a href="https://www.nohrsc.noaa.gov/" target="_blank" rel="noopener noreferrer">National & Regional Snow Maps</a>
                                <a href="https://www.nhc.noaa.gov/" target="_blank" rel="noopener noreferrer">National Hurricane Center</a>
                                <a href="https://www.wunderground.com/" target="_blank" rel="noopener noreferrer">Hurricane and Tropical Cyclones</a>
                                <a href="https://droughtmonitor.unl.edu/" target="_blank" rel="noopener noreferrer">Weekly Drought Monitor</a>
                                <a href="https://droughtmonitor.unl.edu/" target="_blank" rel="noopener noreferrer">Drought Monitor 12-Week Slideshow</a>
                                <a href="https://www.cpc.ncep.noaa.gov/" target="_blank" rel="noopener noreferrer">Palmer Drought Index</a>
                                <a href="https://www.cpc.ncep.noaa.gov/" target="_blank" rel="noopener noreferrer">Seasonal Drought Outlook</a>
                            </div>
                        </li>
                    </ul>
                    <p id="disclaimer" style={{fontSize: '0.8rem'}}>
                        Disclaimer: These links refer to external resources, for whose content and availability we cannot guarantee.
                    </p>
                </div>
            </div>
        </>
    );
}

export default UsefullLinks;