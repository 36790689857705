const ProfitTarget = {
    headers : [
        'Defining Profit Targets',
    ],

    titles : [
        'Overview & Fib Retracement Tool',
        'Initial Pattern (MA18, ADMA)',
        'Initial Pattern (MA18, ADMA)',
        'Correction Pattern (ma18, percentR, inside and outside bars)',
        'Correction Pattern (adma)', 
        'Correction Pattern (adma)',
        'Momentum 28 Pattern',
        'Momentum 28 Pattern',
    ],

    media : [
        {type: 'image', src: '2CourseDailyMedien/fibRetracementTool.png', alt: 'Overview and Fib Retracement Tool', caption: 'Chart provided by TradingView'},
        {type: 'image', src: '2CourseDailyMedien/PTpatternLength.png', alt: 'Initial Pattern 1:1', caption: 'Chart provided by TradingView'},
        {type: 'image', src: '2CourseDailyMedien/lastCorrectionProfitTarget.png', alt: 'Initial Pattern 1:1', caption: 'Chart provided by TradingView'},
        {type: 'image', src: '2CourseDailyMedien/PTma18Rebound.png', alt: 'Correction Pattern 18MA', caption: 'Chart provided by TradingView'},
        {type: 'image', src: '2CourseDailyMedien/PTadmaCorrection.png', alt: 'Correction Pattern adma', caption: 'Chart provided by TradingView'},
        {type: 'image', src: '2CourseDailyMedien/PTadmaCorrectionPattern.png', alt: 'Correction Pattern adma', caption: 'Chart provided by TradingView'},
        {type: 'image', src: '2CourseDailyMedien/PTmom28-1.png', alt: 'Momentum 28 Pattern', caption: 'Chart provided by TradingView'},
        {type: 'image', src: '2CourseDailyMedien/PTmom28-2.png', alt: 'Momentum 28 Pattern', caption: 'Chart provided by TradingView'},
    ],

    texts : [
        {
            content: [
                {type: 'paragraph', text: 'Profit targets are an important part of the trading plan. They help structure the trade and maximize profits. There are various methods to define profit targets.'},
                {type: 'paragraph', text: 'For our profit target determination, we define different profit targets depending on the pattern.'},
                {type: 'paragraph', text: 'Initial Pattern (MA18, ADMA):'},
                {type: 'list', items: ['The range of the initial pattern 1:1', 'The range of the last price movement of the short-term highs and lows 1:1']},
                {type: 'paragraph', text: 'Initial Pattern Momentum 28:'},
                {type: 'list', items: ['The range of the Momentum 28 pattern 0.5:1 or 1:1', 'The range of the trigger bar 1:1']},
                {type: 'paragraph', text: 'Correction Pattern (ma18, percentR, inside and outside bars):'},
                {type: 'list', items: ['The range of the correction pattern 2:1 or 1.5:1']},
                {type: 'paragraph', text: 'Correction Pattern adma:'},
                {type: 'list', items: ['1. Close within the 8MA and 10MA', 'Close opposite the 8MA-10MA channel']},
                {type: 'paragraph', text: 'Measure the range of the correction candle with the Fib Retracement Tool and project it in the direction of the trend.'},
                {type: 'paragraph', text: 'Test the different markets based on the patterns and profit targets and pay particular attention to the trend strength when setting profit targets. Each market has its own characteristics, and the strength of the market can affect the profit targets.'},
                {type: 'paragraph', text: 'It should also be noted that you do not always have to stick to the profit targets. If you see that the market continues to move in your direction, you can also adjust the profit targets and let the trade run further, using a manual trailing stop. If you are in a drawdown, however, you should focus more on fixed profit targets.'},
            ]
        },
        {
            content: [
                {type: 'paragraph', text: 'The initial pattern is the first pattern we look for to trade in a new trend direction.'},
                {type: 'paragraph', text: 'After defining our entry point and stop loss, we use the range 1:1 from the entry point in our direction as the profit target.'},
            ]
        },
        {
            content: [
                {type: 'paragraph', text: 'If the range is too small, we can also use the range of the last price movement of the short-term highs and lows 1:1 as the profit target.'},
            ]
        },
        {
            content: [
                {type: 'paragraph', text: 'For correction patterns, profit targets of 1.5:1 to 2:1 work very well.'},
            ]
        },
        {
            content: [
                {type: 'paragraph', text: 'For the ADMA correction pattern, we enter at the close of the bar if the close is below the 8MA in an uptrend and vice versa in a downtrend. The first profit target is a close within the 8-10MA channel. If we are trading multiple contracts, we take half of the contracts out of the market here.'},
                {type: 'paragraph', text: 'The second profit target is a close opposite the 8MA-10MA channel. Here we take the remaining contracts out of the market.'},
            ]
        },
        {
            content: [
                {type: 'paragraph', text: 'Here is another example of the ADMA correction pattern.'},
                {type: 'list', items: ['Use the channel width times 2 to set the stop loss. The entry is at the close or open above or below the channel depending on the market direction.', 'The first profit target is a close within the 8-10MA channel. If we are trading multiple contracts, we take half of the contracts out of the market here.', 'The second profit target is a close opposite the 8MA-10MA channel. Here we take the remaining contracts out of the market.', 'The stop loss is moved to break-even when the first profit target is reached to take the risk out of the market.', 'The remaining contracts are taken out of the market at the second profit target.', 'Instead of the second profit target after the close, you can also use the channel as a profit target if you want to set a profit target.', 'Depending on the trend strength, a manual trailing stop can also be used here if necessary.']}
            ]
        },
        {
            content: [
                {type: 'paragraph', text: 'For the Momentum 28 pattern, we look for divergences in a past period of up to 60 days. For our profit target, we use the range from point A to point D and project it in the direction of the trend from the entry point in our direction.'},
                {type: 'paragraph', text: 'Here we also take half of the contracts out of the market at 0.5:1 of the range and the remaining contracts at 1:1. If only one contract is traded, we decide based on the trend strength and our emotional capital (too many drawdowns before) whether to let the trade run further or take the contract out of the market at the first profit target.'},
            ]
        },
        {
            content: [
                {type: 'paragraph', text: 'In this example, points A and D are at the same level.'},
                {type: 'paragraph', text: 'In such a case, we can also use the range of the consolidation phase or, depending on the size, the length of the last market movement of short-term or medium-term highs and lows.'},
            ]
        }
    ]
};

export default ProfitTarget;