import React from 'react';

function CourseTarget() {
  return (
    <div id="courseTarget">
        <h2>What you will master after this course:</h2>
        <ul>
            <li><strong>Trading with COT data:</strong> Learn to trade with COT data and how to identify profitable trading signals for various markets.</li>
            <li><strong>Trend assessment:</strong> Understand how to assess the trend of a market and decide if a COT signal is applicable.</li>
            <li><strong>Weekly watchlist:</strong> Create a weekly watchlist for markets you want to trade based on COT signals and evaluate them daily to identify optimal entry points.</li>
            <li><strong>Risk management:</strong> Manage your risk efficiently and place trades according to your trading account.</li>
            <li><strong>Trade management:</strong> Increase your profits and reduce losses through effective trade management.</li>
            <li><strong>Emotional control:</strong> Reflect on your emotional behavior and minimize mistakes by consciously implementing your strategy.</li>
            <li><strong>Success in trading:</strong> Become a successful trader and achieve your financial goals with our course.</li>
        </ul>
    </div>
  );
}

export default CourseTarget;
