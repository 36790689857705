import React, { useState, useEffect, useCallback } from 'react';
import '../css/matrix.css';
import Papa from 'papaparse';
import { groupByYear } from './SeasonalCalculation';

const BestTrades = ({ csvFile }) => {
    const [tableData, setTableData] = useState([]);
    const [tradingDay, setTradingDay] = useState(1);
    const [holdingDays, setHoldingDays] = useState(20);
    const [results, setResults] = useState([]);
    const [summary, setSummary] = useState({
        totalYears: 0,
        positiveReturns: [],
        negativeReturns: [],
        probabilitiesPositive: [],
        probabilitiesNegative: [],
        avgDrawdownPositive: [],
        avgDrawdownNegative: [],
        maxDrawdownLong: [],
        maxDrawdownShort: [],
        maxReturnPositive: [],
        minReturnPositive: [],
        maxReturnNegative: [],
        minReturnNegative: [],
        avgReturnPositive: [],
        avgReturnNegative: []
    });
    const [probabilityThreshold, setProbabilityThreshold] = useState(60);
    const [maxDrawdownThreshold, setMaxDrawdownThreshold] = useState(5);

    const [isTableVisible, setIsTableVisible] = useState(false);
    const [isTableVisible1, setIsTableVisible1] = useState(true);

    const toggleTableVisibility = () => {
        setIsTableVisible(!isTableVisible);
    };
    const toggleTableVisibility1 = () => {
        setIsTableVisible1(!isTableVisible1);
    };

    const parseCSV = (file) => {
        Papa.parse(file, {
            header: true,
            complete: (result) => {
                if (result.errors.length > 0) {
                    console.error("Error parsing CSV file:", result.errors);
                    return;
                }
                const data = result.data.map((row, index, array) => {
                    const previousRow = index > 0 ? array[index - 1] : {};
                    return {
                        time: row.time || previousRow.time,
                        open: parseFloat(row.open) || parseFloat(previousRow.open),
                        high: parseFloat(row.high) || parseFloat(previousRow.high),
                        low: parseFloat(row.low) || parseFloat(previousRow.low),
                        close: parseFloat(row.close) || parseFloat(previousRow.close)
                    };
                });
                setTableData(data);
            }
        });
    };

    const handleCalculate = useCallback(() => {
        const groupedData = groupByYear(tableData);
        const results = [];
        const summary = {
            totalYears: 0,
            positiveReturns: Array(holdingDays).fill(0),
            negativeReturns: Array(holdingDays).fill(0),
            zeroReturns: Array(holdingDays).fill(0), // Hinzufügen eines Arrays für Nullwerte
            probabilitiesPositive: Array(holdingDays).fill(0),
            probabilitiesNegative: Array(holdingDays).fill(0),
            avgDrawdownPositive: Array(holdingDays).fill(0),
            avgDrawdownNegative: Array(holdingDays).fill(0),
            maxDrawdownLong: Array(holdingDays).fill(0),
            maxDrawdownShort: Array(holdingDays).fill(0),
            maxReturnPositive: Array(holdingDays).fill(-Infinity),
            minReturnPositive: Array(holdingDays).fill(Infinity),
            maxReturnNegative: Array(holdingDays).fill(-Infinity),
            minReturnNegative: Array(holdingDays).fill(Infinity),
            avgReturnPositive: Array(holdingDays).fill(0),
            avgReturnNegative: Array(holdingDays).fill(0)
        };
    
        Object.keys(groupedData).forEach(year => {
            const yearData = groupedData[year];
            const yearResults = { year };
    
            for (let i = 0; i < holdingDays; i++) {
                const currentIndex = tradingDay - 1 + i;
                if (yearData.length > currentIndex) {
                    let entryPrice, endClose, returnPercentage, returnCalculation;
    
                    if (tradingDay === 1) {
                        // Für den ersten Trading-Tag (Index 0)
                        entryPrice = yearData[0].open;
                        endClose = yearData[currentIndex].close;
                    } else {
                        // Für alle anderen Trading-Tage
                        entryPrice = yearData[tradingDay - 2].close;
                        endClose = yearData[currentIndex].close;
                    }
    
                    returnPercentage = ((endClose - entryPrice) / entryPrice) * 100;
                    returnCalculation = `(${endClose} - ${entryPrice}) / ${entryPrice} * 100`;
    
                    // Berechnung des Drawdowns für Long-Trades
                    const lows = yearData.slice(tradingDay - 1, currentIndex + 1).map(day => day.low);
                    const minLow = Math.min(...lows);
                    const currentDrawdownLong = ((minLow - entryPrice) / entryPrice) * 100;
                    const drawdownLongCalculation = `(${minLow} - ${entryPrice}) / ${entryPrice} * 100`;
    
                    // Berechnung des Drawdowns für Short-Trades
                    const highs = yearData.slice(tradingDay - 1, currentIndex + 1).map(day => day.high);
                    const maxHigh = Math.max(...highs);
                    const currentDrawdownShort = ((entryPrice - maxHigh) / entryPrice) * 100;
                    const drawdownShortCalculation = `(${entryPrice} - ${maxHigh}) / ${entryPrice} * 100`;
    
                    yearResults[`returnDay${i + 1}`] = returnPercentage.toFixed(2);
                    yearResults[`returnCalculationDay${i + 1}`] = returnCalculation;
                    yearResults[`drawdownLongDay${i + 1}`] = currentDrawdownLong.toFixed(2);
                    yearResults[`drawdownLongCalculationDay${i + 1}`] = drawdownLongCalculation;
                    yearResults[`drawdownShortDay${i + 1}`] = currentDrawdownShort.toFixed(2);
                    yearResults[`drawdownShortCalculationDay${i + 1}`] = drawdownShortCalculation;
    
                    // Hinzufügen der Rohdaten
                    yearResults[`entryPriceDay${i + 1}`] = entryPrice.toFixed(2);
                    yearResults[`endCloseDay${i + 1}`] = endClose.toFixed(2);
                    yearResults[`lowsDay${i + 1}`] = lows.map(low => low.toFixed(2));
                    yearResults[`highsDay${i + 1}`] = highs.map(high => high.toFixed(2));
    
                    // Update summary
                    if (returnPercentage > 0) {
                        summary.positiveReturns[i]++;
                        summary.avgDrawdownPositive[i] += currentDrawdownLong;
                        summary.avgReturnPositive[i] += returnPercentage;
                        summary.maxDrawdownLong[i] = Math.min(summary.maxDrawdownLong[i], currentDrawdownLong);
                        summary.maxReturnPositive[i] = Math.max(summary.maxReturnPositive[i], returnPercentage);
                        summary.minReturnPositive[i] = Math.min(summary.minReturnPositive[i], returnPercentage);
                    } else if (returnPercentage < 0) {
                        summary.negativeReturns[i]++;
                        summary.avgDrawdownNegative[i] += currentDrawdownShort;
                        summary.avgReturnNegative[i] += returnPercentage;
                        summary.maxDrawdownShort[i] = Math.min(summary.maxDrawdownShort[i], currentDrawdownShort);
                        summary.maxReturnNegative[i] = Math.max(summary.maxReturnNegative[i], returnPercentage);
                        summary.minReturnNegative[i] = Math.min(summary.minReturnNegative[i], returnPercentage);
                    } else {
                        summary.zeroReturns[i]++; // Nullwerte zählen
                    }
                }
            }
    
            results.push(yearResults);
        });
    
        summary.totalYears = results.length;
        summary.probabilitiesPositive = summary.positiveReturns.map((count, index) => ((count / summary.totalYears) * 100).toFixed(2));
        summary.probabilitiesNegative = summary.negativeReturns.map((count, index) => ((count / summary.totalYears) * 100).toFixed(2));
        summary.avgDrawdownPositive = summary.avgDrawdownPositive.map((total, index) => (summary.positiveReturns[index] > 0 ? (total / summary.positiveReturns[index]).toFixed(2) : 0));
        summary.avgDrawdownNegative = summary.avgDrawdownNegative.map((total, index) => (summary.negativeReturns[index] > 0 ? (total / summary.negativeReturns[index]).toFixed(2) : 0));
        summary.avgReturnPositive = summary.avgReturnPositive.map((total, index) => (summary.positiveReturns[index] > 0 ? (total / summary.positiveReturns[index]).toFixed(2) : 0));
        summary.avgReturnNegative = summary.avgReturnNegative.map((total, index) => (summary.negativeReturns[index] > 0 ? (total / summary.negativeReturns[index]).toFixed(2) : 0));
        summary.maxDrawdownLong = summary.maxDrawdownLong.map(value => value.toFixed(2));
        summary.maxDrawdownShort = summary.maxDrawdownShort.map(value => value.toFixed(2));
        summary.maxReturnPositive = summary.maxReturnPositive.map(value => value.toFixed(2));
        summary.minReturnPositive = summary.minReturnPositive.map(value => value.toFixed(2));
        summary.maxReturnNegative = summary.maxReturnNegative.map(value => value.toFixed(2));
        summary.minReturnNegative = summary.minReturnNegative.map(value => value.toFixed(2));
    
        setResults(results);
        setSummary(summary);
    }, [tableData, tradingDay, holdingDays]);

    useEffect(() => {
        if (csvFile) {
            parseCSV(csvFile);
        }
    }, [csvFile]);

    useEffect(() => {
        if (tableData.length > 0) {
            handleCalculate();
        }
    }, [tableData, tradingDay, holdingDays, probabilityThreshold, maxDrawdownThreshold, handleCalculate]);

    const getCellStyle = (returnValue, isDrawdownLong = false, isDrawdownShort = false, relatedReturnValue = null) => {
        if (relatedReturnValue !== null) {
            if (relatedReturnValue >= 0 && isDrawdownLong) {
                return { backgroundColor: 'rgba(0, 255, 0, 0.2)', color: 'silver' };
            } else if (relatedReturnValue < 0 && isDrawdownShort) {
                return { backgroundColor: 'rgba(255, 0, 0, 0.2)', color: 'silver' };
            }
        } else {
            if (returnValue >= 0) {
                return { backgroundColor: 'rgba(0, 255, 0, 0.2)', color: 'silver' };
            } else {
                return { backgroundColor: 'rgba(255, 0, 0, 0.2)', color: 'silver' };
            }
        }
        return { opacity: 0.3 };
    };

    const getDrawdownCellStyle = (value, colIndex, rowValues, isPositive) => {
        const hasExceededThreshold = rowValues.slice(0, colIndex).some(val => val < -maxDrawdownThreshold);
        const probabilityValues = isPositive ? summary.probabilitiesPositive : summary.probabilitiesNegative;
        const firstValidProbabilityIndex = probabilityValues.findIndex(prob => prob >= probabilityThreshold);
        const isAfterFirstValidProbability = colIndex >= firstValidProbabilityIndex;
        const hasValidProbability = firstValidProbabilityIndex !== -1;
    
        if (value >= -maxDrawdownThreshold && !hasExceededThreshold && hasValidProbability && isAfterFirstValidProbability) {
            return { backgroundColor: 'rgba(255, 0, 0, 0.2)', color: 'silver' };
        }
        return {};
    };

    const getCellStyleSecondTable = (value, columnIndex, isDrawdown, isPositive, rowIndex) => {
        if (rowIndex < 3) {
            return {};
        }
    
        const probabilityValue = isPositive ? summary.probabilitiesPositive[columnIndex] : summary.probabilitiesNegative[columnIndex];
        const drawdownValue = isPositive ? summary.maxDrawdownLong[columnIndex] : summary.maxDrawdownShort[columnIndex];
        const meetsProbabilityThreshold = probabilityValue >= probabilityThreshold;
        const hasExceededThreshold = isPositive
            ? summary.maxDrawdownLong.slice(0, columnIndex).some(val => val < -maxDrawdownThreshold)
            : summary.maxDrawdownShort.slice(0, columnIndex).some(val => val < -maxDrawdownThreshold);
    
        if (meetsProbabilityThreshold && drawdownValue >= -maxDrawdownThreshold && !hasExceededThreshold) {
            if (isDrawdown) {
                return { backgroundColor: 'rgba(255, 0, 0, 0.2)', color: 'silver' };
            } else {
                return { backgroundColor: 'rgba(0, 255, 0, 0.2)', color: 'silver' };
            }
        }
        return { opacity: 0.5 };
    };



    return (
        <div className="best-trades-container">
            <div className="best-trades-header-toolbar">
                <div className="best-trades-toolbar-item">
                    <label>Trading Day of Year:</label>
                    <input
                        type="number"
                        value={tradingDay}
                        title="Enter a value between 1 and 250."
                        onChange={(e) => {
                            const value = parseInt(e.target.value);
                            if (value > 0 && value <= 360) {
                                setTradingDay(value);
                            }
                        }}
                    />
                </div>
                <div className="best-trades-toolbar-item">
                    <label>Holding Days:</label>
                    <input
                        type="number"
                        value={holdingDays}
                        title="Enter a value between 1 and 50."
                        onChange={(e) => {
                            const value = parseInt(e.target.value);
                            if (value > 0 && value <= 50) {
                                setHoldingDays(value);
                            }
                        }}
                    />
                </div>
                <div className="best-trades-toolbar-item">
                    <label>Min Probability:</label>
                    <input
                        type="number"
                        value={probabilityThreshold}
                        title="Enter a value between 30 and 100."
                        onChange={(e) => {
                            const value = parseInt(e.target.value);
                            if (value >= 30 && value <= 100) {
                                setProbabilityThreshold(value);
                            }
                        }}
                    />
                </div>
                <div className="best-trades-toolbar-item">
                    <label>Max Drawdown:</label>
                    <input
                        type="number"
                        value={maxDrawdownThreshold}
                        title="Enter a value between 1 and 15."
                        onChange={(e) => {
                            const value = parseInt(e.target.value);
                            if (value > 0 && value <= 15) {
                                setMaxDrawdownThreshold(value);
                            }
                        }}
                    />
                </div>
                <div className="best-trades-toolbar-buttons">
                    <button onClick={toggleTableVisibility}>
                        {isTableVisible ? 'Hide Table' : 'Show Table'}
                    </button>
                </div>
                <div className="best-trades-toolbar-buttons">
                    <button onClick={toggleTableVisibility1}>
                        {isTableVisible1 ? 'Hide Table' : 'Show Table'}
                    </button>
                </div>
            </div>
            <div className={`first-table ${isTableVisible ? 'visible' : ''}`}>
                <div className="best-trades-table-container">
                    <table className="best-trades-table">
                        <thead>
                            <tr>
                                <th>Year</th>
                                {Array.from({ length: holdingDays }, (_, i) => (
                                    <React.Fragment key={i}>
                                        <th>Return Day {i + 1} (%)</th>
                                        <th>Drawdown Long Day {i + 1} (%)</th>
                                        <th>Drawdown Short Day {i + 1} (%)</th>
                                    </React.Fragment>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {results.map((result, index) => (
                                <tr key={index}>
                                    <td>{result.year}</td>
                                    {Array.from({ length: holdingDays }, (_, i) => (
                                        <React.Fragment key={i}>
                                            <td style={getCellStyle(result[`returnDay${i + 1}`])}>
                                                {result[`returnDay${i + 1}`]}%<br />
                                                {result[`returnCalculationDay${i + 1}`]}
                                            </td>
                                            <td style={getCellStyle(result[`drawdownLongDay${i + 1}`], true, false, result[`returnDay${i + 1}`])}>
                                                {result[`drawdownLongDay${i + 1}`]}%<br />
                                                {result[`drawdownLongCalculationDay${i + 1}`]}<br />
                                            </td>
                                            <td style={getCellStyle(result[`drawdownShortDay${i + 1}`], false, true, result[`returnDay${i + 1}`])}>
                                                {result[`drawdownShortDay${i + 1}`]}%<br />
                                                {result[`drawdownShortCalculationDay${i + 1}`]}<br />
                                            </td>
                                        </React.Fragment>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className={`second-table ${isTableVisible1 ? 'visible' : ''}`}>
                <div className="best-trades-table-wrapper">
                    <div className="best-trades-table-container">
                        <table className="best-trades-table second-table">
                            <thead>
                                <tr>
                                    <th>Metric</th>
                                    {Array.from({ length: holdingDays }, (_, i) => (
                                        <th key={`day${i + 1}`}>Day {i + 1}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {[
                                    { label: 'Total Years', values: Array.from({ length: holdingDays }, () => summary.totalYears) },
                                    { label: 'Positive Returns', values: summary.positiveReturns },
                                    { label: 'Negative Returns', values: summary.negativeReturns },
                                    { label: 'Probability Positive (%)', values: summary.probabilitiesPositive, isProbability: true, isPositive: true },
                                    { label: 'Probability Negative (%)', values: summary.probabilitiesNegative, isProbability: true, isPositive: false },

                                    { label: 'Average Return Long', values: summary.avgReturnPositive, isPositive: true },
                                    { label: 'Min Return Long', values: summary.minReturnPositive, isPositive: true },
                                    { label: 'Max Return Long', values: summary.maxReturnPositive, isPositive: true },
                                    { label: 'Average Drawdown Long', values: summary.avgDrawdownPositive, isDrawdown: true, isPositive: true },
                                    { label: 'Max Drawdown Long', values: summary.maxDrawdownLong, isDrawdown: true, isPositive: true, isMaxDrawdown: true },

                                    { label: 'Average Return Short', values: summary.avgReturnNegative.map(value => -value), isPositive: false },
                                    { label: 'Min Return Short', values: summary.maxReturnNegative.map(value => -value), isPositive: false },
                                    { label: 'Max Return Short', values: summary.minReturnNegative.map(value => -value), isPositive: false },
                                    { label: 'Average Drawdown Short', values: summary.avgDrawdownNegative.map(value => value), isDrawdown: true, isPositive: false },
                                    { label: 'Max Drawdown Short', values: summary.maxDrawdownShort, isDrawdown: true, isPositive: false, isMaxDrawdown: true },
                                ].map((row, rowIndex) => (
                                    <tr key={rowIndex}>
                                        <td>{row.label}</td>
                                        {row.values.map((value, colIndex) => (
                                            <td
                                                key={colIndex}
                                                style={
                                                    row.isMaxDrawdown
                                                        ? getDrawdownCellStyle(value, colIndex, row.values, row.isPositive)
                                                        : getCellStyleSecondTable(value, colIndex, row.isDrawdown, row.isPositive, rowIndex)
                                                }
                                            >
                                                {value}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BestTrades;