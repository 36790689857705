import spinner from '../assets/svg/spinner.svg';
import '../css/standard.css';

function Spinner() {
    return (
        <div className="spinner-loading">
            <div>
                <img src={spinner} alt="Loading..." className='h-24'></img>
            </div>
        </div>
    )
}

export default Spinner;