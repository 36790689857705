
const CotSentiment = {
    headers : [
            'Sentiment Analysis',
        ],
    
    
    titles : [
        'What is Sentiment?',
        'Small Speculators as a Contrarian Indicator',

    ],

    media : [
        {type: 'image', src: '3CourseAdditionalMedien/fearandgreed.jpg', alt: 'Fear & Greed Index'},
        {type: 'image', src: '1CourseWeeklyMedien/small-speculators.png', alt: 'Small Speculators', caption: 'Chart provided by TradingView'},



        /*{type: 'image', src: 'pics/weeklyCotAnalysis/abcd-market.png', alt: '18 MA Pattern'},
        {type: 'video', src: 'videos/CotDataCotIndexAnalysis.mp4', alt: 'Zick Zack Trendanalyse'},
        {type: 'gif', src: 'path/to/your-gif.gif', alt: 'Gewinnen'}, // Direkter Link zum GIF-Bild
        // weitere Medieneinträge...*/
    ],


    texts : [
        {
            content: [
                {type: 'paragraph', text: 'Sentiment is an indicator that reflects the mood of market participants.'},
                {type: 'list', items: ['This can appear in various forms, such as the Fear & Greed Index, which summarizes the sentiment of market participants in an index.'] },
                {type: 'list', items: ['Sentiment can also appear in the form of surveys that reflect the mood of market participants.'] },
                {type: 'list', items: ['Even small investors (Small Speculators) can be considered a sentiment indicator.'] },
            ]
        },
        {
            content: [
                {type: 'paragraph', text: 'In COT analysis, we can use small speculators as a contrarian indicator.'},
                {type: 'paragraph', text: 'Small speculators are the small investors who trade in the futures markets.'},
                {type: 'paragraph', text: 'Small speculators are often known as contrarian indicators in market sentiment analysis. This perspective is based on the assumption that small investors are often positioned on the wrong side of the market, and their collective positioning can signal potential trend reversals.'},
                {type: 'paragraph', text: 'However, small investors are not always on the wrong side of the market. What we can find out through further research is that they are usually most bullish or bearish at market highs and lows.'}, 
                {type: 'paragraph', text: 'When a significant majority of small investors hold short positions (for example, when less than 25% hold short positions and the commercial traders’ indicator is above 75%, indicating that they are strongly positioned in the opposite direction with long positions), this contrast in positioning can be interpreted as a potential signal for a trend reversal. The rationale is that commercial traders, with their presumed market knowledge and hedging purposes, may be correctly positioned for an expected market movement, while small investors may be overly bearish just before an upward movement.' },
            ]
        }


        
        
        /*{
            content: [
                {type: 'paragraph', text: 'Hier ist ein Absatztext für das Zick Zack Pattern'},
                {type: 'list', items: ['Kurzfristig A', 'Mittelfristig B', 'Langfristig C'] }
            ]
        }*/
    ]
}

export default CotSentiment;