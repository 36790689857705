import React, { useEffect, useState } from 'react';

function Grid() {
  const descriptionTexts = {
    pictogram: [
      '../../Medien/pictogram/fundamental.png',
      '../../Medien/pictogram/timing.png',
      '../../Medien/pictogram/trademanagement.png',
      '../../Medien/pictogram/demoTrading.png',
      '../../Medien/pictogram/realTrading.png',
      '../../Medien/pictogram/psychology.png',
      '../../Medien/pictogram/broker.png',
      '../../Medien/pictogram/tradingview.png'
    ],

    text: [
      "Fundamental Analysis: Maximize Your Strategy with COT Data. Discover how to accurately interpret COT data and integrate it into your trading strategy for informed decision-making.",
      "Timing: Find the Optimal Entry Point. Learn how to effectively analyze the market and identify the perfect timing for your trades to maximize your chances of success.",
      "Trade & Risk Management: Optimize Your Trading Performance. Discover how to efficiently manage your trades to increase profits and minimize risks, ensuring full control over your portfolio.",
      "Demo Trading: Test Your Strategy Risk-Free. Take advantage of risk-free opportunities to test your trading strategies and develop a positive expectancy for your future trades.",
      "Live Trading: Execute Your Strategy Successfully. Learn how to apply your developed strategies in a real trading environment to consistently achieve your goals.",
      "Psychology: Stay Disciplined and Controlled. Explore techniques to keep your emotions in check and ensure a disciplined trading approach that brings long-term success.",
      "Choosing the Right Broker: Find the Ideal Broker for Your Success. Learn what criteria are crucial when selecting the right broker to best support your trading objectives.",
      "TradingView: Get all indicators for free with the purchase of our comprehensive Master Course."
    ]
  }

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % descriptionTexts.text.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [descriptionTexts.text.length, currentIndex]);

  const handleItemClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div>

      <div className="gridcontainer">

        {descriptionTexts.pictogram.map((imgPath, index) => (
          <div 
            key={index} 
            className={`griditem ${index === currentIndex ? 'active' : ''}`}
            onClick={() => handleItemClick(index)}
            >
            <figure>
              <img src={imgPath} alt={`item${index + 1}`} />
              {/* <figcaption>{descriptionTexts.text[index].split(":")[0]}</figcaption> */}
            </figure>
          </div>
        ))}
      </div>
      <div id="descriptions">
        <p id="descriptionText">{descriptionTexts.text[currentIndex]}</p>
      </div>
    </div>
  );
}

export default Grid;
