import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuthStatus } from '../hooks/useAuthStatus';
import { usePaymentStatus } from '../hooks/usePaymentStatus';
import { getAuth, signOut } from 'firebase/auth';
import { toast } from 'react-toastify';
import Spinner from './Spinner';
import '../css/navbar.css';

function Navigation({ header }) {
  const { loggedIn, checkingStatus } = useAuthStatus();
  const paymentStatus = usePaymentStatus();
  const location = useLocation();
  const navigate = useNavigate();

  const [isPaymentSucceeded, setIsPaymentSucceeded] = useState(false);

  // useEffect, um auf Änderungen von paymentStatus zu reagieren
  useEffect(() => {
    if (paymentStatus === 'succeeded') {
      setIsPaymentSucceeded(true);
    } else {
      setIsPaymentSucceeded(false);
    }
  }, [paymentStatus]); 


  const onLogout = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      navigate('/home');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const openNav = () => {
    if (!isPaymentSucceeded) {
      toast.error('This area is only accessible to customers of the Master Course.');
      navigate('/checkout');
      return; // Verhindere weitere Ausführung der Funktion
    }

    const sidenav = document.getElementById("mySidenav");
    const mainContent = document.getElementById("main");

    if (sidenav) {
      sidenav.style.width = "250px";
    }

    if (mainContent) {
      mainContent.style.marginLeft = "250px";
    }
  };

  function closeNav() {
    const sidenav = document.getElementById("mySidenav");
    const mainContent = document.getElementById("main");

    if (sidenav) {
          sidenav.style.width = "0";
        } else {
          console.error('Element with the ID "mySidenav" was not found.');
        }
    
        if (mainContent) {
          mainContent.style.marginLeft = "0";
        } else {
          console.error('Element with the ID "main" was not found.');
        }
      
  }

  function activeRoute(route) {
    return route === location.pathname ? { color: 'goldenrod' } : {};
  }

  function handleProtectedLinkClick(event, route) {
    if (paymentStatus !== 'succeeded') {
      event.preventDefault();
      navigate('/checkout');
    } else {
      closeNav();
      navigate('/welcome');
    }
  }

  if (checkingStatus) {
    return <Spinner />
  }

  return (
    <div className="head-nav">
        <header>
          {loggedIn && <>
              <h1 id="header-text">{ header }</h1>
              <button id="nav-button" onClick={openNav}><i id='nav-icon' className="fa-solid fa-bars"></i>Trading Course</button>
            </>
          }
        </header>

        {loggedIn && isPaymentSucceeded && (
        <nav id="mySidenav" className="sidenav">
          <button className="closebtn" onClick={closeNav}>&times;</button>
          <div className="dropdown-content" id="navbar-course">
            <ul>
              <li><Link to="/welcome" onClick={(e) => handleProtectedLinkClick(e, '/welcome')} style={activeRoute('/welcome')}>Introduction</Link></li>
              <li><Link to="/course/1" onClick={(e) => handleProtectedLinkClick(e, '/course/1')} style={activeRoute('/course/1')}>What are COT Data?</Link></li>
              <li><Link to="/course/2" onClick={(e) => handleProtectedLinkClick(e, '/course/2')} style={activeRoute('/course/2')}>Indicator Setup</Link></li>
              <li><Link to="/course/3" onClick={(e) => handleProtectedLinkClick(e, '/course/3')} style={activeRoute('/course/3')}>Weekly COT Signals</Link></li>
              <li><Link to="/course/4" onClick={(e) => handleProtectedLinkClick(e, '/course/4')} style={activeRoute('/course/4')}>COT Extremes</Link></li>
              <li><Link to="/course/5" onClick={(e) => handleProtectedLinkClick(e, '/course/5')} style={activeRoute('/course/5')}>COT Sentiment</Link></li>
              <li><Link to="/course/6" onClick={(e) => handleProtectedLinkClick(e, '/course/6')} style={activeRoute('/course/6')}>COT Bubble</Link></li>
              <li><Link to="/course/7" onClick={(e) => handleProtectedLinkClick(e, '/course/7')} style={activeRoute('/course/7')}>Market Price Structure</Link></li>
              <li><Link to="/course/8" onClick={(e) => handleProtectedLinkClick(e, '/course/8')} style={activeRoute('/course/8')}>ABCD Markets</Link></li>
              <li><Link to="/course/9" onClick={(e) => handleProtectedLinkClick(e, '/course/9')} style={activeRoute('/course/9')}>Seasonality & Cycles</Link></li>
              <li><Link to="/course/10" onClick={(e) => handleProtectedLinkClick(e, '/course/10')} style={activeRoute('/course/10')}>Weekly Analysis - Summary</Link></li>
              <li><Link to="/course/11" onClick={(e) => handleProtectedLinkClick(e, '/course/11')} style={activeRoute('/course/11')}>Price Patterns</Link></li>
              <li><Link to="/course/12" onClick={(e) => handleProtectedLinkClick(e, '/course/12')} style={activeRoute('/course/12')}>Stop-Loss Techniques</Link></li>
              <li><Link to="/course/13" onClick={(e) => handleProtectedLinkClick(e, '/course/13')} style={activeRoute('/course/13')}>Defining Profit Targets</Link></li>
              <li><Link to="/course/14" onClick={(e) => handleProtectedLinkClick(e, '/course/14')} style={activeRoute('/course/14')}>Proff, POIV Indicators</Link></li>
              <li><Link to="/course/15" onClick={(e) => handleProtectedLinkClick(e, '/course/15')} style={activeRoute('/course/15')}>Backtests</Link></li>
              <li><Link to="/course/16" onClick={(e) => handleProtectedLinkClick(e, '/course/16')} style={activeRoute('/course/16')}>RMM Bracket Order</Link></li>
              <li><Link to="/course/17" onClick={(e) => handleProtectedLinkClick(e, '/course/17')} style={activeRoute('/course/17')}>Contracts & Spreads</Link></li>
              <li><Link to="/course/18" onClick={(e) => handleProtectedLinkClick(e, '/course/18')} style={activeRoute('/course/18')}>FinViz</Link></li>
              <li><Link to="/course/19" onClick={(e) => handleProtectedLinkClick(e, '/course/19')} style={activeRoute('/course/19')}>Relative Strength Comparison</Link></li>
              <li><Link to="/course/20" onClick={(e) => handleProtectedLinkClick(e, '/course/20')} style={activeRoute('/course/20')}>Correlation</Link></li>
              <li><Link to="/course/21" onClick={(e) => handleProtectedLinkClick(e, '/course/21')} style={activeRoute('/course/21')}>Trading Plan / Journal</Link></li>
              <li><Link to="/course/22" onClick={(e) => handleProtectedLinkClick(e, '/course/22')} style={activeRoute('/course/22')}>Psychology</Link></li>
              <li><Link to="/course/23" onClick={(e) => handleProtectedLinkClick(e, '/course/23')} style={activeRoute('/course/23')}>WASDE Report</Link></li>
              <li><Link to="/course/24" onClick={(e) => handleProtectedLinkClick(e, '/course/24')} style={activeRoute('/course/24')}>Weather Report</Link></li>
              <li><Link to="/course/25" onClick={(e) => handleProtectedLinkClick(e, '/course/25')} style={activeRoute('/course/25')}>Broker</Link></li>
              {/*<li><Link to="/course/26" onClick={(e) => handleProtectedLinkClick(e, '/course/26')} style={activeRoute('/course/26')}>Examples</Link></li>*/}
              {/* Weitere Links hier, ebenfalls mit onClick={closeNav} */}
            </ul>
          </div>
        </nav>
        )}

        <nav id="nav-container" className="navbar-list">
            <ul>
              <li className="dropdown1">
                <Link to="#" className="dropbtn1">Menu</Link>
                <div className="dropdown-content1" id="navbar">
                  <Link to="/home" style={activeRoute('/home')}>Home</Link>
                  <Link to="/resources" style={activeRoute('/resoucres')}>Resources</Link>
                  <Link to="/seasonals" style={activeRoute('/seasonals')}>Seasonals</Link>
                  <Link to="/cot-data" style={activeRoute('/cot-data')}>COT Data</Link>
                  <Link to="/checkout" style={activeRoute('/checkout')}>Products</Link>
                  <Link to="/faq" style={activeRoute('/faq')}>FAQ</Link>
                  {loggedIn && <Link to="/profile" style={activeRoute('/profile')}>Profile</Link>}
                  {loggedIn && <Link to="/logout" onClick={onLogout} style={activeRoute('/logout')}>Log Out</Link>}
                  {!loggedIn && <Link to="/sign-in" style={activeRoute('/login')}>Sign In</Link>}
                  {!loggedIn &&<Link to="/sign-up" style={activeRoute('/sign-up')}>Sign Up</Link>}
                </div>
              </li>
            </ul>
        </nav>
    </div>
  );
}

export default Navigation;