const ProffPoiv = {
    headers : [
            'Proff & POIV Indicators',
        ],
    
        titles : [
            'Proff Indicator',
            'POIV Indicator',
        ],

    media : [
        {type: 'image', src: '2CourseDailyMedien/proffDivergence.png', alt: 'Proff Indicator', caption: 'Chart provided by TradingView'},
        {type: 'image', src: '2CourseDailyMedien/poivDivergence.png', alt: 'POIV Indicator', caption: 'Chart provided by TradingView'},




        /*{type: 'image', src: 'pics/weeklyCotAnalysis/abcd-market.png', alt: '18 MA Pattern'},
        {type: 'video', src: 'videos/CotDataCotIndexAnalysis.mp4', alt: 'Zick Zack Trendanalyse'},
        {type: 'gif', src: 'path/to/your-gif.gif', alt: 'Gewinnen'}, // Direkter Link zum GIF-Bild
        // weitere Medieneinträge...*/
    ],

    texts : [
            {
                content: [
                    {type: 'paragraph', text: 'The Proff Indicator (also known as ProGo by Larry Williams) measures the strength of the trend based on the daily highs and the daily low from 14 days ago. This difference is then scaled and adjusted by an offset of 50.'},
                    {type: 'list', items: ['(High - Low[14]) * 50 - 50'] },
                    {type: 'paragraph', text: 'With this indicator, we can measure the strength of the trend and identify potential reversal points, i.e., divergences between the price and the indicator.'},
                    {type: 'paragraph', text: 'These divergences help us get further confirmation on whether to enter a trade or not.'}, 
                    {type: 'download', text: 'Proff Indicator', href: 'https://www.tradingview.com/script/6HgLILUu-Momentum-PROFF-Larry-Williams/'}
                ]
            },
            {
                content: [
                    {type: 'paragraph', text: 'The POIV Indicator uses not only price data but also the open interest of traded futures.'},
                    {type: 'paragraph', text: 'POIV Calculation:'},
                    {type: 'list', items: [
                        'th and tl calculate the maximum and minimum between the previous closing price and the current high and low prices.',
                        'roc (Rate of Change) calculates the price change.',
                        'myobv (On-Balance Volume) accumulates OI values based on the price change.',
                        'poiv combines myobv and a calculated OI change based on the prices.',
                        'The last 14 days are also used to calculate the indicator.'
                    ]},
                    {type: 'paragraph', text: 'Both indicators help to find divergences that indicate a possible price reversal.'},
                    {type: 'paragraph', text: 'Caution! These divergences can occur during longer consolidation phases in the market.'},
                    {type: 'paragraph', text: 'During a consolidation, the market usually moves sideways as it tries to determine whether there is more selling or buying interest.'},
                    {type: 'download', text: 'POIV Indicator', href: 'https://www.tradingview.com/script/XItlvcRC-POIV/'}
                ]
            }
    
        
    /*{
        content: [
            {type: 'headline', text: 'Zick Zack Pattern'},
            {type: 'paragraph', text: 'Hier ist ein Absatztext für das Zick Zack Pattern'},
            {type: 'list', items: [
                'Kurzfristig A', 
                {type: 'strong', text: 'Mittelfristig B'}, // Fett gedruckt
                'Mittelfristig B', 
                'Langfristig C'
            ] }
            {type: 'download', text: 'Einstellungen für die Wochenanalyse', href: '1CourseWeeklyPictures/COTSettingsWeekly.png'}
        ]
    }*/
    ]
}

export default ProffPoiv;


