import React from 'react';

export const PercentChangeTable = ({ isTable2Visible, percentageChanges, maxColumns, usePreviousClose }) => (
    isTable2Visible && (
        <table className="table-percent-change">
            <thead>
                <tr>
                    <th>Year</th>
                    {usePreviousClose ? (
                        Array.from({ length: maxColumns - 1 }, (_, i) => (
                            <th key={i}>{i + 1} </th>
                        ))
                    ) : (
                        Array.from({ length: maxColumns }, (_, i) => (
                            <th key={i}>{i + 1}</th>
                        ))
                    )}
                </tr>
            </thead>
            <tbody>
                {Object.keys(percentageChanges).map((year, yearIndex) => (
                    <tr key={year}>
                        <td>{year}</td>
                        {percentageChanges[year].map((change, index) => (
                            <td key={index}>
                                <div>{change !== null ? change.change : ''}</div>
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    )
);

export const StatsTable = ({ isTable3Visible, percentageStats, maxColumns, usePreviousClose }) => (
    isTable3Visible && (
        <table className="table-stats">
        <thead>
            <tr>
                <th className="table-stats-header"></th>
                <th className="table-stats-header">% Sum All</th>
                <th className="table-stats-header">Total Count</th>
                <th className="table-stats-header">% Average All</th>
                <th className="table-stats-header">Positive Count</th>
                <th className="table-stats-header">% Sum Positive</th>
                <th className="table-stats-header">% Average Positive</th>
                <th className="table-stats-header">Negative Count</th>
                <th className="table-stats-header">% Sum Negative</th>
                <th className="table-stats-header">% Average Negative</th>
                <th className="table-stats-header">Probability</th>
            </tr>
        </thead>
            <tbody>
                {Array.from({ length: usePreviousClose ? maxColumns - 1 : maxColumns }, (_, i) => {
                    if (percentageStats.counts[i] === 0) {
                        return (
                            <tr key={i}>
                                <td>{i + 1}</td>
                                <td colSpan="10">No data</td>
                            </tr>
                        );
                    }

                    const positivePercentage = (percentageStats.positiveCounts[i] / percentageStats.counts[i]) * 100;
                    const negativePercentage = (percentageStats.negativeCounts[i] / percentageStats.counts[i]) * 100;
                    const higherPercentage = positivePercentage > negativePercentage ? positivePercentage : negativePercentage;
                    const isPositiveHigher = positivePercentage > negativePercentage;

                    const color = isPositiveHigher
                        ? positivePercentage > 60
                            ? '#095F92'
                            : 'green'
                        : negativePercentage > 60
                            ? '#ddad6a'
                            : 'rgb(147, 24, 24)';

                    return (
                        <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{percentageStats.sums[i].toFixed(2)}</td>
                            <td>{percentageStats.counts[i]}</td>
                            <td>{percentageStats.averages[i]}</td>
                            <td>{percentageStats.positiveCounts[i]}</td>
                            <td>{percentageStats.positiveSums[i].toFixed(2)}</td>
                            <td>{percentageStats.positiveAverages[i]}</td>
                            <td>{percentageStats.negativeCounts[i]}</td>
                            <td>{percentageStats.negativeSums[i].toFixed(2)}</td>
                            <td>{percentageStats.negativeAverages[i]}</td>
                            <td style={{ color, fontWeight: 'bold' }}>
                                {higherPercentage.toFixed(2)}%
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    )
);

export const CustomCalculationTable = ({ isTable4Visible, customPercentageChanges, maxColumns, columnSums }) => (
    Object.keys(customPercentageChanges).length > 0 && isTable4Visible && (
        <table className="table-custom-calculation">
            <thead>
                <tr>
                    <th>Jahr</th>
                    {Array.from({ length: maxColumns }, (_, i) => (
                        <th key={i}>{i + 1}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {Object.keys(customPercentageChanges).map((year, yearIndex) => (
                    <tr key={year}>
                        <td>{year}</td>
                        {customPercentageChanges[year].map((change, index) => (
                            <td key={index}>
                                <div>{change !== null ? `${change}%` : ''}</div>
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
            <tfoot>
                <tr>
                    <td>Summe</td>
                    {columnSums.map((sum, index) => (
                        <td key={index}>{sum.toFixed(2)}</td>
                    ))}
                </tr>
            </tfoot>
        </table>
    )
);