
const StoplossTechniques = {
    headers : [
            'Risk Management Stoploss Techniques',
        ],
        
        titles : [
            'Stoploss Techniques by Market Structure & 3 Bar Stoploss Technique',
            'Stoploss Technique by Market Structure',
            '3 Bar Stoploss Technique',
            'Combination of Stoploss Techniques',
            'Break-Even Technique',
            'Video: Stoploss Techniques'
        ],
    
        media : [
            {type: 'image', src: '2CourseDailyMedien/StoplossTechniques.png', alt: 'Stoploss Technique by Market Structure & 3 Bar Stoploss Technique'},
            {type: 'image', src: '2CourseDailyMedien/StoplossMarkttechnik.png', alt: 'Stoploss Technique by Market Structure', caption: 'Chart provided by TradingView'},
            {type: 'image', src: '2CourseDailyMedien/Stoploss3BarTechnik.png', alt: '3 Bar Stoploss Technique', caption: 'Chart provided by TradingView'},
            {type: 'image', src: '2CourseDailyMedien/StoplossTechnikKombiniert.png', alt: 'Combination of Stoploss Techniques', caption: 'Chart provided by TradingView'},
            {type: 'image', src: '2CourseDailyMedien/StoplossBreakEven.png', alt: 'Break-Even Technique', caption: 'Chart provided by TradingView'},
            {type: 'video', src: 'videos/stoploss.mp4', alt: 'Stoploss Techniques'},



        /*{type: 'image', src: 'pics/weeklyCotAnalysis/abcd-market.png', alt: '18 MA Pattern'},
        {type: 'video', src: 'videos/CotDataCotIndexAnalysis.mp4', alt: 'Zick Zack Trendanalyse'},
        {type: 'gif', src: 'path/to/your-gif.gif', alt: 'Gewinnen'}, // Direkter Link zum GIF-Bild
        // weitere Medieneinträge...*/
    ],


        texts : [
            {
                content: [
                    {type: 'paragraph', text: 'Stoploss Techniques by Market Structure:'},
                    {type: 'list', items: [
                        'Stoploss by market structure is oriented towards the short-term highs and lows of the price structure.', 
                        {type: 'strong', text: 'Long Positions: '},
                        'The stoploss is set below the last short-term low - 1 tick.',
                        'If the market closes above the last short-term high, the stoploss is moved to the last short-term low - 1 tick.',
    
                        {type: 'strong', text: 'Short Positions: '}, 
                        'The stoploss is set above the last short-term high + 1 tick.',
                        'If the market closes below the last short-term low, the stoploss is moved to the last short-term high + 1 tick.',
                    ] },
                    {type: 'paragraph', text: '3 Bar Stoploss Technique: '},
                    {type: 'list', items: [
                        {type: 'strong', text: 'Long Positions: '},
                        'If three higher closing prices are formed above the entry price, with each closing price higher than the previous highest closing price, the stoploss is moved to the low of the candle, which is the third counted candle backward from the new higher close.',
                        {type: 'strong', text: 'Short Positions: '},
                        'If three lower closing prices are formed below the entry price, with each closing price lower than the previous lowest closing price, the stoploss is moved to the high of the candle, which is the third counted candle backward from the new lower close.',
                    ] },
                ]
            },
            {
                content: [
                    {type: 'paragraph', text: 'Stoploss technique by market structure means using the short-term highs and lows as a reference point.'},
                    {type: 'paragraph', text: 'Once a new short-term low is formed in an uptrend, we wait for a closing price above the last short-term high.'},
                    {type: 'paragraph', text: 'The stoploss is then set below the low of the last short-term low and vice versa for short positions. (+/- 1 tick)'},
                    {type: 'paragraph', text: 'This technique is mainly used when we want to trade a trend. It is less suitable for range markets.'},
                ]
            },
            {
                content: [
                    {type: 'paragraph', text: 'The 3 Bar Stoploss Technique is a technique that is based on higher or lower closing prices. It can also be called a manual trailing stop.'},
                    {type: 'paragraph', text: 'Once three higher closing prices are formed, the stoploss is moved to the low of the first higher closing price and vice versa for short positions. (+/- 1 tick)'},
                    {type: 'paragraph', text: 'This technique is especially suitable for trend markets and can also be used in combination with the market structure technique.'},
                ]
            },
            {
                content: [
                    {type: 'paragraph', text: 'Combination of Stoploss Techniques: '},
                    {type: 'paragraph', text: 'Combining stoploss techniques can be useful to minimize risk and optimize the trade.'},
                    {type: 'paragraph', text: 'Pay attention to the last three dark green marked circles in the image. A circle always means a new lower closing price.'},
                    {type: 'paragraph', text: 'In this example, you can see that the last short-term high is lower than the high after the 3 Bar Stop Technique. The stoploss is then set to the last short-term high to maximize profits.'},
                ]
            },
            {
                content: [
                    {type: 'paragraph', text: 'Break-Even Technique: '},
                    {type: 'paragraph', text: 'Break-even means that the stoploss is moved to the entry price once the market has moved a certain number of points in the desired direction. This is useful as it minimizes risk and allows the trade to be closed without a loss if necessary.'},
                    {type: 'paragraph', text: 'When do I set the stoploss to break-even?'},
                    {type: 'list', items: [
                        'Basically, the 3 Bar Stop Technique can be used.',
                        'However, market volatility must always be considered.',
                        'When looking for trades, we look for trend markets as they promise the most profits. Tight range markets should be avoided as you can be quickly thrown out of the market and potential profits are rather limited.',
                        'In trend markets, the stoploss can be moved to break-even faster as the market can move in the desired direction more quickly.',
                        {type: 'strong', text: 'To avoid burning too much money in range markets, the following rule can be observed: '},
                        'If we notice that the market does not switch to a trend quickly enough and the market is still trading in the same range after 5 days, the stoploss can be moved to break-even as an emergency measure.',
                        {type: 'strong', text: 'Break-Even Consideration: '},
                        'It is important not to move the stoploss to break-even too early as the market also makes small corrections in trend markets.',
                        'For this reason, depending on market volatility, the break-even stoploss should be set according to the 3 Bar Stop Technique or market structure. If three higher closing prices have formed after our entry, we move the stop to the entry point (break-even). According to market structure, we would move the stop to break-even after a new short-term low has formed and the price is higher than the entry price. This description is for long trades. The reverse applies to short trades.',
                        'Learn to read and understand the market to apply the break-even technique optimally.'
                    ] },
                ]
            },
            {
                content: [
                    {type: 'paragraph', text: 'The video explains the different stoploss techniques and how they can be used to optimize the trade.'},
                ]
            },




        
        /*{
            content: [
                {type: 'paragraph', text: 'Hier ist ein Absatztext für das Zick Zack Pattern'},
                {type: 'list', items: ['Kurzfristig A', 'Mittelfristig B', 'Langfristig C'] }
                {type: 'download', text: 'Einstellungen für die Wochenanalyse', href: '1CourseWeeklyPictures/COTSettingsWeekly.png'}
            ]
        }*/
    ]
}

export default StoplossTechniques;