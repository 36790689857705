export default function Cancellation() {
    return (
        <div>
            <h1>Instruction for Cancellation &amp; Cancellation form</h1>

            <p>
                Consumers, i.e. any individual acting for purposes which are wholly or mainly outside those individual’s trade, business, craft or profession, are entitled to cancel any contract on the following conditions:
            </p>

            <h2>A. Instruction for Cancellation</h2>

            <h3>RIGHT TO CANCEL</h3>

            <p>You are entitled to cancel this contract without giving any reasons.</p>

            <p>The cancellation period is 14 days from the day of the conclusion of the contract.</p>

            <p>
                To exercise the right to cancel, you must inform us (Samuel Pietrowski, COTSeason.com, Mendelssohnstraße 11, 49762 Lathen, Germany, Phone.: 01717307259, e-mail: salamontraderinfo@gmail.com) of your decision to cancel this contract by a clear statement (e.g. a letter sent by post or e-mail). You may use the attached model cancellation form, but it is not obligatory.
            </p>

            <p>To meet the cancellation deadline, it is sufficient for you to send your communication concerning your exercise of the right to cancel before the cancellation period has expired.</p>

            <h3>Effects of cancellation</h3>

            <p>
                If you cancel this contract, we will reimburse to you all payments received from you, including the costs of delivery (except for the supplementary costs arising, if you choose a type of delivery other than the least expensive type of standard delivery offered by us) without undue delay and not later than fourteen days after the day on which we are informed about your decision to cancel this contract. We will make the reimbursement using the same means of payment as you used for the initial transaction, unless you have expressly agreed otherwise. In any event, you will not incur any fees as a result of the reimbursement.
            </p>

            <p>
                If you requested to begin the performance of services during the cancellation period, you shall pay us an amount which is in proportion to what has been performed until you have communicated us your cancellation from this contract, in comparison with the full coverage of the contract.
            </p>

            <h3>Exclusion and/or premature expiration of the right to cancel</h3>

            <p>
                In the case of a service contract, the right to cancel ceases to be available if we have fully performed the service and performance of the service only began after a request by you and with your acknowledgement that you would lose your right to cancel once the contract had been fully performed by us.
            </p>

            <p>
                In case of contracts for the supply of digital goods, the right of cancellation shall expire prematurely if we have started the performance of the contract after you have expressly agreed that we start the performance of the contract before the expiry of the cancellation period, and you have confirmed to us your knowledge that you lose your right of cancellation by agreeing to start the performance of the contract, and if we have provided you with a confirmation of the contract on a durable medium containing the content of the contract including the aforementioned conditions relating to the premature expiry of the right of cancellation.
            </p>

            <h2>B. Cancellation form</h2>

            <p>If you wish to cancel this contract, please complete and submit this form.</p>

            <p>
                Samuel Pietrowski<br />
                COTSeason.com<br />
                Mendelssohnstraße 11<br />
                49762 Lathen<br />
                Germany
            </p>

            <p>e-mail: salamontraderinfo@gmail.com</p>

            <p>
                I/We (*) hereby give notice that I/We (*) cancel my/our (*) contract of sale of the following goods (*) /for the supply of the following service (*),
            </p>

            <p>_______________________________________________________</p>

            <p>_______________________________________________________</p>

            <p>Ordered on (*) ____________ / received on (*) __________________</p>

            <p>________________________________________________________<br />
                Name of consumer(s)
            </p>

            <p>________________________________________________________<br />
                Address of consumer(s)
            </p>

            <p>________________________________________________________<br />
                Signature of consumer(s) (only if this form is notified on paper)
            </p>

            <p>_________________________<br />
                Date
            </p>
            <hr />
            <p>(*) Delete as appropriate</p>

            <h1>Widerrufsbelehrung (Dienstleistungen und digitale Inhalte) &amp; Widerrufsformular</h1>

            <p>
                Verbrauchern steht ein Widerrufsrecht nach folgender Ma&szlig;gabe zu, wobei Verbraucher jede nat&uuml;rliche Person ist, die ein Rechtsgesch&auml;ft zu Zwecken abschlie&szlig;t, die &uuml;berwiegend weder ihrer gewerblichen noch ihrer selbst&auml;ndigen beruflichen T&auml;tigkeit zugerechnet werden k&ouml;nnen:
            </p>

            <h2>A. Widerrufsbelehrung (Dienstleistungen und digitale Inhalte)</h2>

            <h3>Widerrufsrecht</h3>

            <p>Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gr&uuml;nden diesen Vertrag zu widerrufen.</p>

            <p>Die Widerrufsfrist betr&auml;gt vierzehn Tage ab dem Tag des Vertragsabschlusses.</p>

            <p>
                Um Ihr Widerrufsrecht auszu&uuml;ben, m&uuml;ssen Sie uns (Samuel Pietrowski, COTSeason.com, Mendelssohnstraße 11, 49762 Lathen, Deutschland, Tel.: 01717307259, E-Mail: salamontraderinfo@gmail.com) mittels einer eindeutigen Erkl&auml;rung (z. B. ein mit der Post versandter Brief oder E-Mail) &uuml;ber Ihren Entschluss, diesen Vertrag zu widerrufen, informieren.
            </p>

            <p>
                Sie k&ouml;nnen daf&uuml;r das beigef&uuml;gte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.
            </p>

            <p>
                Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung &uuml;ber die Aus&uuml;bung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
            </p>

            <h3>Folgen des Widerrufs</h3>

            <p>
                Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschlie&szlig;lich der Lieferkosten (mit Ausnahme der zus&auml;tzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, g&uuml;nstigste Standardlieferung gew&auml;hlt haben), unverz&uuml;glich und sp&auml;testens binnen vierzehn Tagen ab dem Tag zur&uuml;ckzuzahlen, an dem die Mitteilung &uuml;ber Ihren Widerruf dieses Vertrags bei uns eingegangen ist. F&uuml;r diese R&uuml;ckzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der urspr&uuml;nglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdr&uuml;cklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser R&uuml;ckzahlung Entgelte berechnet.
            </p>

            <p>
                Haben Sie bei Vertr&auml;gen &uuml;ber Dienstleistungen verlangt, dass die Dienstleistungen w&auml;hrend der Widerrufsfrist beginnen sollen, so haben Sie uns einen angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von der Aus&uuml;bung des Widerrufsrechts hinsichtlich dieses Vertrags unterrichten, bereits erbrachten Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht.
            </p>

            <h3>Ausschluss bzw. vorzeitiges Erl&ouml;schen des Widerrufsrechts</h3>

            <p>
                Das Widerrufsrecht erlischt bei Vertr&auml;gen &uuml;ber die Erbringung von Dienstleistungen vorzeitig, wenn wir die Dienstleistung vollst&auml;ndig erbracht haben und wir mit der Ausf&uuml;hrung der Dienstleistung erst begonnen haben, nachdem Sie dazu Ihre ausdr&uuml;ckliche Zustimmung gegeben haben und gleichzeitig Ihre Kenntnis davon best&auml;tigt haben, dass Sie Ihr Widerrufsrecht bei vollst&auml;ndiger Vertragserf&uuml;llung durch uns verlieren.
            </p>

            <p>
                Das Widerrufsrecht erlischt vorzeitig bei Vertr&auml;gen zur Bereitstellung von digitalen Inhalten, wenn wir mit der Vertragserf&uuml;llung begonnen haben, nachdem Sie ausdr&uuml;cklich zugestimmt haben, dass wir mit der Vertragserf&uuml;llung vor Ablauf der Widerrufsfrist beginnen, Sie uns Ihre Kenntnis davon best&auml;tigt haben, dass Sie durch Ihre Zustimmung mit Beginn der Vertragserf&uuml;llung Ihr Widerrufsrecht verlieren, und wir Ihnen eine Best&auml;tigung des Vertrags, in der der Vertragsinhalt einschlie&szlig;lich der vorgenannten Voraussetzungen zum vorzeitigen Erl&ouml;schen des Widerrufsrechts wiedergegeben ist, auf einem dauerhaften Datentr&auml;ger zur Verf&uuml;gung gestellt haben.
            </p>

            <h2>B. Widerrufsformular</h2>

            <p>Wenn Sie den Vertrag widerrufen wollen, dann f&uuml;llen Sie bitte dieses Formular aus und senden es zur&uuml;ck.</p>

            <p>An</p>

            <p>
                Samuel Pietrowski<br />
                COTSeason.com<br />
                Mendelssohnstraße 11<br />
                49762 Lathen<br />
                Deutschland
            </p>

            <p>E-Mail: salamontraderinfo@gmail.com</p>

            <p>
                Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag &uuml;ber den Kauf der folgenden Waren (*)/die Erbringung der folgenden Dienstleistung (*)
            </p>

            <p>_______________________________________________________</p>

            <p>_______________________________________________________</p>

            <p>Bestellt am (*) ____________ / erhalten am (*) __________________</p>

            <p>________________________________________________________<br />
                Name des/der Verbraucher(s)
            </p>

            <p>________________________________________________________<br />
                Anschrift des/der Verbraucher(s)
            </p>

            <p>________________________________________________________<br />
                Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)
            </p>

            <p>_________________________<br />
                Datum
            </p>

            <p>(*) Unzutreffendes streichen</p>
        </div>
    );
}
