import React, { useState, useEffect, useCallback } from 'react';
import { useAuthStatus } from '../hooks/useAuthStatus';
import '../css/course-frame.css';

const HelloTrader = () => {
  const { displayName } = useAuthStatus();
  const [currentIndex, setCurrentIndex] = useState(0);
  const currentYear = new Date().getFullYear();
  const [progress, setProgress] = useState(0);
  const interval = 10; // Dauer des Intervalls in Sekunden

  const headers = [`Hallo ${displayName},`]; // Hier sollte der Name des Benutzers eingefügt werden


  const titles = [
    'Hindsight Bias',
    'Eliminate Distractions',
    'Emotional Stability',
    'Focus on Learning and Development',
    'Develop a Positive Mindset',
    'Maintain Disciplined Trading',
    'Learn from Losses',
    'Practice Mindfulness',
    'Avoid Trading Based on News',
    'Think Like a Business in Trading',
    'Focus on the Long Term',
    'Stay Calm in Quiet Markets',
    'Simplify Your Approach',
    'Test and Backtest Your Strategy',
    'Accept Uncertainty in Trading',
    'Recognize Difficult Markets',
    'Adapt in Volatile Phases',
    'Be Cautious with Interest Rate Decisions',
    'Perception Bias',
];

const media = [
    {type: 'image', src: '/Medien/sprüchepics/hindsightBias.jpg', alt: 'Hindsight Bias'},
    {type: 'image', src: '/Medien/sprüchepics/störfaktoren.jpg', alt: 'Eliminate Distractions'},
    {type: 'image', src: '/Medien/sprüchepics/fomo.jpg', alt: 'Emotional Stability'},
    {type: 'image', src: '/Medien/sprüchepics/skills.jpg', alt: 'Focus on Learning and Development'},
    {type: 'image', src: '/Medien/sprüchepics/mindset.jpg', alt: 'Develop a Positive Mindset'},
    {type: 'image', src: '/Medien/sprüchepics/disziplin.jpg', alt: 'Maintain Disciplined Trading'},
    {type: 'image', src: '/Medien/sprüchepics/journal.jpg', alt: 'Learn from Losses'},
    {type: 'image', src: '/Medien/sprüchepics/stressed.jpg', alt: 'Practice Mindfulness'},
    {type: 'image', src: '/Medien/sprüchepics/news.jpg', alt: 'Avoid Trading Based on News'},
    {type: 'image', src: '/Medien/sprüchepics/businessman.jpg', alt: 'Think Like a Business in Trading'},
    {type: 'image', src: '/Medien/sprüchepics/longterm.jpg', alt: 'Focus on the Long Term'},
    {type: 'image', src: '/Medien/sprüchepics/chart.jpg', alt: 'Stay Calm in Quiet Markets'},
    {type: 'image', src: '/Medien/sprüchepics/kiss.jpg', alt: 'Simplify Your Approach'},
    {type: 'image', src: '/Medien/sprüchepics/strategy.jpg', alt: 'Backtest Your Strategy'},
    {type: 'image', src: '/Medien/sprüchepics/risk.jpg', alt: 'Accept Uncertainty in Trading'},
    {type: 'image', src: '/Medien/sprüchepics/rules.jpg', alt: 'Recognize Difficult Markets'},
    {type: 'image', src: '/Medien/sprüchepics/rules.jpg', alt: 'Adapt in Volatile Phases'},
    {type: 'image', src: '/Medien/sprüchepics/kalender.jpg', alt: 'Be Cautious with Interest Rate Decisions'},
    {type: 'image', src: '/Medien/sprüchepics/confirmationBias.jpg', alt: 'Confirmation Bias'},

]; // Hier sollten passende Bilder zum zufallsgenerierten Text eingefügt werden und dem Index des zufälligen Arrays zugeordnet werden.

const texts = [
    {
      content: [
        { type: 'paragraph', text: 'Looking back with hindsight bias - This means judging past events as if you had foreseen the outcome, which can lead to distorted evaluations. It is important to avoid this when analyzing past trades.'},
        { type: 'paragraph', text: 'Reflect objectively on past trades without letting the knowledge of the actual outcome influence your evaluation. Recognize and mitigate the influence of hindsight bias on your trading analysis.'}
        ]
    },
    {
        content: [
            { type: 'paragraph', text: 'Ensure a calm environment where you can focus on learning.' },
            { type: 'paragraph', text: 'Set up a quiet workspace and minimize distractions to create an effective learning environment.' }
        ]
    },
    {
        content: [
            { type: 'paragraph', text: 'Reflect on past life experiences that may influence your decisions. Focus on implementing your strategy.' },
            { type: 'paragraph', text: 'Consider how personal experiences can influence your emotions and develop strategies to act rationally despite emotional influences.' }
        ]
    },
    {
        content: [
            { type: 'paragraph', text: 'Focus your attention on learning and developing the skills necessary to implement a successful trading strategy.' },
            { type: 'paragraph', text: 'Dedicate time to acquiring new knowledge and sharpening your skills to improve your ability to implement a successful trading strategy.' }
        ]
    },
    {
        content: [
            { type: 'paragraph', text: 'Cultivate a positive mindset and mental resilience to stick to your strategy even under challenging market conditions.' },
            { type: 'paragraph', text: 'Practice maintaining a positive attitude and strengthen your mental resilience to stay true to your trading strategy during difficult market phases.' }
        ]
    },
    {
        content: [
            { type: 'paragraph', text: 'Stick to a disciplined trading strategy by building emotional capital from your experiences and backtesting your strategy.' },
            { type: 'paragraph', text: 'Build emotional resilience through your experiences and thorough backtesting of your strategy to maintain discipline in your trading approach.' }
        ]
    },
    {
        content: [
            { type: 'paragraph', text: 'Deal with losses by keeping a trading journal and drawing valuable lessons from them.' },
            { type: 'paragraph', text: 'Keep a trading journal to analyze losses and learn from them. Use them as an opportunity to improve your strategy and decision-making.' }
        ]
    },
    {
      content: [
        { type: 'paragraph', text: 'It is important to check your emotional state before making trading decisions. You can incorporate individual techniques like mindfulness into your routine to reduce emotional stress and stay focused.' },
        { type: 'paragraph', text: 'Make trading decisions based on thorough analysis and do not be influenced by short-term market fluctuations or intraday movements. Stick to your researched strategies and avoid reacting impulsively to emotional responses.' }
        ]   
    },
    {
        content: [
            { type: 'paragraph', text: 'Be a trader who relies on a solid strategy rather than reacting impulsively to news.' },
            { type: 'paragraph', text: 'Focus on implementing your strategy and do not make impulsive decisions based on current news. Stick to your well-defined trading plan.' }
        ]
    },
    {
        content: [
            { type: 'paragraph', text: 'View price data as a tool to assess the health of the economy. Trading is not gambling but systematic action based on a deep understanding of the market.' },
            { type: 'paragraph', text: 'View the financial market as an economic indicator. Analyze price movements to understand economic trends and make informed trading decisions.' }
        ]
    },
    {
        content: [
            { type: 'paragraph', text: 'Do not chase short-term gains. Follow a clear strategy based on market understanding and a well-functioning trading system.' },
            { type: 'paragraph', text: 'Instead of being swayed by daily market fluctuations, develop a strategy that aligns with your long-term financial goals.' }
        ]
    },
    {
        content: [
            { type: 'paragraph', text: 'Prioritize stable markets for consistent gains. Exciting, impulsive market behavior can be risky. Patience and rational decisions are crucial.' },
            { type: 'paragraph', text: 'In calm market conditions, stick to your established strategy and avoid impulsive actions driven by short-term fluctuations.' }
        ]
    },
    {
        content: [
            { type: 'paragraph', text: 'Less is more with strategies and indicators. Focus on one strategy and appropriate indicators to avoid confusion. Keep It Stupid Simple (KISS).' },
            { type: 'paragraph', text: 'Choose a simple yet effective trading strategy. Complexity can lead to confusion and impair your ability to make informed decisions.' }
        ]
    },
    {
        content: [
            { type: 'paragraph', text: 'Review and statistically test each strategy and additional indicators to ensure profitability. Avoid looking back with bias (hindsight bias) and strive for a robust set of rules.' },
            { type: 'paragraph', text: 'Test a new strategy rigorously with historical data before implementing it to ensure its effectiveness and minimize the influence of hindsight bias.' }
        ]
    },
    {
        content: [
            { type: 'paragraph', text: 'No one can predict the future, so trade in uncertainty. Firm rules and good risk management (RMM) are essential.' },
            { type: 'paragraph', text: 'Acknowledge that market outcomes are unpredictable. Set rules for risk management to handle uncertainties and protect your capital.' }
        ]
    },
    {
        content: [
            { type: 'paragraph', text: 'The art is to recognize when the market is challenging. Stick to clear rules and solid risk management, especially in uncertain times.' },
            { type: 'paragraph', text: 'During volatile periods, adhere to your established rules and risk management strategies to navigate challenging market conditions.' }
        ]
    },
    {
        content: [
            { type: 'paragraph', text: 'In challenging markets, strict rules like COT rules with 18MA and stops based on market trends or supertrend can be an option.' },
            { type: 'paragraph', text: 'During turbulent market phases, consider adopting stricter trading rules to adapt to increased volatility and minimize risks.' }
        ]
    },
    {
        content: [
            { type: 'paragraph', text: 'Avoid trading indices or currencies before interest rate decisions to prevent unpredictable market reactions.' },
            { type: 'paragraph', text: 'Be cautious before major economic events like interest rate decisions to minimize exposure to unpredictable market shifts.' }
        ]
    },
    {
        content: [
            { type: "paragraph", text: "Looking at information with bias - In commodity trading, it can be easy to only perceive or seek information that confirms your existing beliefs. A typical example is using COT data (Commitment of Traders) to support your trading decisions."},
            { type: "paragraph", text: "Reflect objectively on COT data and other market data without letting your existing beliefs influence your evaluation. Recognize and mitigate the influence of confirmation bias on your trading decisions."}
      ]      
    }
];

  const navigate = useCallback((direction) => {
    setCurrentIndex(prevIndex => {
      let nextIndex = prevIndex;
      if (direction === 'next') {
        nextIndex = (prevIndex + 1) % titles.length; // Geht zum nächsten Index, fängt von vorne an, wenn das Ende erreicht ist
      } else if (direction === 'prev') {
        nextIndex = (prevIndex - 1 + titles.length) % titles.length; // Geht zum vorherigen Index, geht zum Ende, wenn der Anfang erreicht ist
      }
      return nextIndex;
    });
    setProgress(0); // Fortschrittsbalken zurücksetzen
  }, [titles.length]);

  const renderArrows = () => {
    return (
      <>
        {currentIndex > 0 && <i id="prev" className="fa-solid fa-arrow-left" onClick={() => navigate('prev')}></i>}
        {currentIndex < titles.length - 1 &&<i id="prev" className="fa-solid fa-arrow-right" onClick={() => navigate('next')}></i>}
      </>
    );
  };

  useEffect(() => {
    // Startet den Timer für den Fortschrittsbalken
    const progressTimer = setInterval(() => {
      setProgress(prevProgress => {
        const newProgress = prevProgress + 10000;
        if (newProgress >= 200000) {
          // Wenn der Fortschritt 100% erreicht, stoppen wir den Timer
          clearInterval(progressTimer);
          // Und navigieren sofort zum nächsten Inhalt
          setCurrentIndex(prevIndex => prevIndex + 1);
          navigate('next');
        }
        return newProgress < 200000 ? newProgress : 200000;
      });
    }, 2000);

    // Bereinigungsfunktion, um den Timer zu löschen, wenn die Komponente unmountet wird
    return () => clearInterval(progressTimer);
  }, [currentIndex, navigate]); // Abhängigkeiten aktualisiert, um `navigate` und `currentIndex` einzuschließen



  return (
    <main className='grid-container'>

      <section id="course-text" className="grid-item">
        <h1>{headers[0]}</h1>
        <h1 style={{textAlign: 'left'}}>Welcome to our trading course!</h1>
        <p>We are delighted that you have chosen this path and are happy to accompany you on your learning journey.</p>
        <p>On the left side, you will find the navigation bar with all the course topics in the correct order. Our tip: Work through the units step by step and take enough time to put what you have learned into practice. This way, you will optimally internalize the knowledge.</p>
        <p>Throughout the course, we will also provide you with all the important links and indicators you need for your trading. If you have any questions or want to exchange ideas with other course participants, feel free to join our <button style={{backgroundColor: 'white', border: '1px white solid', borderRadius: '5px'}}><a href="https://t.me/+6BgYDNreBEBmYTNi">Telegram Channel</a></button>. There, you can discuss the theory with like-minded people and ask our team questions directly.</p>
        <p>What is needed: You need a TradingView Essential subscription. </p>
        <p>Good luck and have fun learning!</p>
      </section>

      <section id="course-visualisation" className="grid-item" style={{overflow: 'auto'}}>
        <h2 id='topic'>{titles[currentIndex]}</h2>
        <div id="hello-media">
          {media[currentIndex].type === 'image' && <img src={media[currentIndex].src} alt={media[currentIndex].alt} />}
        </div>

        <div id="text" style={{width: '80%'}}>
          {texts[currentIndex].content.map((text, index) => (
            <p key={index}>{text.text}</p>
          ))}

          <section className="grid-item">
            <progress value={progress} max={interval * 20000}></progress>
          </section>
          {renderArrows()}

        </div>
      </section>
      <section className="grid-item">
        <div className='course-footer'>
          <p>&copy; <span id="year">{currentYear}</span> by COT Season</p>
        </div>
      </section>
    </main>
  );
};

export default HelloTrader;


