import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Navigation from './js/course-navigation';
import HelloTrader from './content/0helloTrader';
import CourseUnit from './js/CourseUnit';
import ForgotPassword from './pages/ForgotPassword';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Logout from './pages/Logout';
import Profile from './pages/Profile';
import AppLanding from './landingComponents/AppLanding';
import Faq from './pages/Faq';
import PrivateRoute from './js/PrivateRoute';
import Checkout from './components/Checkout';
import Resources from './pages/Resources';
import CotData from './pages/CotData';
import Seasonals from './pages/Seasonals';
import BestTrades from './pages/BestTrades';
import TermsConditions from './pages/TermsConditions';
import Impressum from './pages/Impressum';
import PrivacyPolicy from './pages/PrivacyPolicy';


function App() {
  return (
    <Router>
      <>
        <Navigation />
        <Routes>
          {/* Public Routes */}
          <Route path="/home" element={ <AppLanding /> } />

          <Route path="/resources" element={<Resources />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/seasonals" element={<Seasonals />} />
          <Route path="/best-trades" element={<BestTrades />} />
          <Route path="/cot-data" element={<CotData />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          
          
          {/* Private Routes */}
          <Route element={<PrivateRoute />}>
            <Route path="/profile" element={<Profile />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/welcome" element={<HelloTrader />} />
            <Route path="/course/:courseId" element={<CourseUnit />} />
          </Route>

          {/* Fallback-Routen */}
          <Route path="*" element={<Navigate to="/home" replace />} />

        </Routes>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          rtl={false}
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </>
    </Router>
  );
}

export default App;
