
const AbcdMarkets = {

    headers : [
            'Trend Structure Analysis and Market Trend Categorization',
        ],
    
        titles : [
            'Support and Resistance Lines with COT Signals',
            'ABCD Markets for Trend Definition',
            'ABCD Markets for Trend Definition and Subjectivity',
            'ABCD Markets for Trend Definition Example',
            'ABCD Markets for Trend Definition Example',
        ],
    
        media : [
            {type: 'image', src: '1CourseWeeklyMedien/U_W-Signals.png', alt: 'Support and Resistance Lines with COT Signals', caption: 'Chart provided by TradingView'},
            {type: 'image', src: '1CourseWeeklyMedien/ABCDMarkets.png', alt: 'ABCD Markets for Trend Definition'},
            {type: 'image', src: '1CourseWeeklyMedien/ABCDMarketDefinitions.png', alt: 'ABCD Markets for Trend Definition', caption: 'Chart provided by TradingView'},
            {type: 'image', src: '1CourseWeeklyMedien/abcdExample1.png', alt: 'ABCD Markets for Trend Definition Example', caption: 'Chart provided by TradingView'},
            {type: 'image', src: '1CourseWeeklyMedien/abcdExample2.png', alt: 'ABCD Markets for Trend Definition Example', caption: 'Chart provided by TradingView'}
        ],

    texts : [
        {
            content: [
                {type: 'paragraph', text: 'Unterstützungs- und Widerstandsniveaus stimmen mit den Niveaus überein, an denen zuvor Kauf oder Verkaufs Signale aufgetreten sind.'},
                {type: 'paragraph', text: 'Insofern Signale auf dem selben Niveau auftreten, an dem zuvor Kauf- oder Verkaufssignale aufgetreten sind, kann dies als Unterstützung oder Widerstandsniveau betrachtet werden. Dabei ist zu beachten das dieses Niveau nocht nicht von den Preisbewegungen durchbrochen wurde.'},
                {type: 'list', items: ['Es benötigt mindestens ein vorrangegangenes COT Kauf- oder Verkaufssignal auf dem selben Niveau, um ein Unterstützung oder Widerstandsniveau zu kennzeichnen.'] }
            ]
        },
        {
            content: [
                {type: 'paragraph', text: 'ABCD Märkte sind Trenddefinitionen, um die Richtung des Marktes zu bestimmen.'},
                {type: 'list', items: ['A Märkte sind COT Signale in Trendrichtung. Dies sind die am besten zu handelnde Signale.'] },
                {type: 'list', items: ['B Märkte sind Rangemärkte. Das bedeutet, dass sich der Markt konsolidiert um Angebot und Nachfrage neu zu bestimmen. Meist treten Konsolidierungsphasen nach stärkeren Trendmärkten auf. In dieser Phase schwankt der Preis in einer Range. Interessant sind in B Märkten nur Kauf oder Verkaufssignale die an Widerstands- bzw. Unterstützungsniveaus entstehen, wobei die Range zwischen Support und Resistance groß genug sein muss um ein Tradesetup zu generieren und auch handeln zu können. Dazu später mehr.' ] },
                {type: 'list', items: ['C Märkte sind COT Signale die gegen den Trend entstehen. Um gegen den Trend zu handeln, muss zuvor ein COT Signal, aus selben Niveau und in die selben Richtung entstanden sein, welches vom Preis noch nicht durchbrochen wurde.'] }, 
                {type: 'list', items: ['D Märkte sind Verkaufssignale gegen den Trend und werden nicht gehandelt.'] }
            ]
        },
        {
            content: [
                {type: 'paragraph', text: 'Manchmal ist es schwer zu erkennen, ob ein Markt in einem A, B oder C Markt ist. In solchen Fällen ist es wichtig, die Marktstruktur zu analysieren bzw. ein Marktverständnis der Preisbewegungen zu entwickeln.'},
                {type: 'list', items: ['Das erste Kaufsignal ist ein A Markt, da es ein COT Signal in Trendrichtung ist.'] },
                {type: 'list', items: ['Das erste Verkaufssignal ist gegen den Trend. In den letzten 3 Jahren gab es auch kein weiteres Verkaufssignal auf diesem Niveau um einen Widerstand zu markieren. Daher ist dies ein D-Markt.'] },
                {type: 'list', items: ['Das zweite Verkaufssignal könnte einen C Markt definieren, da es vorher ein höheres COT Signal gegen den Trend gab und ein Preistechnisches niedrigeres Hoch ist. Allerdings befinden sich der Markt immer noch in einem Aufwärtstrend. Gegen den Trend zu handeln kann sehr risikoreich sein. Um ein Signal zu handeln ist es wichtig eine Trendanalyse im Tageschart durchzuführen.'] },
                {type: 'list', items: ['Das dritte Verkaufssignal ist ein C Markt, da es ein vorrangegeangenes COT Signal auf dem selben Niveau (Widerstand) hat und am oberen Kanal Widerstand enstanden ist. Da ein Signal 6 Wochen lang gültig ist, müssen wir im Tageschart die Trendstruktur weiter untersuchen.'] },
                {type: 'list', items: ['Das zweite Kaufsignal ist könnte ein A Markt sein, wenn man die längere Abwärtsphase als bullische Konsolidierung betrachet. Der nach unten gerichtete Kanal in einem Aufwärtstrend wird als bullische Flagge bezeichnet. Es könnte aber auch als B-Markt interpretiert werden, wenn der Markt als überkauft gilt und man eine längere Konsolidierung erwartet. Manchmal kann es schwer sein den Markt richtig zu bewerten. Es benötigt Zeit und Marktverständnis.'] },
                {type: 'paragraph', items: ['Manchmal kann die Definition eines Marktes, je nach Marktverständnis, subjektiv sein. Im Verlauf des Kurses werden wir aber weitere Regeln dazu lernen.'] },
            ]
        },
        {
            content: [
                {type: 'paragraph', text: 'In diesem Beispiel haben wir einen bärischen Longterm downtrend mit einem 1 Jahre langen B Rangemarket. In diesem Abwärtstrend kommen immer wieder Kaufsignale wobei der Markt weiter fällt. Dies kann es manchmal schwer machen den Markt richtig zu interpretieren. Der einfache gleitende Durschnitt von 52 Wochen kann uns einen ersten Eindruck vermitteln. In diesem Fall ist sie fallend. Des weiteren können wir die kurz-, mittel- und langfristigne Tiefs und Hoch einzeichenn um einen besseren Eindruck zu gewinnne.'},
                {type: 'list', items: [
                    'Das erste und zweite Kaufsignal ist ein D Markt, da es gegen den Trend ist und kein Kaufsignal auf dem selben Niveau in den letzten 3 Jahren vorhanden ist.',
                    'Das dritte Kaufsignal ist ein C Markt, da es ein vorrangegeangenes COT Signal auf dem selben Niveau (Unterstützung) hat. C Märkte können mit Vorsicht gehandelt werden, insofern die Markstruktur ein Kaufsignal generiert. C Märkte sind risikoreich und sollten vorischtig gehandelt werden. Gerade dann wenn ein Mark aus einem längeren Trend kommt, benötigt es eine Zeit der Konsolidierung damit ein neuer Trend entstehen kann.',
                    'Das vierte Kaufsignal ist nun ein B Mark da wir bereits eine kleine Range erkennen können und zuvor weitere Kaufsignale auf ungefähre dem gleichne Niveau entstanden sind. Zu diesem Zeitpunkt zeichnet der Markt jedoch weiterhin tiefere Tiefs und Hochs.', 
                    'Das erste Verkaufssignal ist innerhalb der Range und kann einerseits als B Markt gekennzeichnet werden, aber zu diesem Zeipunkt auch noch als A Markt. Es befindet sich in Haupttrendrichtung und es sind nur tiefere Hochs zu finden.',
                    'Das zweite Verkaufssignal hat ein höheres Hoch aber ist weiterhing in Trendrichtung da es bisher nur ein kurzfristiges Hoch in einem langfristigen Abwärtstrend ist. Es ist ein B Markt mit Signal in Haupttrendrichtung in einer kleinen Range am Top. Diese Signal sind den vorherigen B Markt Kaufsignalen zu bevorzugen. Da es aber in einer Range ist, sollte es auch im Handel eher vorsichtig betrachtet werden.',
                    'Die nächsten beiden Kaufsignal sind wiederum an der Unterstützung mit weiteren Kaufsignalen auf selben Niveau. Treten immer öfter Gegensignale gegen den Haupttrend auf ist die Wahrscheinlichkeit eines Trendwechsels höhern. Es bildet sich nach dem zweiten Signal auch ein höheres Tief aus und der Preis durchbricht das letzte kurzfristige Hoch nach oben. Da das Signal zu diesem Zeitpunkt nicht mehr gültig kann es nicht mehr gehandelt werden.',
                    'Für Fortgeschrittene Trader kann es dennoch interessant sein, da die Commercials Net Positonen zu diesem Zeitpunkt ein Extrem Wert Netto Long ausbilden und bereits mehrere Signale auf dem selben Niveau waren, ist die Wahrscheinlichkeit eines Trendwechsels höher. Es ist jedoch wichtig zu beachten, dass der Markt sich in einer Range befindet und es noch keine klare Trendrichtung gibt. Es ist daher ratsam, den Markt weiter zu beobachten und zu analysieren.',
                    'Zuletzt haben wir noch ein Verkaufssignal in die Haupttrendrichtung mit einem höheren Hoch an einem Widerstand mit einem langen Doch und einer negativen Wochenkerze. Finden wir im Tageschart einen guten Einstiegspunkt, können wir das Signal handeln.'
                ]}
            ]
        },
        {
            content: [
                {type: 'paragraph', text: 'In diesem Beispiel soll der Supertrend ein wenig verdeutlicht werden.'},
                {type: 'list', items: [
                    'Der Markt befindet sich seit mehreren Jahren in einem Abwärtstrend. A Signale in Trendrichtung sind die besten Signale.',
                    'Nach dem ersten eingezeichneten A Markt Verkaufssignal entsteht ein Kaufsignal (C-Market) gegen den Trend und wird in der vierten Wochen nach unten durchbrochen. Da der Haupttrend stark bärisch ist, kann es als Contra Supertrend Signal auch bärisch behandelt werden.',
                    'Nachdem der Wochenkurs unterhalb des Tiefs der Kaufsignalkerze geschlossen hat, kann dieses Signal im Tageschart Short gehandelt werden.',
                    'Das gleiche gilt auch für das D Markt Kaufsignal mit dem zweiten eingezeichneten Supertrend.',
                    'Beide Signale sind mit weiteren bärischen Wochenkerzen bestätigt worden.'

                ]}
            ]
        }

        
        
        /*{
            content: [
                {type: 'paragraph', text: 'Hier ist ein Absatztext für das Zick Zack Pattern'},
                {type: 'list', items: ['Kurzfristig A', 'Mittelfristig B', 'Langfristig C'] }
            ]
        }*/
    ]
}

export default AbcdMarkets;

