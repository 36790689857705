const MarketPriceStructure = {
    headers : [
        'Market Structure Analysis',
    ],

    titles : [
        'Overview',
        'Candle Information',
        'Inside & Outside Bars',
        'Short-term Highs and Lows (STL, STH)',
        'Inside & Outside Bars in Swing Highs or Lows (STL, STH)',
        'Medium-term Highs and Lows (MTL, MTH)',
        'Long-term Highs and Lows (LTL, LTH)',
        'Short- and Medium-term Highs and Lows with Structure Break (STL, STH, MTL, MTH)',
        'Short-, Medium-, and Long-term Highs and Lows (STL, STH, MTL, MTH, LTL, LTH)',
    ],

    media : [
        {type: 'image', src: '2CourseDailyMedien/analysis.jpg', alt: 'Course Overview'},
        {type: 'image', src: '2CourseDailyMedien/zick-zack/ohlc.png', alt: 'Candle Information', caption: 'Chart provided by TradingView'},
        {type: 'image', src: '2CourseDailyMedien/zick-zack/insideOutsideExample.png', alt: 'Inside Outside Bars Example', caption: 'Chart provided by TradingView'},
        {type: 'image', src: '2CourseDailyMedien/zick-zack/shortterm_high_low.png', alt: 'Short-term Highs and Lows', caption: 'Chart provided by TradingView'},
        {type: 'image', src: '2CourseDailyMedien/zick-zack/inside_outside.png', alt: 'Short-term Highs and Lows with Inside & Outside Bars', caption: 'Chart provided by TradingView'},
        {type: 'image', src: '2CourseDailyMedien/zick-zack/medium-term-highlow.png', alt: 'Medium-term Highs and Lows', caption: 'Chart provided by TradingView'},
        {type: 'image', src: '2CourseDailyMedien/zick-zack/longtermHighLow.png', alt: 'Long-term Highs and Lows'},
        {type: 'image', src: '2CourseDailyMedien/zick-zack/midterm_break_structure.png', alt: 'Structure Break in Short- and Medium-term Highs and Lows', caption: 'Chart provided by TradingView'},
        {type: 'image', src: '2CourseDailyMedien/zick-zack/long-mid-term-high-low.png', alt: 'Long-, Medium-, and Short-term Highs and Lows', caption: 'Chart provided by TradingView'},
    ],

    texts : [
        {
            content: [
                {type: 'paragraph', text: 'Trend structure analysis is a method for identifying the trend of a market. It is based on the analysis of the highs and lows of a market.'},
                {type: 'paragraph', text: 'The highs and lows are divided into three categories: '},
                {type: 'list', items: ['Short-term highs and lows (STL, STH)', 'Medium-term highs and lows (MTL, MTH)', 'Long-term highs and lows (LTL, LTH)'] }
            ]
        },
        {
            content: [
                {type: 'paragraph', text: 'A candle is a formation on a chart that represents the price movement of a market over a specific period.'},
                {type: 'paragraph', text: 'It consists of a body and two wicks. The body shows the opening and closing prices of the market, while the wicks represent the highest and lowest prices within the period.'},
            ]
        },
        {
            content: [
                {type: 'paragraph', text: ''},
                {type: 'list', items: [
                    'Inside bar: The candle is entirely within the range of the previous candle. An inside bar indicates consolidation or a brief pause in the trend.', 
                    'Outside bar: The high and low of the current candle are outside the range of the previous candle. An outside bar usually indicates a strong market movement and can signal a trend reversal or continuation.'
                ]},
            ]
        },
        {
            content: [
                {type: 'paragraph', text: ''},
                {type: 'list', items: [
                    'A short-term high (STH) has two lower highs on both sides.',  
                    'A short-term low (STL) has two higher lows on both sides.',
                    'Inside bars are ignored as the market is in a brief consolidation and we cannot define a high or low.',
                    'Outside bars must be observed as the market is in a strong movement and can signal a trend reversal or continuation.',
                ]}
            ]
        },
        {
            content: [
                {type: 'paragraph', text: 'Inside bars and outside bars in swing highs or lows are very important for trend structure analysis. They can signal a trend reversal or continuation.'},
                {type: 'list', items: [
                    'Inside bars and outside bars are not counted to define a high or low. The next candle must be observed to define a swing high or low.'
                ] }
            ]
        },
        {
            content: [
                {type: 'paragraph', text: 'A medium-term high (MTH) has two lower short-term highs (STH) on both sides.'},
                {type: 'paragraph', text: 'A medium-term low (MTL) has two higher short-term lows (STL) on both sides.'}, 
            ]
        },
        {
            content: [
                {type: 'paragraph', text: 'A long-term high (LTH) has two lower medium-term highs (MTH) on both sides.'},
                {type: 'paragraph', text: 'A long-term low (LTL) has two higher medium-term lows (MTL) on both sides.'},
                {type: 'paragraph', text: 'When we know the trend of a market, we can use this information to trade in the direction of the trend.'},
            ]
        },
        {
            content: [
                {type: 'list', items: [
                    'As long as higher short-term lows (STL) exist in an uptrend, we can trade in the direction of the trend.',
                    'As long as lower short-term highs (STH) exist in a downtrend, we can trade in the direction of the trend.',
                    'A structure break can indicate a trend reversal or a longer consolidation phase.',
                    'A structure break occurs when the closing price in an uptrend is below the last short-term low (STL) and a lower short-term high (STH) has been formed.',
                    'A structure break occurs when the closing price in a downtrend is above the last short-term high (STH) and a higher short-term low (STL) has been formed.',
                    'Pay attention to when and where the structure break occurs. In a strong trend, this could indicate consolidation, after which the trend continues. In a market that is already in a range, the structure break could be a better signal for a trend reversal.',
                ]}
            ]
        },
        {
            content: [
                {type: 'paragraph', text: 'Trend structure analysis is a method for identifying the trend of a market. It is based on the analysis of the highs and lows of a market.'},
                {type: 'paragraph', text: 'The highs and lows are divided into three categories: '},
                {type: 'list', items: [
                    'Short-term highs and lows (STL, STH)', 
                    'Medium-term highs and lows (MTL, MTH)', 
                    'Long-term highs and lows (LTL, LTH)'
                ]},
                {type: 'paragraph', text: 'Larry Williams emphasizes in his strategies the importance of trading in the direction of the trend as long as it is intact – recognizable by higher short-term lows (STL) in an uptrend or lower short-term highs (STH) in a downtrend. A structure break, where the closing price falls below the last short-term low (STL) in an uptrend or above the last short-term high (STH) in a downtrend, can indicate a trend reversal or a longer consolidation.'},
                {type: 'paragraph', text: 'It is particularly important to analyze the context of the structure break. In a strong trend, it could merely signal consolidation, after which the trend continues. In a market that is already in a range, however, the structure break could be a stronger signal for a trend reversal.'},
                {type: 'paragraph', text: 'It is worth studying and applying trend structure analysis closely, as it provides valuable insights into market development and enables important trend insights.'},
                {type: 'paragraph', text: 'Now it is time to practice trend structure analysis.'},
            ]
        }
    ]
}

export default MarketPriceStructure;
